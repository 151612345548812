var filterNames = [
  "currency",
  "date",
  "filter",
  "json",
  "limitTo",
  "lowercase",
  "number",
  "orderBy",
  "uppercase",
];

var injector = angular.injector(["ng"]);
var $filter = injector.get("$filter");

var angularFilters = {
  $filter: $filter,
};

_.forEach(filterNames, function (filterName) {
  angularFilters[filterName] = injector.get(filterName + "Filter");
});

export function filterPercentage(val, decimal, withMark, filterOutNull) {
  decimal = isNaN(decimal) ? 2 : decimal;
  var suffix = withMark ? "%" : "";
  if (isNaN(val)) {
    if (filterOutNull || val === undefined) {
      return;
    }
    var zero = 0;
    return zero.toFixed(decimal) + suffix;
  }
  return angularFilters.number(val * 100, decimal) + suffix;
}

export function filterFormatNumber(val, decimal, noRound) {
  if (isNaN(val)) {
    return val;
  }

  if (decimal === undefined) {
    var stringVal = val.toString();
    var decimalIndex = stringVal.indexOf(".");
    if (decimalIndex > -1) {
      decimal = stringVal.length - 1 - decimalIndex;
      if (decimal === 3) {
        decimal = 4;
      }
      if (val > 0) {
        val += 1e-12;
      } else if (val < 0) {
        val -= 1e-12;
      }
    }
  } else {
    val = parseFloat(val);
    if (val > 0) {
      val += 1e-12;
    } else if (val < 0) {
      val -= 1e-12;
    }
  }

  if (noRound) {
    var pow = Math.pow(10, decimal);
    if (val >= 0) {
      val = Math.floor(val * pow) / pow;
    } else {
      val = Math.ceil(val * pow) / pow;
    }

    return angularFilters.number(val, decimal);
  }
  return angularFilters.number(val, decimal);
}
