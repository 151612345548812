import { estFilter, noFractionCurrency } from "../tools/tk-tools";
import { commonColumns } from "./default-col";

export var plColumns = commonColumns.concat([
  {
    name: "Account",
    value: "accountNumberDecoded",
  },
  {
    name: "IBD",
    value: "ibdDecoded",
  },
  {
    name: "Symbol",
    value: "symbol",
  },
  {
    name: "Date",
    value: "plAnalysis.eventPosition.endDtime",
    filter: function (o) {
      return estFilter(o, "MM/DD/YYYY h:mma");
    },
  },
  {
    name: "Event Position",
    value: "plAnalysis.eventPosition.position",
    decimal: 0,
  },
  {
    name: "Event Value",
    value: "plAnalysis.eventPosition.value",
    filter: function (o) {
      return noFractionCurrency(-o);
    },
  },
  {
    name: "Event P&L",
    value: "plAnalysis.eventPnl",
    filter: function (o) {
      return noFractionCurrency(o);
    },
  },
  {
    name: "Trade P&L",
    value: "plAnalysis.tradedPnl",
    filter: function (o) {
      return noFractionCurrency(o);
    },
  },
  {
    name: "Total P&L",
    value: "plAnalysis..totalPnl",
    filter: function (o) {
      return noFractionCurrency(o);
    },
  },
  {
    name: "E",
    value: "isException",
    filter: function (o) {
      return o ? "Y" : "N";
    },
  },
]);
