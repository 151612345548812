angular.module('sniperWebApp')
  .factory('metaConfigFactory', function($resource, config) {
    var _allConfigs = [];
    var _filteredConfigs = [];
    var _filteredConfigTypes = [];

    var Symbol = $resource(config.restApiUrl + '/metadata/config/:id', {}, {
        fetchAll: {method: 'GET', url: config.restApiUrl + '/metadata/configs/:configTypes', isArray: true},
        fetchFiltered: {method: 'GET', url: config.restApiUrl + '/metadata/configs/:configType', isArray: true},
        fetch: {method: 'GET'},
        save: {method: 'POST'}
    });

    function setAllConfig (allConfigs) {
      _allConfigs = allConfigs;
    }

    function getAllConfig () {
      return _allConfigs;
    }

    function setFilteredConfig (filteredConfig, configType) {
      var configIndex = _filteredConfigTypes.indexOf(configType);
      if(configIndex > -1) {
        return;
      }
      _filteredConfigTypes.push(configType);
      _filteredConfigs.push(filteredConfig);
    }

    function getFilteredConfig (configType) {
      return _filteredConfigs[_filteredConfigTypes.indexOf(configType)] || [];
    }

    return {
      fetchAll: Symbol.fetchAll,
      fetchFiltered: Symbol.fetchFiltered,
      fetch: Symbol.fetch,
      save: Symbol.save,
      setAllConfig: setAllConfig,
      getAllConfig: getAllConfig,
      setFilteredConfig: setFilteredConfig,
      getFilteredConfig: getFilteredConfig,
    };
  })
  .factory('metaConfigHandlerFactory', function ($filter) {
    function setTradeDate (data) {
      if(!data.length) {
        return data;
      }
      var configType = data[0].configType
      if(configType === 'REGM') {
        data.forEach(function (d) {
          d.tradeDate = d.endDate;
        });
      } else if(configType === 'IPO') {
        data.forEach(function (d) {
          d.tradeDate = d.startDate;
        });
      }

      /** Make sure the indexing are consistent, some are missing from server **/
      data.forEach(function (d) {
        var tempAttributes = new Array(4);
        for(var i = 0; i < d.attributes.length; i++) {
          if(d.attributes[i].key === 'Company') {
            tempAttributes[0] = d.attributes[i];
          } else if(d.attributes[i].key === 'Description') {
            tempAttributes[1] = d.attributes[i];
          } else if(d.attributes[i].key === 'Shares') {
            tempAttributes[2] = d.attributes[i];
          } else if(d.attributes[i].key === 'Price') {
            tempAttributes[3] = d.attributes[i];
          }
        }
        if(!tempAttributes[2]) {
          tempAttributes[2] = {key: 'Shares', value: '-1'};
        }
        if(!tempAttributes[3]) {
          tempAttributes[3] = {key: 'Price', value: '-1'};
          tempAttributes[4] = {
            key: 'Price Orig',
            value: '-1'
          };
        }
        if(tempAttributes[3].value && !tempAttributes[3].value.match(/[a-zA-Z]/)) {
          tempAttributes[4] = {
            key: 'Price Orig',
            value: tempAttributes[3].value
          };
          tempAttributes[3].value = parseFloat(tempAttributes[3].value.replace('$', ''));
        }
        if(!tempAttributes[4]) {
          tempAttributes[4] = {
            key: 'Price Orig',
            value: tempAttributes[3].value
          };
        }
        d.attributes = tempAttributes;
      });
      return data;
    }

    function sort (arr, key, reverse) {
      switch(key) {
        case 'symbol':
          return $filter('orderBy')(arr, 'symbol', reverse);
          break;
        case 'tradeDate':
          return $filter('orderBy')(arr, 'tradeDate', reverse);
          break;
        case 'company':
          return $filter('orderBy')(arr, function (d) {
            return d.attributes[0].value;
          }, reverse);
          break;
        case 'price':
          return $filter('orderBy')(arr, function (d) {
            return d.attributes[3].value;
          }, reverse);
          break;
        case 'share':
          var isReversed = reverse ? 1 : -1;
          return arr.sort(function (a, b) {
            var aNumeric = parseFloat(a.attributes[2].value.split(' ')[0]) || 0
              , bNumeric = parseFloat(b.attributes[2].value.split(' ')[0]) || 0;
            if(aNumeric < bNumeric) {
              return -1 * isReversed;
            } else if(aNumeric > bNumeric) {
              return isReversed;
            }
            return 0;
          });
          break;
        case 'configType':
          return $filter('orderBy')(arr, 'configType', reverse);
          break;
        case 'analysisType':
          return $filter('orderBy')(arr, 'analysisType', reverse);
          break;
        case 'startDate':
          return $filter('orderBy')(arr, 'startDate', reverse);
          break;
        case 'endDate':
          return $filter('orderBy')(arr, 'endDate', reverse);
          break;
        case 'name':
          return $filter('orderBy')(arr, 'name', reverse);
          break;
        case 'account':
          return $filter('orderBy')(arr, 'accountNumberDecoded', reverse);
          break;
        case 'accountNumber':
          return $filter('orderBy')(arr, 'accountNumberDecoded', reverse);
          break;
      }
      return arr;
    }

    return {
      setTradeDate: setTradeDate,
      sort: sort
    };
  })
  .factory('metaConfigFilterFactory', function ($filter) {
    function filterConfigType(type, data) {
      if(type === 'ALL') {
        return data;
      }
      return data.filter(function (d) {
        return d.configType === type;
      });
    }

    function filterSymbol(config, data) {
      return $filter('filter')(data, {
        symbol: config.symbol
      });
    }

    function filterStartDate(config, data) {
      return $filter('filter')(data, function (d) {
        return d.startDate >= moment(config.startDate).subtract('d', 1).add('s', 1).valueOf();
      });
    }

    function filterEndDate(config, data) {
      return $filter('filter')(data, function(d){
        return d.endDate <= moment(config.endDate).valueOf();
      });
    }

    function filterCaseType(config, data) {
      return $filter('filter')(data, function(d){
        console.log(d.analysisType + ' : ' + config.caseType)
        return d.analysisType === config.caseType;
      });
    }

    function filter(filterConfig, data) {
      if(filterConfig.caseType && filterConfig.caseType !== 'all') {
        data = filterCaseType(filterConfig, data);
      }

      if(filterConfig.symbol) {
        data = filterSymbol(filterConfig, data);
      }

      if(filterConfig.configType) {
        data = filterConfigType(filterConfig.configType, data);
      }

      if(filterConfig.startDate) {
        data = filterStartDate(filterConfig, data);
      }

      if(filterConfig.endDate) {
        data = filterEndDate(filterConfig, data);
      }

      return data;
    }

    return {
      filter: filter
    };
  });
