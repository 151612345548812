angular
  .module("sniperWebApp")
  .factory("ruleAnalysisHelperFactory", function () {
    function generateSymbolSummary(c) {
      c.caseType = "Rules";
      var flaggedArr = c.rulesAnalysis.flagged,
        symbolSummary = [],
        filteredSymbol = [],
        prepOrderArr = [],
        currentSymbol = "",
        symbolIndex = -1;
      var isEven = false;
      /** Filter same symbol to the same index of filteredSymbol **/
      for (var i = 0; i < flaggedArr.length; i += 1) {
        currentSymbol = flaggedArr[i].prepOrder.order.symbol;
        symbolIndex = filteredSymbol.indexOf(currentSymbol);
        flaggedArr[i].prepOrder.order.limitedTo = flaggedArr[
          i
        ].prepOrder.order.buy_sell_ind.match(/SSHORT|SSELL/)
          ? 2
          : 1;
        if (symbolIndex === -1) {
          filteredSymbol.push(currentSymbol);
          prepOrderArr.push([flaggedArr[i].prepOrder]);
        } else {
          prepOrderArr[symbolIndex].push(flaggedArr[i].prepOrder);
        }

        /** Setting odd and even row for multiple flag and execution summary **/
        var executionSummaries = flaggedArr[i].prepOrder.executions.summary;
        if (executionSummaries && executionSummaries.length > 0) {
          for (var j = 0; j < executionSummaries.length; j += 1) {
            executionSummaries[j].isEven = isEven;
            isEven = !isEven;
          }
        }
      }

      filteredSymbol.forEach(function (symbol, i) {
        var totalPrice = 0,
          totalVol = 0,
          totalOrigVol = 0;
        prepOrderArr[i].forEach(function (prepOrder) {
          totalPrice += prepOrder.order.execution_price || 0;
          totalVol += prepOrder.order.trade_volume || 0;
          totalOrigVol += prepOrder.order.original_volume || 0;
        });

        symbolSummary.push({
          symbol: symbol,
          avgPrice: totalPrice / prepOrderArr[i].length,
          totalVol: totalVol,
          totalOrigVol: totalOrigVol,
          associatedPrepOrder: prepOrderArr[i],
        });
      });
      c.rulesAnalysis.flaggedSymbolSummary = symbolSummary;
      c.symbol = c.rulesAnalysis.rule.symbol || symbolSummary[0].symbol;
      return c;
    }

    return {
      generateSymbolSummary: generateSymbolSummary,
    };
  });
