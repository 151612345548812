'use strict';

angular.module('sniperWebApp')
  .factory('userFactory', function($resource, config) {

    var User = $resource(config.restApiUrl + '/user/?', {}, {
      list: {method: 'GET'},
      changePassword: {method: 'GET', url: config.restApiUrl + '/user/password?original=:original&new=:new'}
    });

    return {
      get: function() {
        return User.get();
      },
      changePassword: function(spec) {
        return User.changePassword(spec);
      }
    };
  });
