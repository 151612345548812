(function () {
  var CONST = {
    footer: 4,
  };
  angular
    .module("sniperWebApp")
    .directive("bodyResizer", function () {
      return {
        link: function ($scope, element) {
          var headerEle = $(".tkg-header");
          var resizeTimeout;
          var panelElement;

          setTimeout(function () {
            panelElement = element.find(".panel-body").first();
          }, 50);

          function resize() {
            window.clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(function () {
              var elementHeight =
                window.innerHeight - headerEle.height() - CONST.footer;
              element.css({
                height: elementHeight,
              });
              panelElement.css({
                height: elementHeight - 38,
              });
            }, 101);
          }

          $(window).resize(resize);
          resize();
          $scope.$on("$destroy", () => {
            $(window).off("resize", resize);
            clearTimeout(resizeTimeout);
          });
        },
      };
    })
    .directive("highchartResizer", function ($parse, $timeout) {
      return {
        scope: false,
        link: function ($scope, element, attrs) {
          if (!attrs.highchartResizer) {
            return;
          }
          var resizeTimeout;
          var parentElement = attrs.parentElement
            ? $(attrs.parentElement)
            : element;
          var chart = $parse(attrs.highchartResizer)($scope);

          function resize() {
            $timeout.cancel(resizeTimeout);
            resizeTimeout = $timeout(function () {
              chart.options.chart.height = parentElement.height();
              chart.options.chart.width = parentElement.width();
            }, 105);
          }

          $(window).resize(resize);
          resize();
          $scope.$on("$destroy", () => {
            $(window).off("resize", resize);
            clearTimeout(resizeTimeout);
          });
        },
      };
    });
})();
