'use strict';

angular.module('sniperWebApp')
	.factory('marketDataFactory', function($resource, $log, config) {

		var MarketData = $resource(config.restApiUrl + '/mdata/interval/:symbol/?from=:from&to=:to&mins=:mins', {}, {
			interval: {method: 'GET', params: {}, isArray: true}
		});

		return {
			interval: function(spec) {
				//spec = {symbol: symbol, from: from, to: to, mins: mins}
				return MarketData.interval(spec);
			}
		};
	})
  .service('MarketDataFactory', function ($http, config) {
    this.interval = function (params, symbol) {
      return $http({
        method: 'GET',
        url: config.restApiUrl + '/mdata/interval/' + symbol + '/',
        params: params
      });
    };
  });
