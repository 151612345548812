import { estFilter, noFractionCurrency } from "../tools/tk-tools";
import { commonColumns } from "./default-col";

export var aheadOfNewsCol = commonColumns.concat([
  {
    name: "Account",
    value: "accountNumberDecoded",
  },
  {
    name: "IBD",
    value: "ibdDecoded",
  },
  {
    name: "Symbol",
    value: "symbol",
  },
  {
    name: "Date",
    value: "aheadOfNewsAnalysis.marketEvent.dtime",
    filter: function (o) {
      return estFilter(o, "MM/DD/YYYY h:mma");
    },
  },
  {
    name: "Open Position",
    value: "aheadOfNewsAnalysis.positionBeforeEvent.position",
    decimal: 0,
  },
  {
    name: "Open Value",
    value: "aheadOfNewsAnalysis.positionBeforeEvent.value",
    filter: function (o) {
      return noFractionCurrency(-o);
    },
  },
  {
    name: "Open P&L",
    value: "aheadOfNewsAnalysis.eventPnl",
    filter: function (o) {
      return noFractionCurrency(o);
    },
  },
  {
    name: "Trade P&L",
    value: "aheadOfNewsAnalysis.tradedPnl",
    filter: function (o) {
      return noFractionCurrency(o);
    },
  },
  {
    name: "Total P&L",
    value: "aheadOfNewsAnalysis.totalPnl",
    filter: function (o) {
      return noFractionCurrency(o);
    },
  },
  {
    name: "E",
    value: "isException",
    filter: function (o) {
      return o ? "Y" : "N";
    },
  },
]);
