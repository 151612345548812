angular
  .module("sniperWebApp")
  .controller(
    "MetaFilteredConfigController",
    function (
      $filter,
      $routeParams,
      $controller,
      $scope,
      $timeout,
      config,
      metaConfigFactory,
      metaConfigFilterFactory,
      metaConfigHandlerFactory
    ) {
      angular.extend(this, $controller("AdminController", { $scope: $scope }));
      $scope.filteredConfigSet = [];
      $scope.currentPageConfigSet = [];
      $scope.allConfigs = [];
      $scope.config = config;
      $scope.constants = {
        // configType: ['ALL', 'EXCLUSION', 'AFTER_MARKET_DAY', 'BEFORE_MARKET_DAY', 'OTHER'],
      };

      var configType;
      if ($scope.currentTab === "SPO") {
        configType = "REGM";
      } else if ($scope.currentTab === "IPO") {
        configType = "IPO";
      }
      $scope.filter = {
        startDate: "",
        endDate: "",
        configType: configType,
        symbol: "",
        predefinedDateSelection: "{}",
        caseType: "all",
      };
      $scope.filterConfig = {
        selectedConfig: null,
        sortSymbol: true,
        sortTradeDate: false,
        sortCompany: true,
        sortPrice: true,
        sortShare: true,
      };

      $scope.sort = function (key, reverse) {
        $scope.allConfigs = metaConfigHandlerFactory.sort(
          $scope.allConfigs,
          key,
          reverse
        );
        $scope.filteredConfigSet = angular.copy($scope.allConfigs);
        getCurrentCases();
      };

      var paging = ($scope.paging = {
        currentPage: 1,
        totalPages: 0,
        totalSymbols: 0,
      });

      function setDatesFromCurrentPredefindSelection() {
        var dateRange = angular.fromJson($scope.filter.predefinedDateSelection);
        if (dateRange.from) {
          $scope.filter.startDate =
            dateRange.from < 0
              ? moment().businessSubtract(Math.abs(dateRange.from)).toDate()
              : moment().businessAdd(Math.abs(dateRange.from)).toDate();
        }
        if (dateRange.to) {
          $scope.filter.endDate =
            dateRange.to < 0
              ? moment().businessSubtract(Math.abs(dateRange.to)).toDate()
              : moment().businessAdd(Math.abs(dateRange.to)).toDate();
        }
      }

      $scope.$watch(
        "filter.predefinedDateSelection",
        setDatesFromCurrentPredefindSelection
      );

      function displayMessage(type, msg, timer) {
        $scope.addAlert({ type: type, msg: msg }, timer);
      }

      function getPagingInfo(data, toLastPage) {
        paging.totalPages = Math.ceil(data.length / 100);
        paging.totalSymbols = data.length;
        if (!toLastPage) {
          paging.currentPage = 1;
        } else {
          paging.currentPage = paging.totalPages;
        }
      }

      function getCurrentCases() {
        var startIndex = (paging.currentPage - 1) * 100,
          endIndex = startIndex + 100;

        $scope.currentPageConfigSet = $scope.filteredConfigSet.slice(
          startIndex,
          endIndex
        );
      }
      $scope.$watch("paging.currentPage", getCurrentCases);
      $scope.$watch("filteredConfigSet", function () {
        paging.currentPage = 1;
      });
      $scope.$watch("filter.caseType", function (type) {
        if (!type || !$scope.allConfigs.length) return;
        $scope.submitFilter();
        $scope.editEntry = null;
      });

      /***************************/
      /** Get All Saved Entries **/
      function fetchSavedAnalysis(forceUpdate) {
        var configs = metaConfigFactory.getFilteredConfig(configType);
        if (!configs.length || forceUpdate) {
          metaConfigFactory
            .fetchFiltered({ configType: configType })
            .$promise.then(function (data) {
              data = metaConfigHandlerFactory.setTradeDate(data);
              metaConfigFactory.setFilteredConfig(data, configType);
              $scope.allConfigs = data;
              $scope.filteredConfigSet = angular.copy(data);
              getCurrentCases();
              getPagingInfo(data);
              $scope.sort("tradeDate", true);
            });
        } else {
          $scope.allConfigs = configs;
          $scope.filteredConfigSet = angular.copy(configs);
          getCurrentCases();
          getPagingInfo(configs);
          $scope.sort("tradeDate", true);
        }
      }
      $scope.fetchSavedAnalysis = fetchSavedAnalysis;
      $scope.fetchSavedAnalysis(true);

      $scope.submitFilter = function () {
        $scope.filteredConfigSet = metaConfigFilterFactory.filter(
          $scope.filter,
          $scope.allConfigs
        );
        getPagingInfo($scope.filteredConfigSet);
        getCurrentCases();
      };

      /**********************/
      /** Adding New Entry **/

      var newEntry = ($scope.newEntry = {
        symbol: "",
        startDate: "",
        endDate: "",
        formattedEndDate: moment(moment().valueOf()).format("MM/DD/YYYY"),
        company: "",
        price: "",
        shares: "",
      });

      function clearNewEntry() {
        for (var i in newEntry) {
          if (i !== "startDate") {
            newEntry[i] = "";
          }
        }
        newEntry.formattedEndDate = moment(moment().valueOf()).format(
          "MM/DD/YYYY"
        );
      }

      function validateNewEntry() {
        var keysToValidate = ["symbol", "formattedEndDate"];

        for (var i in keysToValidate) {
          if (!newEntry[keysToValidate[i]]) return false;
        }
        return true;
      }

      $scope.saveNewEntry = function (obj) {
        var queryParam;
        $scope.formSubmitted = true;
        if (obj) {
          queryParam = obj;
        } else {
          if (!validateNewEntry()) {
            return;
          }
          var momentEndDate = moment(newEntry.formattedEndDate);
          var endDate = (newEntry.startDate = newEntry.formattedEndDate
            ? momentEndDate.valueOf()
            : null);
          var startDate = (newEntry.endDate = newEntry.formattedEndDate
            ? momentEndDate.subtract(7, "day").valueOf()
            : null);
          queryParam = {
            analysisType: "RULES",
            configType: "REGM",
            filterBy: "SYMBOL",
            symbol: newEntry.symbol,
            startDate: startDate,
            endDate: endDate,
            attributes: [
              { key: "Company", value: newEntry.company },
              { key: "Description" },
              { key: "Shares", value: newEntry.shares || undefined },
              { key: "Price", value: newEntry.price || undefined },
            ],
            logRecord: {
              comment: newEntry.logRecord ? newEntry.logRecord.comment : "",
            },
          };
          angular.copy(newEntry);
        }
        $scope.disableSubmit = true;
        metaConfigFactory.save(queryParam).$promise.then(
          () => {
            clearNewEntry();
            $scope.isOpen = $scope.disableSubmit = false;
            fetchSavedAnalysis(true);
            if (obj) {
              displayMessage("success", "Successfully Updated", 3000);
            } else {
              displayMessage("success", "Successfully Saved", 3000);
            }
          },
          () => {
            displayMessage("danger", "Invalid Reply", 3000);
          }
        );
      };
      /** End of New Entry **/
      /**********************/

      /****************************/
      /** Editing Existing Entry **/
      function validateExistingEntry() {
        var keysToValidate = ["symbol", "formattedEndDate"];

        for (var i in keysToValidate) {
          if (!$scope.editEntry[keysToValidate[i]]) return false;
        }
        return true;
      }

      $scope.openEditContent = function () {
        var selectedConfig = $scope.filterConfig.selectedConfig;
        if (!selectedConfig) {
          return;
        }

        $scope.isEdit = $scope.isOpen = true;
        if ($scope.editEntry && $scope.editEntry.mid === selectedConfig.mid) {
          return;
        }
        $scope.editEntry = angular.copy(selectedConfig);
        var company = _.find(selectedConfig.attributes, { key: "Company" });
        var price = _.find(selectedConfig.attributes, { key: "Price Orig" });
        var shares = _.find(selectedConfig.attributes, { key: "Shares" });
        angular.extend($scope.editEntry, {
          formattedEndDate: selectedConfig.endDate
            ? $filter("estFilter")(selectedConfig.endDate, "MM/DD/YYYY")
            : null,
          company: company ? company.value : "",
          price: price && price.value != -1 ? price.value : "",
          shares: shares ? shares.value : "",
        });
        // $scope.editEntry.formattedEndDate = selectedConfig.endDate ? $filter('estFilter')(selectedConfig.endDate, 'MM/DD/YYYY') : null;
        // $scope.editEntry.formattedStartDate = selectedConfig.startDate ? $filter('estFilter')(selectedConfig.startDate, 'MM/DD/YYYY') : null;
        // $scope.editEntry.company = selectedConfig.attributes[0].value;
      };

      $scope.updateExistingEntry = function () {
        $scope.formSubmitted = true;
        $scope.invalidAccount = false;
        if (!validateExistingEntry()) {
          return;
        }
        $scope.disableSubmit = true;
        var momentEndDate = moment($scope.editEntry.formattedEndDate);
        var endDate = $scope.editEntry.formattedEndDate
          ? momentEndDate.valueOf()
          : null;
        var startDate = $scope.editEntry.formattedEndDate
          ? momentEndDate.subtract(7, "day").valueOf()
          : null;
        var editEntry = angular.copy($scope.editEntry);
        var queryParam = {
          analysisType: "RULES",
          configType: "REGM",
          filterBy: "SYMBOL",
          symbol: editEntry.symbol,
          startDate: startDate,
          endDate: endDate,
          attributes: [
            { key: "Company", value: editEntry.company },
            { key: "Description", value: editEntry.description || "" },
            { key: "Shares", value: editEntry.shares || "" },
            { key: "Price", value: editEntry.price || "" },
          ],
          logRecord: {
            comment: editEntry.logRecord ? editEntry.logRecord.comment : "",
          },
        };
        if (editEntry.endDate !== endDate) {
          $scope.destroyExistingEntry(true).then(function () {
            $scope.saveNewEntry(queryParam);
          });
        } else {
          angular.extend(editEntry, queryParam);
          editEntry.$save(
            {},
            () => {
              $scope.editEntry = null;
              $scope.filterConfig.selectedConfig = null;
              $scope.isEdit = $scope.isOpen = $scope.disableSubmit = false;
              fetchSavedAnalysis(true);
              displayMessage("success", "Successfully Updated", 3000);
            },
            () => {
              displayMessage("danger", "Invalid Reply", 3000);
            }
          );
        }
      };

      $scope.destroyExistingEntry = function (disableFetch) {
        if (!$scope.filterConfig.selectedConfig) {
          //@Todo create an alert message saying select a case
          return;
        }
        var selectedConfig = angular.copy($scope.filterConfig.selectedConfig);
        selectedConfig.active = false;
        selectedConfig.endDate = moment().valueOf();
        $scope.disableSubmit = true;
        return metaConfigFactory.save(selectedConfig).$promise.then(
          () => {
            $scope.disableSubmit = false;
            $scope.filterConfig.selectedConfig = null;
            if (!disableFetch) {
              fetchSavedAnalysis(true);
              displayMessage("success", "Successfully Removed", 3000);
            }
          },
          () => {
            displayMessage("danger", "Invalid Reply", 3000);
          }
        );
      };
      /** End of Editing Existing Entry **/
      /***********************************/

      /**
       * [keepItOpen keep the bottom edit or add entry bar open depening on cases]
       */
      $scope.keepItOpen = function (addOrEdit, isEdit, isOpen, event) {
        if (addOrEdit === "add") {
          if (isEdit && isOpen) {
            $scope.isOpen = true;
            event.stopPropagation();
          }
          $scope.isEdit = false;
        } else {
          if (!$scope.editEntry || !$scope.editEntry.mid) {
            event.preventDefault();
            event.stopPropagation();
            return;
          }
          if (!isEdit && isOpen) {
            $scope.isOpen = true;
            event.stopPropagation();
          }
          $scope.isEdit = true;
        }
      };
    }
  );
