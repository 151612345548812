let tableResizeDelay = 200;
let bodyResizeDelay = 201;
let documentEle;
let lastScrollPos = 0;
let lastClickHeader = null;

angular
  .module("tools.tk")
  .directive("sniperScrollableTable", function ($document) {
    return {
      controller: function scrollableTableCtrl($scope, $element) {
        documentEle = documentEle || $document;
        $scope.testing = true;
        this.ctrlName = "scrollableTableCtrl";
        this.tableEle = $element;
      },
      scope: {
        data: "=watch",
      },
      link($scope, element, _, ctrl) {
        var resizeTimer,
          stopWatchData = false;
        element.addClass("scrollable-table");

        function setBodyElement() {
          ctrl.bodyEle.css({
            "margin-top":
              parseInt(ctrl.headerEle.css("height") || 0) -
              parseInt(ctrl.headerEle.css("border-top-width") || 0) +
              "px",
            // 'height': parseInt(bodyHeight) -
            //           parseInt(ctrl.headerEle.css('height')) - 15 + 'px',
            width: element.css("width"),
          });
          ctrl.bodyResize();
        }

        function setHeaderElement() {
          ctrl.headerEle.css({
            width: element.css("width"),
          });
        }

        function resize() {
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(function () {
            ctrl.bodyEle.css({
              width: element.css("width"),
            });
            ctrl.headerEle.css({
              width: element.css("width"),
            });
          }, tableResizeDelay);
        }

        setBodyElement();
        setHeaderElement();
        setTimeout(function () {
          setBodyElement();
          setHeaderElement();
        });

        $scope.$watch("data", (data) => {
          if (data && !stopWatchData) {
            stopWatchData = true;
            setBodyElement();
            scrollToLastPos();
            setTimeout(function () {
              stopWatchData = false;
            }, 50);
          }
        });

        function scrollToLastPos() {
          if (lastScrollPos > 0) {
            setTimeout(function () {
              var bodyEle = ctrl.bodyEle[0];
              var headerRowEle = ctrl.headerEle.find("tr")[0];
              var lastHeaderWidth = lastClickHeader.width();
              bodyEle.scrollLeft = lastScrollPos + lastHeaderWidth;
              headerRowEle.style.left =
                -1 * (lastScrollPos + lastHeaderWidth) + "px";
            }, 250);
          }
        }

        $(window).on("resize", resize);

        $scope.$on("$destroy", function () {
          $(window).off("resize", resize);
          lastScrollPos = 0;
          lastClickHeader = null;
        });
      },
    };
  })
  .directive("thead", function () {
    return {
      restrict: "E",
      priority: 800,
      require: "?^sniperScrollableTable",
      link($scope, element, attrs, scrollableTableCtrl) {
        if (
          !scrollableTableCtrl ||
          scrollableTableCtrl.ctrlName != "scrollableTableCtrl"
        ) {
          return;
        }
        scrollableTableCtrl.headerEle = element;
      },
    };
  })
  .directive("tbody", function () {
    return {
      restrict: "E",
      priority: 801,
      require: "?^sniperScrollableTable",
      link($scope, element, attrs, scrollableTableCtrl) {
        if (
          !scrollableTableCtrl ||
          scrollableTableCtrl.ctrlName != "scrollableTableCtrl"
        ) {
          return;
        }
        var nativeEle = element[0],
          headerEle = scrollableTableCtrl.headerEle,
          headerRowEle = headerEle.find("tr")[0],
          resizeTimer;

        scrollableTableCtrl.bodyEle = element;
        scrollableTableCtrl.bodyResize = resize;

        function scroll() {
          if (nativeEle.scrollLeft > 0) {
            lastScrollPos = nativeEle.scrollLeft;
            lastClickHeader = element;
          }
          headerRowEle.style.left = -1 * nativeEle.scrollLeft + "px";
        }

        /** Create responsiveness in the table columns when table width is longer than the table row width **/
        function resize() {
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(function () {
            const bodyRows = element.find("tr");
            const rowWidth = bodyRows.first().width();
            var widthDifference = element.width() - rowWidth;
            if (widthDifference > 0) {
              widthDifference = widthDifference - 18; //additional 20px reserve for scroll bar
              /** Search for specific columns to set the additional width **/
              var columnsToAdjust = headerEle.find(
                'th[data-adjust-this="true"]'
              );
              if (columnsToAdjust.length) {
                adjustSpecificColumns(
                  columnsToAdjust,
                  widthDifference,
                  1,
                  bodyRows
                );
              } else {
                widthDifference = widthDifference + 18;
                adjustSpecificColumns(
                  headerEle.find("th"),
                  widthDifference,
                  1,
                  bodyRows
                );
              }
            } else if (widthDifference < 0) {
              widthDifference = Math.abs(widthDifference /*- 18*/);
              var columnsToAdjust = headerEle.find(
                'th[data-adjust-this="true"]'
              );
              if (columnsToAdjust.length) {
                adjustSpecificColumns(
                  columnsToAdjust,
                  widthDifference,
                  -1,
                  bodyRows
                );
              } else {
                adjustSpecificColumns(
                  headerEle.find("th"),
                  widthDifference,
                  -1,
                  bodyRows
                );
              }
            }
          }, bodyResizeDelay);
        }

        function adjustSpecificColumns(
          columnsToAdjust,
          widthDifference,
          expanding,
          bodyRows
        ) {
          /** resetting the position of the resizer **/
          columnsToAdjust.children(".col-resizer").css("left", "auto");
          var totalHeaderCellWidth = 0;
          columnsToAdjust.each(function (index, cellTh) {
            totalHeaderCellWidth += parseInt(
              getComputedStyle(cellTh).width || 0
            );
          });

          var additionalLength = 0;
          if (totalHeaderCellWidth < columnsToAdjust.parents("table").width()) {
            additionalLength =
              Math.floor(widthDifference / columnsToAdjust.length) * expanding;
          }
          columnsToAdjust.each(function (index, cellTh) {
            /** Set Header Width **/
            cellTh.style.width =
              parseInt(getComputedStyle(cellTh).width || 0) +
              additionalLength +
              "px";
            normalizeCell($(cellTh), cellTh);
            /** Set Body Width **/
            bodyRows.each(function (i, row) {
              var cellTd = $(row).children("td")[cellTh.cellIndex];
              normalizeCell($(cellTd), cellTh);
            });
          });
        }

        element.on("scroll", scroll);
        // $(window).on('resize', resize);

        $scope.$on("$destroy", function () {
          element.off("scroll", scroll);
          // $(window).off('resize', resize);
        });
      },
    };
  });

/**
 * [checkExistence check whether the scrollable directive exists]
 * @param  {jquery Element} [current cell element either <th> or <td>]
 * @return {Boolean}        [indicating the scrollable table exists or not]
 */
function checkExistence(element) {
  if (!element.parents("table").length) {
    return false;
  }
  return (
    element.parents("table")[0].className.indexOf("scrollable-table") !== -1
  );
}

/**
 * [normalizeCell on resize or on load, if the table doesn't fill up the area,
 *                expand the columns to make the table fill up the respective width]
 * @param  {[type]} element [description]
 * @return {[type]}         [description]
 */
function normalizeCell(element, headerCell) {
  var childElement = element.children().first(),
    width,
    minWidth;

  width =
    parseInt(headerCell.style.width) -
    parseInt(element.css("padding-left") || 0) -
    parseInt(element.css("padding-right") || 0);
  // - parseInt(element.css('border-right-width') || 0)
  // - parseInt(element.css('border-left-width') || 0)

  minWidth = parseInt(headerCell.style.minWidth || 50);

  if (width > minWidth) {
    childElement.css({
      width: width + "px",
    });
  } else {
    childElement.css({
      width: minWidth + "px",
    });
  }
}

/**
 * [initializeCell wrap the cell contents inside a div if it hasn't yet to allow resizability,
 *                 and assign the width if the div base on the header column style]
 * @param  {jQuery Element} element         [current cell element either <th> or <td>]
 * @param  {Function}       getHeaderColumn [returns the header cell respect to the cellIndex]
 * @return {undefined}
 */
function initializeCell(element, getHeaderCell) {
  if (element[0].nodeName === "#comment" || element[0].nodeType === 8) {
    return;
  }
  if (element.children().first().prop("tagName") !== "DIV") {
    element.contents().wrapAll("<div></div>");
  }
  var childElement = element.children().first(),
    headerCell = getHeaderCell(),
    minWidth;

  if (parseInt(headerCell.style.minWidth)) {
    minWidth =
      parseInt(headerCell.style.minWidth) -
      parseInt(element.css("padding-left") || 0) -
      parseInt(element.css("padding-right") || 0) +
      // - parseInt(element.css('border-right-width') || 0)
      // - parseInt(element.css('border-left-width') || 0)
      "px";
  } else {
    minWidth = "50px";
  }

  childElement.css({
    width: minWidth,
  });
}

angular
  .module("tools.tk")
  .directive("th", function () {
    return {
      restrict: "E",
      priority: 801,
      require: "?^thead",
      link($scope, element) {
        setTimeout(init);

        function getHeaderCell() {
          return element[0];
        }

        function init() {
          if (checkExistence(element)) {
            initializeCell(element, getHeaderCell, $scope);
            $scope.$on("$destroy", function () {
              element.off("click");
            });
            // createDraggableColumn($scope, element);
          }
        }
      },
    };
  })
  .directive("td", function () {
    return {
      restrict: "E",
      priority: 801,
      require: "?^tbody",
      link(_, element) {
        setTimeout(function () {
          if (checkExistence(element)) {
            initializeCell(element, getHeaderCell);
          }
        });

        function getHeaderCell() {
          return element.parents("tbody").prev().find("th")[element.index()];
        }
      },
    };
  });
