import html2canvas from 'html2canvas'

export const screenshot = () => {
  const fileName = 'screenshot_img.png'
  /** @type {HTMLElement} */
  const contentEl = document.querySelector('.content')
  const scrollableContainer = contentEl.querySelector('.caseGrid:not([watch="multiMatchedCases"]) .scrollableContainer .scrollArea')

  const tableOriginalHeight = scrollableContainer.clientHeight
  const tableScrollHeight = scrollableContainer.scrollHeight

  contentEl.style.height = contentEl.clientHeight + tableScrollHeight - tableOriginalHeight + 'px'
  scrollableContainer.style.height = tableScrollHeight + 'px'
  document.body.style.overflow = 'visible'
  const cb = () => {
    document.body.style.overflow = null
    contentEl.style.height = null
    scrollableContainer.style.height = tableOriginalHeight + 'px'
  }

  setTimeout(() => {
    html2canvas(document.body, {

      height: contentEl.clientHeight + document.body.clientHeight,
      width: contentEl.clientWidth + 15,
    }).then((canvas) => {
      const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      let downloadContainer = angular.element('<div data-tap-disabled="true"><a></a></div>')
      let downloadLink = angular.element(downloadContainer.children()[0])
      downloadLink.attr('href', image)
      downloadLink.attr('download', fileName)
      $(document).find('body').append(downloadContainer)
      setTimeout(() => {
        downloadLink[0].click()
        downloadLink.remove()
      })
      cb()
    }, () => {
      cb()
    })
  }, 100)
  return false
}
