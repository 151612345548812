angular.module('sniperWebApp')
  .controller('AdminController', function ($scope, $location, $routeParams) {
    // $scope = $scope.$parent;
    if($routeParams.tab) {
      $scope.currentTab = $routeParams.tab;
    } else {
      if($scope.currentPath === '/admin/symbol') {
        $scope.currentTab = 'Index';
      } else if($scope.currentPath === '/admin/config') {
        $scope.currentTab = 'Rules';
      }
    }
    $('body').addClass($scope.currentTab);
    $scope.currentTab = $routeParams.tab || 'Rules';
    // $scope.hideRulesConfig = $scope.currentTab !== 'Rules';
    var clickFunc = function (tab) {
      var path = '';
      if(tab === 'Omnibus') {
        path = '/admin/omnibus';
      } else if(tab.match(/(Index|Splits)/)) { //Symbols (index or splits)
        path = '/admin/symbol';
      } else if(tab.match(/Rules/)){ //Rules
        path = '/admin/config';
      } else if(tab.match(/Symbol_Exclusion/)) {
        path = '/admin/config/SE';
      } else { //SPO or IPO
        path = '/admin/config/' + tab;
      }
      $location.path(path).search({tab: tab});
    };
    $scope.tabs = [
      {name: 'Rules', clickFunc: function () {clickFunc('Rules')}, selected: $scope.currentTab === 'Rules'},
      {name: 'Index', clickFunc: function () {clickFunc('Index')}, selected: $scope.currentTab === 'Index'},
      {name: 'Splits', clickFunc: function () {clickFunc('Splits')}, selected: $scope.currentTab === 'Splits'},
      {name: 'Omnibus', clickFunc: function () {clickFunc('Omnibus')}, selected: $scope.currentTab === 'Omnibus'},
      {name: 'SPO', clickFunc: function () {clickFunc('SPO')}, selected: $scope.currentTab === 'SPO'},
      {name: 'IPO', clickFunc: function () {clickFunc('IPO')}, selected: $scope.currentTab === 'IPO'},
      {name: 'Symbol Exclusion', clickFunc: function () {clickFunc('Symbol_Exclusion')}, selected: $scope.currentTab === 'Symbol_Exclusion'},
    ];

    function resizeTable () {
      var tableEle = $('.scrollableContainer');
      tableEle.css({
        height: window.innerHeight -  tableEle.offset().top - 8 + 'px'
      });
    }

    function checkTableExist () {
      if($('.scrollableContainer').length) {
        setTimeout(resizeTable, 100);
        clearInterval(resizeContainerTimer)
      }
    }
    window.checkTableExist = checkTableExist;

    var resizeContainerTimer = setInterval(checkTableExist, 100);

    var reshapeTableTimer;
    $(window).resize(function () {
      clearTimeout(reshapeTableTimer);
      reshapeTableTimer = setTimeout(resizeTable, 200);
    });

    $scope.$on('$routeChangeStart', function () {
      $(window).off('resize');
      $('body').removeClass($scope.currentTab);
    });
  });
