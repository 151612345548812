angular.module('sniperWebApp')
  .factory('criteria', function ($q, ruleAnalysisHelperFactory, decoderFactory) {
    'use strict';

    function getComparator(comparator) {
      if (comparator === '>') {
        return '$gte'
      } else if (comparator === '<') {
        return '$lte'
      }
      return null
    }
    function getRegex(comparator, value) {
      if (comparator === '>') {
        return { $regex: '^' + value };
      } else if (comparator === '<') {
        return { $regex: value + '$' };
      }
      return value
    }
    function getFindCriteria(caseFilter, caseFilterConfig) {
      var commonCriteria = {};
      var commonAnd = commonCriteria.$and = [];
      var defer = $q.defer();

      if (caseFilter.exceptionsOnly) {
        // @Remove comment
        commonCriteria.isException = true;
      }

      switch (caseFilterConfig.caseType) {
        case 'aheadOfNewsAnalysis':
          commonCriteria.analysisType = 'AHEAD_OF_NEWS';
          break;
        case 'markingTheCloseAnalysis':
          commonCriteria.analysisType = 'MARKING_THE_CLOSE';
          break;
        case 'washSaleAnalysis':
          commonCriteria.analysisType = 'WASH_SALE';
          break;
        case 'rulesAnalysis':
          commonCriteria.analysisType = 'RULES';
          if (caseFilterConfig.caseTypeName === 'Reg-M 105') {
            commonCriteria['rulesAnalysis.rule.name'] = 'REGM';
          } else {
            commonCriteria['rulesAnalysis.rule.name'] = { $ne: 'REGM' };
          }
          break;
        case 'spoofingAnalysis':
          commonCriteria.analysisType = 'SPOOFING';
          break;
        case 'mcapAnalysis':
          commonCriteria.analysisType = 'MCAP';
          break;
        case 'plAnalysis':
          commonCriteria.analysisType = 'PL';
          break;
      }

      if (caseFilter.symbol) {
        commonCriteria.symbol = caseFilter.symbol.toUpperCase();
      } else {
        if (caseFilter.internationalType) {
          switch (caseFilter.internationalType) {
            case 'international':
              // international symbol has . or :
              commonAnd.push(
                { symbol: { $regex: "(\\.|\\:)" } }
              );
              break;
            case 'domestic':
              // domestic: excluding . and :
              commonAnd.push(
                { symbol: { $regex: "^((?!(\\.|\\:)).)*$" } }
              );
              break;
            case 'canada':
              commonAnd.push(
                { symbol: { $regex: "\\:" } }
              );
              break;
          }
        }
      }

      if (caseFilter.comment) {
        commonCriteria['history.comment'] = { $regex: caseFilter.comment, $options: 'i' }
      }

      var aheadOfNewsOnly = {};
      var aheadOfNewsOr = [];
      if (caseFilter.minTotalPnl) {
        if (caseFilter.minTotalPnl > 0) {
          aheadOfNewsOnly['aheadOfNewsAnalysis.totalPnl'] = { $gte: caseFilter.minTotalPnl };
        } else {
          aheadOfNewsOnly['aheadOfNewsAnalysis.totalPnl'] = { $lte: caseFilter.minTotalPnl };
        }
      }

      if (caseFilter.minEventPnl) {
        if (caseFilter.minEventPnl > 0) {
          aheadOfNewsOnly['aheadOfNewsAnalysis.eventPnl'] = { $gte: caseFilter.minEventPnl };
        } else {
          aheadOfNewsOnly['aheadOfNewsAnalysis.eventPnl'] = { $lte: caseFilter.minEventPnl };
        }
      }

      if (caseFilter.eventPosition) {
        if (caseFilter.eventPosition > 0) {
          aheadOfNewsOnly['aheadOfNewsAnalysis.positionBeforeEvent.position'] = { $gte: caseFilter.eventPosition };
        } else {
          aheadOfNewsOnly['aheadOfNewsAnalysis.positionBeforeEvent.position'] = { $lte: caseFilter.eventPosition };
        }
      }

      if (caseFilter.minEventPriceChangePerc) {
        var extremeChange = aheadOfNewsOnly['aheadOfNewsAnalysis.marketEvent.extremeChange'] = {};
        extremeChange[caseFilter.minEventPriceChangePercSymbol] = caseFilter.minEventPriceChangePerc / 100
        // aheadOfNewsOnly['aheadOfNewsAnalysis.marketEvent.extremeChange'] = {$gte: caseFilter.minEventPriceChangePerc / 100};
      }

      if (caseFilter.mustHaveOptions) {
        aheadOfNewsOr.push(
          { 'aheadOfNewsAnalysis.positionBeforeEvent.optionsPosition.position': { $exists: true }, analysisType: 'AHEAD_OF_NEWS' },
          { 'aheadOfNewsAnalysis.positionAfterEvent.optionsPosition.position': { $exists: true }, analysisType: 'AHEAD_OF_NEWS' }
        );
      }
      var markingTheCloseOnly = {};
      if (caseFilter.minMarketVolume) {
        markingTheCloseOnly['markingTheCloseAnalysis.marketVolume'] = { $gte: caseFilter.minMarketVolume };
      }

      if (caseFilter.minCustomerValue) {
        markingTheCloseOnly['markingTheCloseAnalysis.customerValue'] = { $gte: caseFilter.minCustomerValue };
      }

      if (caseFilter.minCustomerVolume) {
        if (caseFilter.minCustomerVolume > 0) {
          markingTheCloseOnly['markingTheCloseAnalysis.customerVolume'] = { $gte: caseFilter.minCustomerVolume };
        } else {
          markingTheCloseOnly['markingTheCloseAnalysis.customerVolume'] = { $lte: caseFilter.minCustomerVolume };
        }
      }

      if (caseFilter.minCustomerPercentageVolume) {
        markingTheCloseOnly['markingTheCloseAnalysis.marketPercentage'] = { $gte: caseFilter.minCustomerPercentageVolume / 100 };
      }

      if (caseFilter.minDayCustomerPercentageVolume) {
        markingTheCloseOnly['markingTheCloseAnalysis.totalDailyMarketPercentage'] = { $gte: caseFilter.minDayCustomerPercentageVolume / 100 };
      }

      if (caseFilter.minMarketPriceChangePerc) {
        markingTheCloseOnly['markingTheCloseAnalysis.pricePercentageChange'] = { $gte: caseFilter.minMarketPriceChangePerc / 100 };
      }

      if (caseFilter.mktScore) {
        markingTheCloseOnly['markingTheCloseAnalysis.score'] = { $gte: caseFilter.mktScore / 100 };
      }

      var washSaleOnly = {};
      var wsOr = [];
      if (caseFilter.minBuyExecutionsVolume) {
        washSaleOnly['washSaleAnalysis.buy.executions.volume'] = { $gte: caseFilter.minBuyExecutionsVolume };
      }

      if (caseFilter.minBuyExecutionsValue) {
        washSaleOnly['washSaleAnalysis.buy.executions.dollars'] = { $gte: caseFilter.minBuyExecutionsValue }
      }

      var minPercentDayVolume = caseFilter.minPercentDayVolume;
      if ((minPercentDayVolume || minPercentDayVolume === 0) && !isNaN(minPercentDayVolume)) {
        var floatValue = minPercentDayVolume >= 1 ? parseFloat(minPercentDayVolume) / 100 : minPercentDayVolume;
        washSaleOnly['washSaleAnalysis.scores.execOfDay.value'] = { $gte: floatValue };
      }

      if (caseFilter.wsScore) {
        washSaleOnly['washSaleAnalysis.scores.total'] = { $gte: caseFilter.wsScore / 100 };
      }

      var wsNotes = caseFilter.wsNotes
      var wsAnd = []
      if (caseFilter.wsNotes) {
        wsNotes = wsNotes
        var noteVal = getRegex(caseFilter.wsNoteComparator, wsNotes);
        var wsNoteOr = {
          $or: [
            { 'washSaleAnalysis.buy.order.notes': noteVal },
            { 'washSaleAnalysis.sell.order.notes': noteVal }
          ]
        }
        if (wsOr.length) {
          wsOr[0].$and.push(wsNoteOr)
        } else {
          wsOr.push({
            $and: [wsNoteOr]
          })
        }
      }

      var wsSource = caseFilter.wsSource;
      if (wsSource) {
        wsSource = wsSource.replace(/atoms/i, 'regular');
        var wsSourceOr = {
          $or: [
            { 'washSaleAnalysis.sell.order.source': wsSource },
            { 'washSaleAnalysis.buy.order.source': wsSource }
          ]
        };
        if (wsOr.length) {
          wsOr[0].$and.push(wsSourceOr)
        } else {
          wsOr.push({
            $and: [wsSourceOr]
          })
        }
      }

      if (caseFilter.wsOptionOnly) {
        var wsOptionOr = {
          $or: [
            { 'washSaleAnalysis.buy.order.product': 'RP1_OPTION', analysisType: 'WASH_SALE' },
            { 'washSaleAnalysis.sell.order.product': 'RP1_OPTION', analysisType: 'WASH_SALE' },
          ]
        }
        if (wsOr.length) {
          wsOr[0].$and.push(wsOptionOr)
        } else {
          wsOr.push({
            $and: [wsOptionOr]
          })
        }
      }

      var ruleOnly = {};
      var ruleOr = [];
      if (caseFilter.ruleName) {
        ruleOnly['rulesAnalysis.rule.name'] = caseFilter.ruleName;
      }

      if (caseFilter.ruleVolume) {
        ruleOnly['rulesAnalysis.flaggedSummary.volume'] = { $gte: caseFilter.ruleVolume };
      }

      if (caseFilter.ruleValue) {
        ruleOnly['rulesAnalysis.flaggedSummary.dollars'] = { $gte: caseFilter.ruleValue };
      }

      if (caseFilter.ruleSource) {
        ruleOnly['rulesAnalysis.flagged.prepOrder.order.source'] = caseFilter.ruleSource.replace(/atoms/i, 'regular');
      }

      var spoofingOr = [];

      var spoofingSource = caseFilter.spoofingSource
      if (spoofingSource) {
        spoofingSource = spoofingSource.replace(/atoms/i, 'regular');
        spoofingOr.push(
          {
            $and: [{
              $or: [
                { 'spoofingAnalysis.sell.order.source': spoofingSource },
                { 'spoofingAnalysis.buy.order.source': spoofingSource }
              ]
            }]
          }
        );
      }

      if (caseFilter.status) {
        commonAnd.push({
          $or: _.map(caseFilter.status.split(','), function (d) {
            return d === 'PENDING' ? { $or: [{ 'history.0.status': d }, { 'history': { $exists: false } }] } : { 'history.0.status': d };
          })
        });
      }
      if (caseFilter.rank) {
        commonCriteria['history.0.rank'] = caseFilter.rank;
      }

      if (caseFilter.action) {
        commonCriteria['history.0.action'] = caseFilter.action;
      }

      if (caseFilter.reason) {
        commonCriteria['history.0.reason'] = caseFilter.reason;
      }

      var encodingDefers = [];
      if (caseFilter.account) {
        var accountPromise = decoderFactory.encode(caseFilter.account).success(function (encodedData) {
          commonAnd.push({
            $or: [
              { 'washSaleAnalysis.buy.order.account_number': encodedData.hash },
              { 'washSaleAnalysis.sell.order.account_number': encodedData.hash },
              { 'accountNumber': encodedData.hash }
            ]
          });
        });
        encodingDefers.push(accountPromise);
      }
      if (caseFilter.branch) {
        var branch = caseFilter.branch
        branch = _.padRight(branch, 4, ' ')
        commonAnd.push({
          $or: [
            { 'washSaleAnalysis.buy.order.order_branch': branch },
            { 'washSaleAnalysis.sell.order.order_branch': branch },
            { 'spoofingAnalysis.buy.order.order_branch': branch },
            { 'spoofingAnalysis.sell.order.order_branch': branch },
          ]
        })
      }
      if (caseFilter.ibd) {
        var ibdPromise = decoderFactory.encode(caseFilter.ibd).success(function (encodedData) {
          commonAnd.push({
            $or: [
              { 'washSaleAnalysis.buy.order.acct_corrnum': encodedData.hash },
              { 'washSaleAnalysis.sell.order.acct_corrnum': encodedData.hash },
              { 'ibd': encodedData.hash }
            ]
          });
        });
        encodingDefers.push(ibdPromise);
      }

      if (caseFilterConfig.caseType === 'all') {
        var typeOr = [];
        var filterTypes = [
          'AHEAD_OF_NEWS',
          'MARKING_THE_CLOSE',
          'WASH_SALE',
          'RULES',
          'SPOOFING',
          'MCAP',
          'PL'
        ];

        if (!_.isEmpty(aheadOfNewsOnly) || !_.isEmpty(aheadOfNewsOr)) {
          if (!_.isEmpty(aheadOfNewsOr)) {
            _.forEach(aheadOfNewsOr, function (d) {
              typeOr.push(_.merge(d, aheadOfNewsOnly));
            });
          } else {
            typeOr.push(_.assign(aheadOfNewsOnly, { analysisType: 'AHEAD_OF_NEWS' }));
          }
          _.pull(filterTypes, 'AHEAD_OF_NEWS');
        }
        if (!_.isEmpty(markingTheCloseOnly)) {
          typeOr.push(_.assign(markingTheCloseOnly, { analysisType: 'MARKING_THE_CLOSE' }));
          _.pull(filterTypes, 'MARKING_THE_CLOSE');
        }
        if (!_.isEmpty(washSaleOnly) || !_.isEmpty(wsOr)) {
          if (!_.isEmpty(wsOr)) {
            _.forEach(wsOr, function (d) {
              typeOr.push(_.merge(d, washSaleOnly));
            });
          } else {
            typeOr.push(_.assign(washSaleOnly, { analysisType: 'WASH_SALE' }));
          }
          _.pull(filterTypes, 'WASH_SALE');
        }
        if (!_.isEmpty(ruleOnly)) {
          typeOr.push(_.assign(ruleOnly, { analysisType: 'RULES' }));
          _.pull(filterTypes, 'RULES');
        }

        if (!_.isEmpty(spoofingOr)) {
          _.forEach(spoofingOr, function (d) {
            typeOr.push(d)
          });
          _.pull(filterTypes, 'SPOOFING')
        }

        if (typeOr.length) {
          typeOr.push({
            analysisType: { $in: filterTypes }
          });
          commonAnd.push({ $or: typeOr });
        }
      } else {
        switch (caseFilterConfig.caseType) {
          case 'aheadOfNewsAnalysis':
            if (!_.isEmpty(aheadOfNewsOnly)) {
              _.assign(commonCriteria, aheadOfNewsOnly);
            }
            if (!_.isEmpty(aheadOfNewsOr)) {
              commonAnd.push({ $or: aheadOfNewsOr });
            }
            break;
          case 'markingTheCloseAnalysis':
            if (!_.isEmpty(markingTheCloseOnly)) {
              _.assign(commonCriteria, markingTheCloseOnly);
            }
            break;
          case 'washSaleAnalysis':
            if (!_.isEmpty(washSaleOnly)) {
              _.assign(commonCriteria, washSaleOnly);
            }
            if (!_.isEmpty(wsOr)) {
              commonAnd.push({ $or: wsOr });
            }
            break;
          case 'rulesAnalysis':
            if (!_.isEmpty(ruleOnly)) {
              _.assign(commonCriteria, ruleOnly);
            }
            break;
          case 'spoofingAnalysis':
            if (!_.isEmpty(spoofingOr)) {
              commonAnd.push({ $or: spoofingOr })
            }
            break;
        }
      }

      $q.all(encodingDefers).then(function () {
        defer.resolve(commonCriteria);
      });

      return defer.promise;
    }

    return {
      getFindCriteria: getFindCriteria,
    };
  });
