angular.module('sniperWebApp')
  .factory('omnibusFactory', function ($resource, config) {

    var omni = $resource(config.restApiUrl + '/metadata/accounts', {}, {
      fetch: {method: 'GET', isArray: true}
    });

    return {
      fetch: omni.fetch
    };
  })
  .factory('omnibusHandlerFactory', function ($rootScope, $q, omnibusFactory, anonymizerFactory) {
    var _data = [];

    function getData () {
      var defer = $q.defer();
      if(_data.length) {
        defer.resolve(_data);
        return defer.promise;
      }

      omnibusFactory.fetch().$promise.then(function (data) {
        if(!Array.isArray(data)) {
          defer.resolve(_data);
          return;
        };

        _data.push.apply(_data, data);
        var hashes = data.map(function (d) {
          return {
            hash: d.ibd
          };
        });
        anonymizerFactory.decode({hashRequest: hashes})
          .$promise.then(function (decodedData) {
            if(decodedData.length != _data.length) {
              $rootScope.alerts.push({type: 'danger', msg: 'Invalid reply'});
              return;
            }
            for (var i = 0; i < _data.length; i++) {
              if(decodedData[i].hash === decodedData[i].value) {
                decodedData.splice(i, 1);
                _data.splice(i, 1);
                i--;
              } else {
                _data[i].ibdDecoded = decodedData[i].value;
              }
            }
            defer.resolve(_data);
          });
      });

      return defer.promise;
    }

    return {
      getData: getData
    };
  });
  ;
