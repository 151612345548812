import zxcvbn from "zxcvbn";

angular
  .module("sniperWebApp")

  .controller(
    "AccountCtrl",
    function ($scope, $timeout, loginService, userFactory, cfpLoadingBar) {
      var user = ($scope.u = {
        oldPassword: "",
        newPassword: "",
        newPasswordAgain: "",
      });

      $scope.vm = {};

      $scope.checkPasswordStr = function () {
        $scope.vm = zxcvbn(user.newPassword);
        switch ($scope.vm.score) {
          case 0:
            $scope.vm.text = "too weak";
            break;
          case 1:
            $scope.vm.text = "weak";
            break;
          case 2:
            $scope.vm.text = "moderate";
            break;
          case 3:
            $scope.vm.text = "strong";
            break;
          case 4:
            $scope.vm.text = "very strong";
            break;
        }
      };

      function validate() {
        if ($scope.vm.score < 1) {
          $scope.addAlert(
            { type: "danger", msg: "Passwords is too weak." },
            2000
          );
          return false;
        }
        if (user.newPassword !== user.newPasswordAgain) {
          $scope.addAlert(
            { type: "danger", msg: "Passwords do not match." },
            2000
          );
          return false;
        }

        if (!user.newPassword) {
          $scope.addAlert(
            { type: "danger", msg: "Empty password not accepted." },
            2000
          );
          return false;
        }

        var clearnNewPw = user.newPassword.replace(/[^a-zA-Z]/g, "");
        var clearUserName = $scope.user.user.replace(/[^a-zA-Z]/g, "");
        var userNameRegex = new RegExp(clearUserName, "i");
        var containsUserName = userNameRegex.test(clearnNewPw);
        var containsPassword = /password/i.test(clearnNewPw);
        if (
          /\s/.test(user.newPassword) ||
          containsUserName ||
          containsPassword
        ) {
          $scope.addAlert(
            {
              type: "danger",
              msg: 'Password should not contain spaces, username, or the keyword "password"',
            },
            3000
          );
          return false;
        }
        var isSafe =
          user.newPassword.length >= 8 &&
          /[`!#$%^&*,.~[\]?]/.test(user.newPassword) &&
          /[a-z]/.test(user.newPassword) &&
          /[A-Z]/.test(user.newPassword);
        if (!isSafe) {
          $scope.addAlert(
            {
              type: "danger",
              msg: "Password must have a mininum of 8 characters with at least 1 uppercase and 1 lowercase alphabetic character and 1 special symbol (! ` # $ & % ^ , .)",
            },
            5000
          );
          return false;
        }

        return true;
      }

      $scope.changePassword = function () {
        if (!validate()) return;

        $scope.loadingPasswordUpdate = true;
        $scope.addAlert({
          type: "info",
          msg: "Updating password, please wait...",
          close: false,
        });
        userFactory
          .changePassword({ original: user.oldPassword, new: user.newPassword })
          .$promise.then(
            function () {
              cfpLoadingBar.start();
              $timeout(function () {
                //wait 15 secs for the server to update
                cfpLoadingBar.complete();
                $scope.closeAlert(0); //remove updating password message
                $scope.addAlert(
                  {
                    type: "success",
                    msg: "Password succesfully changed. Reloading...",
                  },
                  5000
                );
                $scope.loadingPasswordUpdate = false;
                $timeout(function () {
                  loginService
                    .login($scope.user.user, user.newPassword, "/account")
                    .error(function () {
                      $scope.addAlert(
                        { type: "danger", msg: "App authorization failed." },
                        5000
                      );
                    });
                }, 2000);
                //re-login
              }, 15000);
            },
            function (failReason) {
              $scope.loadingPasswordUpdate = false;
              $scope.closeAlert(0); //remove updating password message
              $scope.addAlert(
                {
                  type: "danger",
                  msg: "Could not change password (" + failReason.status + ").",
                },
                5000
              );
            }
          );
      };
    }
  );
