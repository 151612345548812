'use strict';

angular.module('sniperWebApp')
	.factory('newsFactory', function($resource, config) {

		var News = $resource(config.restApiUrl + '/news/interval/:symbol?from=:from&to=:to&type=:type&mins=:mins', {}, {
			list: {method: 'GET', params: {}, isArray: true},
		});

		return {
			list: function(spec) {
				return News.list(spec);
			}
		};
	});
