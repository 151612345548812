angular.module('sniperWebApp')
  .controller('OmnibusController', function($filter, $routeParams, $controller, $scope, $timeout, config, omnibusHandlerFactory) {
    angular.extend(this, $controller('AdminController', {$scope: $scope}));

    var paging = $scope.paging = {
      currentPage: 1,
      totalPages: 0,
      totalSymbols: 0,
    };

    $scope.filterConfig = {
      sortIbd: false
    };

    function getCurrentOmnibus () {
      if(!$scope.omnibusData) return;
      var startIndex = (paging.currentPage - 1) * 100,
          endIndex = startIndex + 100;

      $scope.currentOmnibusData = $scope.omnibusData.slice(startIndex, endIndex);
    }

    function getPagingInfo(data) {
      paging.totalPages = Math.ceil(data.length/100);
      paging.totalSymbols = data.length;
      paging.currentPage = 1;
    }

    $scope.$watch('paging.currentPage', getCurrentOmnibus);

    omnibusHandlerFactory.getData().then(function (data) {
      _.remove(data, function (omnibus) { return !omnibus.ibdDecoded; });
      $scope.omnibusData = data;
      getPagingInfo(data);
      getCurrentOmnibus();
    });


    $scope.sort = function () {
      if(!$scope.omnibusData) return;
      $scope.filterConfig.sortIbd = !$scope.filterConfig.sortIbd;
      var order = $scope.filterConfig.sortIbd ? 'asc' : 'desc';
      $scope.omnibusData = _.sortByOrder($scope.omnibusData, 'ibdDecoded', order);
      console.log($scope.omnibusData);
      getCurrentOmnibus();
    };

  });
