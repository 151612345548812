"use strict";
angular
  .module("sniperWebApp")
  .factory("customerDataFactory", function ($resource, $http, config) {
    function appendTransform(defaults, transform) {
      // We can't guarantee that the default transformation is an array
      defaults = angular.isArray(defaults) ? defaults : [defaults];

      // Append the new transformation to the defaults
      return defaults.concat(transform);
    }

    var CustomerData = $resource(
      config.restApiUrl +
        "/cdata/interval/ctrades/:accountNumber/:symbol/?from=:from&to=:to&collapse=:collapse",
      {},
      {
        trades: {
          method: "GET",
          url:
            config.restApiUrl +
            "/cdata/interval/ctrades/:accountNumber/:symbol/?from=:from&to=:to&mins=:mins&collapse=:collapse&pageSize=:pageSize&pageNo=:pageNo&side=:side",
          isArray: true,
        },
        paging: {
          method: "GET",
          url:
            config.restApiUrl +
            "/cdata/paging/interval/ctrades/:accountNumber/:symbol/?from=:from&to=:to&mins=:mins&collapse=:collapse&pageSize=:pageSize&pageNo=:pageNo",
        },
        positionHistory: {
          method: "GET",
          url:
            config.restApiUrl +
            "/cdata/position/interval?symbol=:symbol&from=:from&to=:to&account=:accountNumber&mins=:mins",
          isArray: true,
        },
        volumeHistory: {
          method: "GET",
          url:
            config.restApiUrl +
            "/cdata/volume/interval?symbol=:symbol&from=:from&to=:to&account=:accountNumber&mins=:mins",
          isArray: true,
        },
        washsaleTrades: {
          method: "GET",
          url: config.restApiUrl + "/cases/washsale/trades/:caseId",
          isArray: true,
        },
        spoofingTrades: {
          method: "GET",
          url: config.restApiUrl + "/cases/spoofing/trades/:caseId",
          isArray: true,
        },
        spoofingTradesWithSides: {
          method: "GET",
          url: config.restApiUrl + "/cases/spoofing/trades/:caseId?side=:side",
          isArray: true,
        },
      }
    );

    function getTradesCsv(spec) {
      var url =
        config.restApiUrl +
        "/report/cdata/interval/ctrades/" +
        spec.account +
        "/" +
        spec.symbol +
        "/";
      delete spec.account;
      delete spec.symbol;
      return $http({
        method: "GET",
        url: url,
        params: spec,
        transformResponse: appendTransform(
          $http.defaults.transformResponse,
          (data) => data
        ),
      });
    }

    return {
      trades: function (spec) {
        return CustomerData.trades(spec);
      },
      paging: function (spec) {
        return CustomerData.paging(spec);
      },
      positionHistory: function (spec) {
        return CustomerData.positionHistory(spec);
      },
      volumeHistory: function (spec) {
        return CustomerData.volumeHistory(spec);
      },
      washsaleTrades: function (spec) {
        return CustomerData.washsaleTrades(spec);
      },
      spoofingTrades: function (spec) {
        return CustomerData.spoofingTrades(spec);
      },
      spoofingTradesWithSides: function (spec) {
        return CustomerData.spoofingTradesWithSides(spec);
      },
      getTradesCsv: function (spec) {
        return getTradesCsv(spec);
      },
    };
  })
  .service("CustomerDataService", function ($http, config) {
    /**
     * @param  {[type]} params
     * {
     *   from      - YYYYMMDD
     *   to        - YYYYMMDD
     *   mins      - integer
     *   collapse  - 0/1
     *   pageSize  - integer
     *   pageNo    - integer
     *   side      - buy/sell/any
     * }
     */
    this.trades = function (params, accountNumber, symbol) {
      return $http({
        method: "GET",
        url:
          config.restApiUrl +
          "/cdata/interval/ctrades/" +
          accountNumber +
          "/" +
          symbol +
          "/",
        params: params,
      });
    };

    /**
     * @param  {[type]} params
     * {
     *   from      - YYYYMMDD
     *   to        - YYYYMMDD
     *   mins      - integer
     *   collapse  - 0/1
     *   pageSize  - integer
     *   side      - buy/sell/any
     * }
     */
    this.paging = function (params, accountNumber, symbol) {
      return $http({
        method: "GET",
        url:
          config.restApiUrl +
          "/cdata/paging/interval/ctrades/" +
          accountNumber +
          "/" +
          symbol +
          "/",
        params: params,
      });
    };
  });
