import { filterFormatNumber, filterPercentage } from "../columns/filter";

function jsonToCsv() {
  function convert(objArray) {
    var array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    var str = "",
      line = "";

    for (var index in array[0]) {
      line += index + ",";
    }

    line = line.replace(/[, ]$/, "").trim();
    str += line + "\r\n";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        var cell = array[i][index];
        if (typeof cell === "string") {
          cell = cell.replace(/,/g, "");
        }
        line += cell + ",";
      }

      line = line.replace(/,$/, "").trim();
      str += line + "\r\n";
    }
    return str;
  }

  return {
    convert: convert,
  };
}

function downloadCsv(data, fileName) {
  if (typeof data === "object") {
    data = jsonToCsv().convert(data);
  }

  var charset = "utf-8";
  var blob = new Blob([data], {
    type: "text/csv;charset=" + charset + ";",
  });

  fileName = fileName.replace(/.csv$/, "") + ".csv";

  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    var downloadContainer = angular.element(
      '<div data-tap-disabled="true"><a></a></div>'
    );
    var downloadLink = angular.element(downloadContainer.children()[0]);
    downloadLink.attr("href", window.URL.createObjectURL(blob));
    downloadLink.attr("download", fileName);
    downloadLink.attr("target", "_blank");
    $(document).find("body").append(downloadContainer);
    setTimeout(function () {
      downloadLink[0].click();
      downloadLink.remove();
    }, null);
  }
}

export function exportToCsv(collection, fileName, columns) {
  if (_.isEmpty(collection)) {
    return;
  }
  var csvRows = _.map(collection, function (c) {
    var row = {};
    _.forEach(columns, function (col) {
      var cellData = _.get(c, col.value);
      if (col.filter) {
        cellData = col.filter(cellData, c);
      } else if (col.percent) {
        cellData = filterPercentage(cellData, col.decimal || 2, col.withMark);
      } else if (col.decimal !== undefined) {
        cellData = filterFormatNumber(cellData, col.decimal);
      } else if (col.text && cellData) {
        cellData = cellData.replace(/"/g, "")
        cellData = `=\"${cellData}\"`
      }
      if (_.isNull(cellData) || cellData === undefined) {
        cellData = "";
      } else if (typeof cellData === "string") {
        cellData = cellData.replace(/\n/g, "");
      }
      row[col.name] = cellData;
    });
    return row;
  });
  downloadCsv(csvRows, fileName);
}

angular.module("sniperWebApp").factory("jsonToCsv", jsonToCsv);
