"use strict";

var pershing = [
  "atoms",
  "bkmm",
  "convergex",
  "dash",
  "inca",
  "rtick",
  "tps",
  "cowen",
];
var sources = {
  // pershing: pershing,
  // pas: pershing,
  // trowe: pershing,
  // insigneo: pershing,
  bbnt: ["flex", "beta"],
  bnymellon: ["bkcm"],
  pact: ["fidessa", "regular", "tps"],
};

var clientSource = pershing;
var pathName = location.pathname;
if (/bbnt/.test(pathName)) {
  clientSource = sources.bbnt;
} else if (/bnymellon/.test(pathName)) {
  clientSource = sources.bnymellon;
} else if (/pact/.test(pathName)) {
  clientSource = sources.pact;
}

var allReasons = [
  { name: "None", id: "" },
  { name: "Event Not Relevant", id: "EVT_NOT_RELVT" },
  { name: "Anticipated Event", id: "ANT_EVT" },
  { name: "Unanticipated Event", id: "UNANT_EVT" },
  { name: "Consistent with Prior Activity", id: "CONST_WITH_PRIOR" },
  { name: "Not Consistent with Prior Activity", id: "NOT_CONST_WITH_PRIOR" },
  { name: "Unusually Large Transaction", id: "LARGE_TRANS" },
  { name: "Unusually Profitable Transaction", id: "PROFIT_TRANS" },
  { name: "Highly Leveraged", id: "HIGH_LEVGD" },
  { name: "Change of Beneficial Owner", id: "CHANGE_OF_OWNER" },
  { name: "No Change of Beneficial Owner", id: "NO_CHANGE_OF_OWNER" },

  // Marking the Close
  { name: "A/C establishing a new position", id: "AC_NEW_POS" },
  {
    name: "Substantial notional value  trade/Legitimate interest",
    id: "SUBST_VAL",
  },
  { name: "No manipulative pattern of activity seen", id: "NO_MANIP_PTRN" },
  {
    name: "Stock price move in the marketplace not caused by the A/C activity",
    id: "PR_MOVE_NO_AC",
  },
  { name: "Potential Marking The Close end of quarter/year", id: "MC_QUAT" },
  { name: "Multiple exceptions/pattern", id: "MULTI_PTRN" },

  // Ahead of News
  { name: "Reverse Split / Forward Split", id: "REV_FWD_SPLIT" },
];
export var config = {
  restApiUrl: "http://localhost:8080",
  predefinedFilterDates: [
    { name: "Date Range", id: "{}" },
    { name: "Last Trading Date", id: '{"from": -1, "to": -1}' },
    { name: "Week to Date", id: '{"from": -5, "to": -1}' },
    { name: "Month to Date", id: '{"from": -20, "to": -1}' },
  ],
  caseRankChoices: [
    { name: "None", id: "NONE" },
    { name: "Low", id: "LOW" },
    { name: "Medium", id: "MEDIUM" },
    { name: "High", id: "HIGH" },
  ],
  caseRankChoicesLookup: function (id) {
    try {
      return this.caseRankChoices.filter(function (c) {
        return c.id === id;
      })[0].name;
    } catch (e) {}
  },
  caseActionChoices: [
    { name: "None", id: "NOTHING" },
    { name: "Continue Review", id: "CONT_REV" },
    { name: "Note IBD and Account", id: "NOTE_IBD_ACCT" },
    { name: "Request Info from IBD", id: "REQ_NFO_FROM_IBD" },
    { name: "File Incident Report", id: "REPORT" },
  ],
  caseActionChoicesLookup: function (id) {
    try {
      return this.caseActionChoices.filter(function (c) {
        return c.id === id;
      })[0].name;
    } catch (e) {
      console.log(e);
    }
  },
  caseReasonChoices: {
    all: [
      { name: "None", id: "" },
      { name: "Event Not Relevant", id: "EVT_NOT_RELVT" },
      { name: "Anticipated Event", id: "ANT_EVT" },
      { name: "Unanticipated Event", id: "UNANT_EVT" },
      { name: "Consistent with Prior Activity", id: "CONST_WITH_PRIOR" },
      {
        name: "Not Consistent with Prior Activity",
        id: "NOT_CONST_WITH_PRIOR",
      },
      { name: "Unusually Large Transaction", id: "LARGE_TRANS" },
      { name: "Unusually Profitable Transaction", id: "PROFIT_TRANS" },
      { name: "Highly Leveraged", id: "HIGH_LEVGD" },
      { name: "Change of Beneficial Owner", id: "CHANGE_OF_OWNER" },
      { name: "No Change of Beneficial Owner", id: "NO_CHANGE_OF_OWNER" },
    ],
    AHEAD_OF_NEWS: [
      { name: "None", id: "" },
      { name: "Event Not Relevant", id: "EVT_NOT_RELVT" },
      { name: "Anticipated Event", id: "ANT_EVT" },
      { name: "Unanticipated Event", id: "UNANT_EVT" },
      { name: "Consistent with Prior Activity", id: "CONST_WITH_PRIOR" },
      {
        name: "Not Consistent with Prior Activity",
        id: "NOT_CONST_WITH_PRIOR",
      },
      { name: "Unusually Large Transaction", id: "LARGE_TRANS" },
      { name: "Unusually Profitable Transaction", id: "PROFIT_TRANS" },
      { name: "Highly Leveraged", id: "HIGH_LEVGD" },
      { name: "Reverse Split / Forward Split", id: "REV_FWD_SPLIT" },
    ],
    MARKING_THE_CLOSE: [
      { name: "None", id: "" },
      { name: "Event not relevant", id: "EVT_NOT_RELVT" },
      { name: "Consistent with Prior Activity", id: "CONST_WITH_PRIOR" },
      { name: "A/C establishing a new position", id: "AC_NEW_POS" },
      {
        name: "Substantial notional value  trade/Legitimate interest",
        id: "SUBST_VAL",
      },
      { name: "No manipulative pattern of activity seen", id: "NO_MANIP_PTRN" },
      {
        name: "Stock price move in the marketplace not caused by the A/C activity",
        id: "PR_MOVE_NO_AC",
      },
      {
        name: "Potential Marking The Close end of quarter/year",
        id: "MC_QUAT",
      },
      { name: "Multiple exceptions/pattern", id: "MULTI_PTRN" },
    ],
    WASH_SALE: [
      { name: "None", id: "" },
      { name: "Event Not Relevant", id: "EVT_NOT_RELVT" },
      { name: "Change of Beneficial Owner", id: "CHANGE_OF_OWNER" },
      { name: "No Change of Beneficial Owner", id: "NO_CHANGE_OF_OWNER" },
      { name: "Unusually Large Transaction", id: "LARGE_TRANS" },
    ],
    RULES: [
      { name: "None", id: "" },
      { name: "Event Not Relevant", id: "EVT_NOT_RELVT" },
      { name: "Anticipated Event", id: "ANT_EVT" },
      { name: "Unanticipated Event", id: "UNANT_EVT" },
      { name: "Consistent with Prior Activity", id: "CONST_WITH_PRIOR" },
      {
        name: "Not Consistent with Prior Activity",
        id: "NOT_CONST_WITH_PRIOR",
      },
      { name: "Unusually Large Transaction", id: "LARGE_TRANS" },
      { name: "Unusually Profitable Transaction", id: "PROFIT_TRANS" },
      { name: "Highly Leveraged", id: "HIGH_LEVGD" },
      { name: "Change of Beneficial Owner", id: "CHANGE_OF_OWNER" },
      { name: "No Change of Beneficial Owner", id: "NO_CHANGE_OF_OWNER" },
    ],
    SPOOFING: [
      { name: "None", id: "" },
      { name: "Event Not Relevant", id: "EVT_NOT_RELVT" },
      { name: "Change of Beneficial Owner", id: "CHANGE_OF_OWNER" },
      { name: "No Change of Beneficial Owner", id: "NO_CHANGE_OF_OWNER" },
      { name: "Unusually Large Transaction", id: "LARGE_TRANS" },
    ],
    MCAP: [
      { name: "None", id: "" },
      { name: "Event Not Relevant", id: "EVT_NOT_RELVT" },
      { name: "Anticipated Event", id: "ANT_EVT" },
      { name: "Unanticipated Event", id: "UNANT_EVT" },
      { name: "Consistent with Prior Activity", id: "CONST_WITH_PRIOR" },
      {
        name: "Not Consistent with Prior Activity",
        id: "NOT_CONST_WITH_PRIOR",
      },
      { name: "Unusually Large Transaction", id: "LARGE_TRANS" },
      { name: "Unusually Profitable Transaction", id: "PROFIT_TRANS" },
      { name: "Highly Leveraged", id: "HIGH_LEVGD" },
      { name: "Change of Beneficial Owner", id: "CHANGE_OF_OWNER" },
      { name: "No Change of Beneficial Owner", id: "NO_CHANGE_OF_OWNER" },
    ],
    PL: [
      { name: "None", id: "" },
      { name: "Event Not Relevant", id: "EVT_NOT_RELVT" },
      { name: "Anticipated Event", id: "ANT_EVT" },
      { name: "Unanticipated Event", id: "UNANT_EVT" },
      { name: "Consistent with Prior Activity", id: "CONST_WITH_PRIOR" },
      {
        name: "Not Consistent with Prior Activity",
        id: "NOT_CONST_WITH_PRIOR",
      },
      { name: "Unusually Large Transaction", id: "LARGE_TRANS" },
      { name: "Unusually Profitable Transaction", id: "PROFIT_TRANS" },
      { name: "Highly Leveraged", id: "HIGH_LEVGD" },
      { name: "Change of Beneficial Owner", id: "CHANGE_OF_OWNER" },
      { name: "No Change of Beneficial Owner", id: "NO_CHANGE_OF_OWNER" },
    ],
  },
  caseReasonChoicesLookup: function (id) {
    try {
      return allReasons.filter(function (c) {
        return c.id === id;
      })[0].name;
    } catch (e) {}
  },
  caseStatusChoices: [
    { name: "Pending", id: "PENDING" },
    { name: "Ongoing", id: "ONGOING" },
    { name: "Closed", id: "CLOSED" },
    { name: "Supervisory Review Completed", id: "SUPERVISORY" },
  ],
  caseStatusFilterChoices: [
    { name: "Pending/Ongoing", id: "PENDING,ONGOING" },
    { name: "Pending", id: "PENDING" },
    { name: "Ongoing", id: "ONGOING" },
    { name: "Closed", id: "CLOSED" },
    { name: "Supervisory Review Completed", id: "SUPERVISORY" },
    { name: "All", id: "PENDING,ONGOING,CLOSED,SUPERVISORY" },
  ],
  wsSources: _.map(clientSource, function (o) {
    return { name: o.replace("regular", "atoms"), id: o };
  }),
  caseTypeChoices: [
    { name: "All Surveillance", id: "all", analysisType: "all" },
    {
      name: "Ahead of News",
      id: "aheadOfNewsAnalysis",
      analysisType: "AHEAD_OF_NEWS",
    },
    {
      name: "Marking the Close",
      id: "markingTheCloseAnalysis",
      analysisType: "MARKING_THE_CLOSE",
    },
    { name: "Wash Sales", id: "washSaleAnalysis", analysisType: "WASH_SALE" },
    { name: "Rules", id: "rulesAnalysis", analysisType: "RULES" },
    { name: "Reg-M 105", id: "rulesAnalysis", analysisType: "RULES" },
    { name: "Spoofing", id: "spoofingAnalysis", analysisType: "SPOOFING" },
    { name: "Market Cap", id: "mcapAnalysis", analysisType: "MCAP" },
    { name: "P&L", id: "plAnalysis", analysisType: "PL" },
  ],
  ruleConditions: [
    { name: "Equal", id: "EQ" },
    { name: "Not Equal", id: "NE" },
    { name: "Less Than", id: "LT" },
    { name: "Greater Than", id: "GT" },
  ],
};
angular
  .module("sniperWebApp")
  .constant("allReasons", allReasons)
  .constant("config", config);
