angular
  .module("sniperWebApp")
  .controller(
    "MetaConfigController",
    function (
      $filter,
      $controller,
      $scope,
      $timeout,
      $q,
      config,
      metaConfigFactory,
      metaConfigFilterFactory,
      metaConfigHandlerFactory,
      anonymizerFactory
    ) {
      angular.extend(this, $controller("AdminController", { $scope: $scope }));
      var editConfigType =
        $scope.currentTab === "Symbol_Exclusion"
          ? ["EXCLUSION"]
          : ["DAILY", "AFTER_MARKET_DAY", "BEFORE_MARKET_DAY", "OTHER"];

      $scope.filteredConfigSet = [];
      $scope.currentPageConfigSet = [];
      $scope.allConfigs = [];
      $scope.config = config;
      $scope.constants = {
        configType: [
          "ALL",
          "EXCLUSION",
          "DAILY",
          "AFTER_MARKET_DAY",
          "BEFORE_MARKET_DAY",
          "OTHER",
        ],
        editAnalysisType: ["AHEAD_OF_NEWS", "MARKING_THE_CLOSE", "WASH_SALE"],
        editConfigType: editConfigType,
      };
      $scope.filter = {
        startDate: "",
        endDate: "",
        configType: "",
        symbol: "",
        predefinedDateSelection: "{}",
        caseType: "all",
      };
      $scope.filterConfig = {
        selectedConfig: null,
        sortConfigType: true,
        sortAnalysisType: true,
        sortName: true,
        sortAccount: true,
        sortSymbol: true,
        sortStartDate: false,
        sortEndDate: false,
      };

      $scope.sort = function (key, reverse) {
        $scope.allConfigs = metaConfigHandlerFactory.sort(
          $scope.allConfigs,
          key,
          reverse
        );
        $scope.filteredConfigSet = angular.copy($scope.allConfigs);
        getCurrentCases();
      };

      var paging = ($scope.paging = {
        currentPage: 1,
        totalPages: 0,
        totalSymbols: 0,
      });

      function setDatesFromCurrentPredefindSelection() {
        var dateRange = angular.fromJson($scope.filter.predefinedDateSelection);
        if (dateRange.from) {
          $scope.filter.startDate =
            dateRange.from < 0
              ? moment().businessSubtract(Math.abs(dateRange.from)).toDate()
              : moment().businessAdd(Math.abs(dateRange.from)).toDate();
        }
        if (dateRange.to) {
          $scope.filter.endDate =
            dateRange.to < 0
              ? moment().businessSubtract(Math.abs(dateRange.to)).toDate()
              : moment().businessAdd(Math.abs(dateRange.to)).toDate();
        }
      }

      $scope.$watch(
        "filter.predefinedDateSelection",
        setDatesFromCurrentPredefindSelection
      );

      function displayMessage(type, msg, timer) {
        $scope.addAlert({ type: type, msg: msg }, timer);
      }

      function getPagingInfo(data, toLastPage) {
        paging.totalPages = Math.ceil(data.length / 100);
        paging.totalSymbols = data.length;
        if (!toLastPage) {
          paging.currentPage = 1;
        } else {
          paging.currentPage = paging.totalPages;
        }
      }

      function getCurrentCases() {
        var startIndex = (paging.currentPage - 1) * 100,
          endIndex = startIndex + 100;

        $scope.currentPageConfigSet = $scope.filteredConfigSet.slice(
          startIndex,
          endIndex
        );
      }
      $scope.$watch("paging.currentPage", getCurrentCases);
      $scope.$watch("filteredConfigSet", function () {
        paging.currentPage = 1;
      });
      $scope.$watch("filter.caseType", function (type) {
        if (!type || !$scope.allConfigs.length) return;
        $scope.submitFilter();
        $scope.editEntry = null;
        $scope.isOpen = false;
      });

      $scope.$watch("editEntry", function () {
        $scope.invalidAccount = false;
        $scope.formSubmitted = false;
      });
      // $scope.tabs = [
      //   {name: 'Symbols', clickFunc: function () {$scope.nav('/admin')}},
      //   {name: 'Rules', selected: true}
      // ];

      /***************************/
      /** Get All Saved Entries **/
      function getSavedAnalysis() {
        // if(!metaConfigFactory.getAllConfig().length) {
        fetchSavedAnalysis();
        // } else {
        //   var data = metaConfigFactory.getAllConfig();
        //   $scope.allConfigs = data;
        //   $scope.filteredConfigSet = angular.copy(data);
        //   getCurrentCases();
        //   getPagingInfo(data);
        // }
      }
      function fetchSavedAnalysis() {
        metaConfigFactory
          .fetchAll({ configTypes: $scope.constants.editConfigType.toString() })
          .$promise.then(function (data) {
            data = metaConfigHandlerFactory.sort(data, "startDate", true);
            data.forEach(function (d, i) {
              if (d.accountNumber) {
                anonymizerFactory
                  .decode({ hashRequest: [{ hash: d.accountNumber }] })
                  .$promise.then(function (decodedData) {
                    // d.accountNumberEncoded = d.accountNumber;
                    // $scope.filteredConfigSet[i].accountNumberEncoded = d.accountNumber;
                    // d.accountNumber = decodedData[0].value;
                    // $scope.filteredConfigSet[i].accountNumber = decodedData[0].value;
                    // if(i < 100) {
                    //   $scope.currentPageConfigSet[i].accountNumber = decodedData[0].value;
                    // } else {
                    //   getCurrentCases();
                    // }
                    d.accountNumberDecoded = decodedData[0].value;
                    $scope.filteredConfigSet[i].accountNumberDecoded =
                      decodedData[0].value;
                  });
              }
            });
            metaConfigFactory.setAllConfig(data);
            $scope.allConfigs = data;
            $scope.filteredConfigSet = angular.copy(data);
            getCurrentCases();
            getPagingInfo(data);
          });
      }
      getSavedAnalysis();

      /** End of Saved Entries **/
      /**************************/

      /**********************/
      /** Adding New Entry **/
      var newEntry = ($scope.newEntry = {
        analysisType: $scope.currentTab === "Rules" ? "RULES" : "",
        configType: $scope.currentTab === "Symbol_Exclusion" ? "EXCLUSION" : "",
        symbol: "",
        startDate: moment.valueOf(),
        formattedStartDate: moment(moment().valueOf()).format("MM/DD/YYYY"),
        endDate: "",
      });

      function clearNewEntry() {
        for (var i in newEntry) {
          newEntry[i] = "";
        }

        newEntry.startDate = moment.valueOf();
        newEntry.analysisType = $scope.currentTab === "Rules" ? "RULES" : "";
        newEntry.configType =
          $scope.currentTab === "Symbol_Exclusion" ? "EXCLUSION" : "";
      }

      function validateNewEntry() {
        var keysToValidate;
        if (
          ($scope.newEntry.analysisType === "RULES" ||
            $scope.newEntry.analysisType === "REGM") &&
          $scope.currentTab !== "Symbol_Exclusion"
        ) {
          keysToValidate = ["analysisType", "configType", "name"];
        } else {
          keysToValidate = ["analysisType", "configType", "symbol"];
        }
        for (var i in keysToValidate) {
          if (!newEntry[keysToValidate[i]]) return false;
        }
        return true;
      }

      function addAdditionalRuleParam(queryParam, entry) {
        var defer = $q.defer();
        if (entry.analysisType !== "RULES") {
          defer.resolve(queryParam);
          return defer.promise;
          // return queryParam;
        }

        angular.extend(queryParam, {
          name: entry.name,
          accountNumber: entry.accountNumberDecoded,
          logRecord: {
            comment: entry.logRecord ? entry.logRecord.comment : "",
          },
        });

        if (
          entry.priceParam &&
          entry.priceParam.value &&
          entry.priceParam.value > 0
        ) {
          entry.priceParam.value = parseFloat(entry.priceParam.value);
          angular.extend(queryParam, {
            priceParam: entry.priceParam,
          });
        } else {
          delete entry.priceParam;
        }

        if (
          entry.volumeParam &&
          entry.volumeParam.value &&
          entry.volumeParam.value > 0
        ) {
          entry.volumeParam.value = parseFloat(entry.volumeParam.value);
          angular.extend(queryParam, {
            volumeParam: entry.volumeParam,
          });
        } else {
          delete entry.volumeParam;
        }

        if (
          entry.dollarsParam &&
          entry.dollarsParam.value &&
          entry.dollarsParam.value > 0
        ) {
          entry.dollarsParam.value = parseFloat(entry.dollarsParam.value);
          angular.extend(queryParam, {
            dollarsParam: entry.dollarsParam,
          });
        } else {
          delete entry.dollarsParam;
        }

        if (entry.symbol || entry.accountNumber) {
          angular.extend(queryParam, {
            filterBy:
              entry.symbol &&
              (entry.accountNumber || entry.accountNumberDecoded)
                ? "SYMBOL_ACCOUNT"
                : entry.accountNumber
                ? "ACCOUNT"
                : "SYMBOL",
          });
        } else {
          angular.extend(queryParam, {
            filterBy: "SYMBOL",
          });
        }

        if (entry.accountNumberDecoded) {
          anonymizerFactory.encode(entry.accountNumberDecoded).$promise.then(
            function (encodedAccountNumber) {
              angular.extend(queryParam, {
                accountNumber: encodedAccountNumber.hash,
              });
              defer.resolve(queryParam);
            },
            function () {
              $scope.invalidAccount = true;
              displayMessage("danger", "Invalid Account Number", 5000);
            }
          );
        } else {
          defer.resolve(queryParam);
        }
        return defer.promise;
        // return queryParam;
      }

      $scope.saveNewEntry = function () {
        $scope.formSubmitted = true;
        if (!validateNewEntry()) {
          return;
        }
        var startDate = (newEntry.startDate = newEntry.formattedStartDate
          ? moment(newEntry.formattedStartDate).valueOf()
          : null);
        var endDate = (newEntry.endDate = newEntry.formattedEndDate
          ? moment(newEntry.formattedEndDate).valueOf()
          : null);
        var queryParam = {
          analysisType: newEntry.analysisType,
          configType: newEntry.configType,
          symbol: newEntry.symbol,
          startDate: startDate,
          endDate: endDate,
        };
        var newCopyEntry = angular.copy(newEntry);
        $scope.disableSubmit = true;
        addAdditionalRuleParam(queryParam, newCopyEntry).then(function (
          params
        ) {
          queryParam = params;
          console.log(queryParam);
          metaConfigFactory.save(queryParam).$promise.then(
            () => {
              // console.log('successfully saved', data);
              clearNewEntry();
              $scope.isOpen = $scope.disableSubmit = false;
              fetchSavedAnalysis();
              displayMessage("success", "Successfully Saved", 3000);
            },
            () => {
              // console.error('failed', error);
              displayMessage("danger", "Invalid Reply", 3000);
            }
          );
        });
      };
      /** End of New Entry **/
      /**********************/

      /****************************/
      /** Editing Existing Entry **/
      function validateExistingEntry() {
        var keysToValidate;
        if (
          ($scope.editEntry.analysisType === "RULES" ||
            $scope.editEntry.analysisType === "REGM") &&
          $scope.currentTab !== "Symbol_Exclusion"
        ) {
          keysToValidate = ["analysisType", "configType", "name"];
        } else {
          keysToValidate = ["analysisType", "configType", "symbol"];
        }
        for (var i in keysToValidate) {
          if (!$scope.editEntry[keysToValidate[i]]) {
            return false;
          }
        }
        return true;
      }

      $scope.openEditContent = function () {
        var selectedConfig = $scope.filterConfig.selectedConfig;
        if (!selectedConfig) {
          return;
        }

        $scope.isEdit = $scope.isOpen = true;
        if ($scope.editEntry && $scope.editEntry.mid === selectedConfig.mid) {
          return;
        }
        $scope.editEntry = angular.copy(selectedConfig);
        $scope.editEntry.formattedEndDate = selectedConfig.endDate
          ? $filter("estFilter")(selectedConfig.endDate, "MM/DD/YYYY")
          : null;
        $scope.editEntry.formattedStartDate = selectedConfig.startDate
          ? $filter("estFilter")(selectedConfig.startDate, "MM/DD/YYYY")
          : null;
        $scope.editEntry.priceParam = $scope.editEntry.priceParam || {
          condition: "EQ",
        };
        $scope.editEntry.volumeParam = $scope.editEntry.volumeParam || {
          condition: "EQ",
        };
        $scope.editEntry.dollarsParam = $scope.editEntry.dollarsParam || {
          condition: "EQ",
        };
      };

      $scope.updateExistingEntry = function () {
        $scope.formSubmitted = true;
        $scope.invalidAccount = false;
        if (!validateExistingEntry()) {
          return;
        }
        $scope.disableSubmit = true;
        $scope.editEntry.startDate = $scope.editEntry.formattedStartDate
          ? moment($scope.editEntry.formattedStartDate).valueOf()
          : null;
        $scope.editEntry.endDate = $scope.editEntry.formattedEndDate
          ? moment($scope.editEntry.formattedEndDate).valueOf()
          : null;
        var editEntry = angular.copy($scope.editEntry);
        delete editEntry.formattedEndDate;
        delete editEntry.formattedStartDate;
        addAdditionalRuleParam(editEntry, editEntry).then(function (editEntry) {
          editEntry.$save(
            {},
            () => {
              $scope.editEntry = null;
              $scope.filterConfig.selectedConfig = null;
              $scope.isEdit = $scope.isOpen = $scope.disableSubmit = false;
              fetchSavedAnalysis();
              displayMessage("success", "Successfully Updated", 3000);
            },
            () => {
              displayMessage("danger", "Invalid Reply", 3000);
            }
          );
        });
      };

      $scope.destroyExistingEntry = function () {
        if (!$scope.filterConfig.selectedConfig) {
          //@Todo create an alert message saying select a case
          return;
        }
        var selectedConfig = angular.copy($scope.filterConfig.selectedConfig);
        selectedConfig.active = false;
        selectedConfig.endDate = moment().valueOf();
        $scope.disableSubmit = true;
        metaConfigFactory.save(selectedConfig).$promise.then(
          () => {
            $scope.disableSubmit = false;
            $scope.filterConfig.selectedConfig = null;
            fetchSavedAnalysis();
            displayMessage("success", "Successfully Removed", 3000);
          },
          () => {
            displayMessage("danger", "Invalid Reply", 3000);
          }
        );
      };
      /** End of Editing Existing Entry **/
      /***********************************/

      /**
       * [keepItOpen keep the bottom edit or add entry bar open depening on cases]
       */
      $scope.keepItOpen = function (addOrEdit, isEdit, isOpen, event) {
        if (addOrEdit === "add") {
          if (isEdit && isOpen) {
            $scope.isOpen = true;
            event.stopPropagation();
          }
          $scope.isEdit = false;
        } else {
          if (!$scope.editEntry || !$scope.editEntry.mid) {
            event.preventDefault();
            event.stopPropagation();
            return;
          }
          if (!isEdit && isOpen) {
            $scope.isOpen = true;
            event.stopPropagation();
          }
          $scope.isEdit = true;
        }
      };

      $scope.submitFilter = function () {
        $scope.filteredConfigSet = metaConfigFilterFactory.filter(
          $scope.filter,
          $scope.allConfigs
        );
        getPagingInfo($scope.filteredConfigSet);
        getCurrentCases();
      };
    }
  );
