angular.module('sniperWebApp')
	.controller('SymbolController', function ($timeout, $controller, $scope, $routeParams, symbolFactory, symbolDataHandlerFactory, symbolDataFilterFactory, config) {
    angular.extend(this, $controller('AdminController', {$scope: $scope}));
    var currentSymbols = [], allMetaSymbols = [], currentSymbolSet = [];
    $scope.constants = {
      // symbolType: ['SPLIT:All', 'SPLIT:Active', 'SPLIT:Expired', 'INDEX'],
    };
    $scope.currentSymbols = currentSymbols;
    var paging = $scope.paging = {
      currentPage: 1,
      totalPages: 0,
      totalSymbols: 0,
    };

    $scope.filter = {
      startDate: '',
      endDate: '',
      symbolType: $routeParams.tab === 'Splits' ? 'SPLIT' : 'INDEX',
      symbol: '',
      issueName: '',
      fundType: '',
      predefinedDateSelection: '{}',
    };
    $scope.filterConfig = {
      sortSymbol: true,
      sortIssueName: true,
      sortFundType: true,
      sortAnnouncedDate: false,
      sortRatio: true,
      sortEndDate: false,
      sortPayableDate: false
    };

    $scope.sort = function (key, reverse) {
      currentSymbolSet = symbolDataHandlerFactory.sort(currentSymbolSet, key, reverse);
      getCurrentSymbols();
    };

    $scope.$watch('filter.predefinedDateSelection', setDatesFromCurrentPredefindSelection);

    $scope.config = config;

    function getCurrentSymbols () {
      if(!currentSymbolSet.length) {
        $scope.currentSymbols = [];
      } else {
        var startIndex = (paging.currentPage - 1) * 100,
            endIndex = startIndex + 100;

        $scope.currentSymbols = symbolDataHandlerFactory.assignDefaultProp(currentSymbolSet.slice(startIndex, endIndex));
      }
    }

    $scope.submitFilter = function (isInit) {
      currentSymbolSet = symbolDataFilterFactory.filter($scope.filter, allMetaSymbols);
      if(isInit) {
        if($scope.currentTab === 'Splits') {
          currentSymbolSet = symbolDataHandlerFactory.sort(currentSymbolSet, 'announcedDate', true);
        } else if($scope.currentTab === 'Index') {
          currentSymbolSet = symbolDataHandlerFactory.sort(currentSymbolSet, 'symbol', false);
        }
        allMetaSymbols = currentSymbolSet;
      }
      getCurrentSymbols();
      getPagingInfo(currentSymbolSet);
    };

    $scope.$watch('paging.currentPage', getCurrentSymbols);

    function getPagingInfo(data) {
      paging.totalPages = Math.ceil(data.length/100);
      paging.totalSymbols = data.length;
      paging.currentPage = 1;
    }

    if(!symbolFactory.get().length) {
      $timeout(function () {
          symbolFactory.query().$promise.then(function (data) {
          symbolFactory.set(data);
          allMetaSymbols = symbolFactory.get();
          $scope.submitFilter(true);
        });
      });
    } else {
      allMetaSymbols = symbolFactory.get();
      $scope.submitFilter(true);
    }

    function setDatesFromCurrentPredefindSelection () {
      var dateRange = angular.fromJson($scope.filter.predefinedDateSelection);
      if (dateRange.from) {
        $scope.filter.startDate = dateRange.from < 0 ?
          moment().businessSubtract(Math.abs(dateRange.from)).toDate() :
          moment().businessAdd(Math.abs(dateRange.from)).toDate();
      }
      if (dateRange.to) {
        $scope.filter.endDate = dateRange.to < 0 ?
          moment().businessSubtract(Math.abs(dateRange.to)).toDate() :
          moment().businessAdd(Math.abs(dateRange.to)).toDate();
      }
    }

	});
