import { estFilter, noFractionCurrency } from "../tools/tk-tools";
import { commonColumns } from "./default-col";

export var spoofingColumns = commonColumns.concat([
  {
    name: "Symbol",
    value: "symbol",
  },
  {
    name: "Date",
    value: "spoofingAnalysis.date",
    filter: function (o) {
      return estFilter(o, "MM/DD/YYYY");
    },
  },
  {
    name: "Buy Account",
    value: "spoofingAnalysis.buy.order.accountNumberDecoded",
  },
  {
    name: "Buy IBD",
    value: "spoofingAnalysis.buy.order.ibdDecoded",
  },
  {
    name: "Buy Volume",
    value: "spoofingAnalysis.buy.executions.volume",
  },
  {
    name: "Buy Value",
    value: "spoofingAnalysis.buy.executions.dollars",
    filter: function (o) {
      return noFractionCurrency(o);
    },
  },
  {
    name: "Sell Account",
    value: "spoofingAnalysis.sell.order.accountNumberDecoded",
  },
  {
    name: "Sell IBD",
    value: "spoofingAnalysis.sell.order.ibdDecoded",
  },
  {
    name: "Sell Volume",
    value: "spoofingAnalysis.sell.executions.volume",
  },
  {
    name: "Sell Value",
    value: "spoofingAnalysis.sell.executions.dollars",
    filter: function (o) {
      return noFractionCurrency(o);
    },
  },
  {
    name: "E",
    value: "isException",
    filter: function (o) {
      return o ? "Y" : "N";
    },
  },
]);
