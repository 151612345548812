'use strict';

angular.module('sniperWebApp')
	.factory('caseFactory', function($resource, $log, config) {

		var Case = $resource('', {}, {
			query: {method: 'POST', url: config.restApiUrl + '/cases/intervalraw?from=:from&to=:to&pageSize=:pageSize&pageNo=:pageNo', isArray: true},
			get: {method: 'GET', url: config.restApiUrl + '/cases/:caseId?'},
			addHistory: {method: 'POST', url: config.restApiUrl + '/cases/hist/:caseId'},
			paging: {method: 'POST', url: config.restApiUrl + '/cases/paging/intervalraw?from=:from&to=:to&pageSize=:pageSize&pageNo=:pageNo'}
		});

		return {
			query: function(spec, criteria) {
				return Case.query(spec, criteria);
			},
			paging: function(spec, criteria) {
				return Case.paging(spec, criteria);
			},
			get: function(caseId) {
				return Case.get({caseId: caseId});
			},
			addHistory: function(caseId, history) {
				return Case.addHistory({caseId: caseId}, history);
			}
		};
	})
  .factory('newCaseFactory', function ($http, config) {
    function getTrades (params, data) {
      return $http({
        method: 'POST',
        url: config.restApiUrl + '/cases/intervalraw',
        params: params,
        data: data
      });
    }

    function addHistory (caseId, data) {
      return $http({
        method: 'POST',
        url: config.restApiUrl + '/cases/hist/' + caseId,
        data: data
      });
    }

    return {
      getTrades: getTrades,
      addHistory: addHistory
    };
  });
