angular
  .module("sniperWebApp")
  .factory("symbolFactory", function ($resource, config) {
    var _allSymbols = [];

    var meta = $resource(
      config.restApiUrl + "/metadata/symbols",
      {},
      {
        get: { method: "GET", isArray: true },
      }
    );

    function get() {
      return _allSymbols;
    }

    function set(allSymbols) {
      _allSymbols = allSymbols;
    }
    return {
      query: meta.get,
      get: get,
      set: set,
    };
  })
  .factory("symbolDataHandlerFactory", function ($filter) {
    var _data = [];

    function separateCategory() {}
    function assignDefaultProp(data) {
      data.map(function (symbol) {
        if (symbol.symbolType) {
          // return symbol;
        } else if (symbol.hasOwnProperty("stockSplit")) {
          symbol.symbolType = "SPLIT";
          // return symbol;
        }
        return symbol;
      });
      return data;
    }

    function set(data) {
      data = assignDefaultProp(data);
      _data = data;
    }

    function get() {
      return _data;
    }

    function sort(arr, key, reverse) {
      switch (key) {
        case "symbol":
          return $filter("orderBy")(arr, "symbol", reverse);
          break;
        case "issueName":
          return $filter("orderBy")(
            arr,
            function (d) {
              return d.attributes[0].value;
            },
            reverse
          );
          break;
        case "fundType":
          return $filter("orderBy")(
            arr,
            function (d) {
              return d.attributes[1].value;
            },
            reverse
          );
          break;
        case "announcedDate":
          return $filter("orderBy")(
            arr,
            function (d) {
              return d.stockSplit[0].announcedDate;
            },
            reverse
          );
          break;
        case "ratio":
          return $filter("orderBy")(
            arr,
            function (d) {
              return d.stockSplit[0].ratio;
            },
            reverse
          );
          break;
        case "endDate":
          return $filter("orderBy")(
            arr,
            function (d) {
              return d.stockSplit[0].exDate;
            },
            reverse
          );
          break;
        case "payableDate":
          return $filter("orderBy")(
            arr,
            function (d) {
              return d.stockSplit[0].payableDate;
            },
            reverse
          );
          break;
      }
      return arr;
    }

    return {
      set: set,
      get: get,
      sort: sort,
      separateCategory: separateCategory,
      assignDefaultProp: assignDefaultProp,
    };
  })
  .factory("symbolDataFilterFactory", function ($filter) {
    function getAllSplit(data) {
      return $filter("filter")(data, function (d) {
        return d.hasOwnProperty("stockSplit");
      });
    }

    function getActiveSplit(data) {
      return $filter("filter")(data, function (d) {
        return d.hasOwnProperty("stockSplit") && !d.stockSplit[0].exDate;
      });
    }

    function getExpiredSplit(data) {
      return $filter("filter")(data, function (d) {
        return d.hasOwnProperty("stockSplit") && d.stockSplit[0].exDate;
      });
    }

    function getIndex(data) {
      return $filter("filter")(data, function (d) {
        return d.symbolType == "INDEX" && d.symbol != "Symbol";
      });
    }

    function getMetaSymbol(data, symbol) {
      return $filter("filter")(data, { symbol: symbol });
    }

    /** Currently on for SPLIT **/
    function getStartDate(data, startDate) {
      return $filter("filter")(data, function (d) {
        if (d.hasOwnProperty("stockSplit") && d.stockSplit[0].announcedDate) {
          return moment(startDate).valueOf() <= d.stockSplit[0].announcedDate;
        }
        return false;
      });
    }

    /** Currently on for SPLIT **/
    function getEndDate(data, endDate) {
      return $filter("filter")(data, function (d) {
        if (d.stockSplit && d.stockSplit[0].announcedDate) {
          return moment(endDate).valueOf() >= d.stockSplit[0].exDate;
        }
        return false;
      });
    }

    function getMatchedInput(data, input) {
      var matchedRegExp = new RegExp(input, "i");
      return data.filter(function (d) {
        return d.attributes && d.attributes[0].value.match(matchedRegExp);
      });
    }

    function filter(filterConfig, data) {
      if (filterConfig.symbol) {
        data = getMetaSymbol(data, filterConfig.symbol);
      }

      if (filterConfig.symbolType) {
        switch (filterConfig.symbolType) {
          case "SPLIT:All":
            data = getAllSplit(data);
            break;
          case "SPLIT":
            data = getAllSplit(data);
            break;
          case "SPLIT:Active":
            data = getActiveSplit(data);
            break;
          case "SPLIT:Expired":
            data = getExpiredSplit(data);
            break;
          case "INDEX":
            data = getIndex(data);
            break;
        }
      }

      if (filterConfig.startDate) {
        data = getStartDate(data, filterConfig.startDate);
      }

      if (filterConfig.endDate) {
        data = getEndDate(data, filterConfig.endDate);
      }

      if (filterConfig.issueName) {
        data = getMatchedInput(data, filterConfig.issueName);
      }

      if (filterConfig.fundType) {
        data = getMatchedInput(data, filterConfig.fundType);
      }

      return data;
    }

    return {
      getAllSplit: getAllSplit,
      getActiveSplit: getActiveSplit,
      getExpiredSplit: getExpiredSplit,
      getIndex: getIndex,
      filter: filter,
    };
  });
