'use strict';

angular.module('sniperWebApp')
	.factory('anonymizerFactory', function($resource, $log, config) {

		var Anonymizer = $resource('', {}, {
			decode: {method: 'POST', url: config.restApiUrl + '/anonymizer/decode/?', isArray: true},
			encode: {method: 'GET', url: config.restApiUrl + '/anonymizer/encode/:accountNumber?'}
		});

		return {
			decode: function(spec) {
				return Anonymizer.decode(spec);
			},
			encode: function(accountNumber) {
				return Anonymizer.encode({accountNumber: accountNumber});
			}
		};
	});
