Highcharts.setOptions({
  lang: {
      thousandsSep: ','
  }
});
angular.module('sniperWebApp')
  .factory('chartConfig', function () {

    //enabling animation with 1.3.9 is messy, exceptions everywhere, so disable it
    var _defaultOptions = {
      chart: {animation: false, zoomType: null, pinchType: null, panning: false, width: 1, height: 1},
      rangeSelector: {enabled: false},
      navigator: {enabled: false},
      scrollbar: {enabled: false},
      legend: {enabled: false},
      credits: {enabled: false},
      exporting: {enabled: false},
      xAxis: {
        type: 'datetime',
        offset: 1,
        plotBands: [],
        dateTimeLabelFormats: {
          day: '%e %b'
        }
      },
      labels: {
        items: [
          {html: 'Symbol', style: {left: '30px', top: '4px', fontSize: '13px'}}
        ]
      }
    };

    var buyMarker = {
      enabled: true,
      fillColor: 'rgba(50, 200, 50, .90)',
      symbol: 'triangle'
    };
    var buyMarker1 = angular.extend(angular.copy(buyMarker), {
      fillColor: 'rgba(42, 158, 7, .90)',
      symbol: 'triangle'
    });
    var sellMarker = angular.extend(angular.copy(buyMarker), {
      fillColor: 'rgba(200, 50, 50, .90)',
      symbol: 'triangle-down'
    });
    var sellMarker1 = angular.extend(angular.copy(buyMarker), {
      fillColor: 'rgba(140, 5, 5, .90)',
      symbol: 'triangle-down'
    });

    var aheadOfNewsChartConfig = {
      options: angular.extend({
        yAxis: [
          {opposite: false, title: {text: 'Market', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, height: 120, lineWidth: 2, labels: {x: 3, align: 'left'}},
          {opposite: false, title: {text: 'Vol', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, top: 0, height: 30, offset: 0, lineWidth: 0, labels: {x: 3, align: 'left'}},
          {opposite: false, title: {text: 'Account', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, top: 120, height: 60, offset: 0, lineWidth: 2, labels: {x: 3, align: 'left'}},
          {opposite: false, title: {text: 'News', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, top: 190, height: 30, offset: 0, lineWidth: 2, labels: {x: 3, align: 'left'}}
        ]
      }, _defaultOptions),
      loading: false,
      useHighStocks: true,
      title: {text: ''},
      series: [
        {id: 'stock', name: ' ', type: 'candlestick', color: '#005902', yAxis: 0, panning: false, data: []},
        {id: 'stockLine', name: ' ', color: 'rgba(0, 0, 0, 0)', yAxis: 0, panning: false, data: [], enableMouseTracking: false},
        {id: 'volume', name: 'Volume', type: 'column', color: 'rgba(50, 0, 160, 0.6)', yAxis: 1, panning: false, data: []},
        {id: 'totalPosition', name: 'Position', type: 'areaspline', color: 'rgba(140, 140, 200, 0.90)', yAxis: 2, zIndex: -1, panning: false, data: []},
        {id: 'equityPosition', name: 'Equity', type: 'line', color: 'rgba(50, 50, 250, .90)', yAxis: 2, data: []},
        {id: 'optionsPosition', name: 'Options', type: 'line', color: 'rgba(40, 150, 250, .90)', yAxis: 2, panning: false, data: []},
        {id: 'customerTradeBuyMarkers', name: 'Buys', type: 'flags', shape: 'url(images/buy-arrow.png)', color: '#005902', fillColor: 'rgba(50, 255, 50, .75)', data: [], y: 10, panning: false, onSeries: 'stockLine', zIndex: 10},
        {id: 'customerTradeSellMarkers', name: 'Sells', type: 'flags', shape: 'url(images/sell-arrow.png)', color: '#005902', fillColor: 'rgba(255, 50, 50, .75)', data: [], y: -10, panning: false, onSeries: 'stockLine', zIndex: 10},
        {id: 'news', name: 'News', type: 'flags', color: '#005902', yAxis: 0, panning: false, data: []}
      ],
    };

    var plChartConfig = {
      options: _.merge({
        yAxis: [
          {opposite: false, title: {text: 'Market', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, height: 120, lineWidth: 2, labels: {x: 3, align: 'left'}},
          {opposite: false, title: {text: 'Vol', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, top: 0, height: 30, offset: 0, lineWidth: 0, labels: {x: 3, align: 'left'}},
          {opposite: false, title: {text: 'Account', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, top: 120, height: 60, offset: 0, lineWidth: 2, labels: {x: 3, align: 'left'}},
          {opposite: false, title: {text: 'News', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, top: 190, height: 30, offset: 0, lineWidth: 2, labels: {x: 3, align: 'left'}}
        ]
      }, _defaultOptions, {
        xAxis: {
          offset: 200
        }
      }),
      loading: false,
      useHighStocks: true,
      title: {text: ''},
      series: [
        {id: 'stock', name: ' ', type: 'candlestick', color: '#005902', yAxis: 0, panning: false, data: []},
        {id: 'stockLine', name: ' ', color: 'rgba(0, 0, 0, 0)', yAxis: 0, panning: false, data: [], enableMouseTracking: false},
        {id: 'volume', name: 'Volume', type: 'column', color: 'rgba(50, 0, 160, 0.6)', yAxis: 1, panning: false, data: []},
        {id: 'totalPosition', name: 'Position', type: 'areaspline', color: 'rgba(140, 140, 200, 0.90)', yAxis: 2, zIndex: -1, panning: false, data: []},
        {id: 'equityPosition', name: 'Equity', type: 'line', color: 'rgba(50, 50, 250, .90)', yAxis: 2, data: []},
        {id: 'optionsPosition', name: 'Options', type: 'line', color: 'rgba(40, 150, 250, .90)', yAxis: 2, panning: false, data: []},
        {id: 'customerTradeBuyMarkers', name: 'Buys', type: 'flags', shape: 'url(images/buy-arrow.png)', color: '#005902', fillColor: 'rgba(50, 255, 50, .75)', data: [], y: 10, panning: false, onSeries: 'stockLine', zIndex: 10},
        {id: 'customerTradeSellMarkers', name: 'Sells', type: 'flags', shape: 'url(images/sell-arrow.png)', color: '#005902', fillColor: 'rgba(255, 50, 50, .75)', data: [], y: -10, panning: false, onSeries: 'stockLine', zIndex: 10},
        {id: 'news', name: 'News', type: 'flags', color: '#005902', yAxis: 0, panning: false, data: []}
      ],
    };

    var markingTheCloseChartConfig = {
      options: angular.extend({
        yAxis: [
          {opposite: false, title: {text: 'Market', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, height: 140, lineWidth: 2, labels: {x: 3, align: 'left'}},
          {opposite: false, title: {text: 'Vol', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, top: 0, height: 30, offset: 0, lineWidth: 0, labels: {x: 3, align: 'left'}},
          {opposite: false, title: {text: 'Account', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, top: 160, height: 80, offset: 0, lineWidth: 2, labels: {x: 3, align: 'left'}}
        ]
      }, _defaultOptions),
      loading: false,
      useHighStocks: true,
      title: {text: ''},
      series: [
        {id: 'stock', name: ' ', type: 'candlestick', color: '#005902', yAxis: 0, panning: false, data: []},
        {id: 'stockLine', name: ' ', color: 'rgba(0, 0, 0, 0)', yAxis: 0, panning: false, data: [], enableMouseTracking: false},
        {id: 'volume', name: 'Volume', type: 'column', color: 'rgba(50, 0, 160, 0.6)', yAxis: 1, panning: false, data: []},
        {id: 'totalPosition', name: 'Position', type: 'areaspline', color: 'rgba(140, 140, 200, 0.90)', yAxis: 2, zIndex: -1, panning: false, data: []},
        {id: 'equityPosition', name: 'Equity', type: 'line', color: 'rgba(50, 50, 250, .90)', yAxis: 2, data: []},
        {id: 'optionsPosition', name: 'Options', type: 'line', color: 'rgba(40, 150, 250, .90)', yAxis: 2, panning: false, data: []},
        {id: 'customerTradeBuyMarkers', name: 'Buys', type: 'flags', shape: 'url(images/buy-arrow.png)', color: '#005902', fillColor: 'rgba(50, 255, 50, .75)', data: [], y: 10, panning: false, onSeries: 'stockLine', zIndex: 10},
        {id: 'customerTradeSellMarkers', name: 'Sells', type: 'flags', shape: 'url(images/sell-arrow.png)', color: '#005902', fillColor: 'rgba(255, 50, 50, .75)', data: [], y: -10, panning: false, onSeries: 'stockLine', zIndex: 10},
        {id: 'customerBuyVolume', name: 'Buy Volume', type: 'column', color: 'rgba(0, 200, 0, .90)', yAxis: 2, panning: false, data: []},
        {id: 'customerSellVolume', name: 'Sell Volume', type: 'column', color: 'rgba(200, 0, 0, .90)', yAxis: 2, panning: false, data: []}
      ],
    };

    var washSaleHeaderFormat = '<span style="font-size: 10px">{point.key}</span><br/>';
    var washSaleTooltipFormat = 'Price: <b>{point.y}</b><br/>' + 'Volume: <b>{point.volume:,.0f}</b>'
    var washSaleTooltip = {
      headerFormat: washSaleHeaderFormat,
      pointFormat: washSaleTooltipFormat,
      valuePrefix: '$',
      valueDecimals: 2,
    };
    var washSaleChartConfig = {
      options: angular.extend({
        yAxis: [
          {opposite: false, title: {text: 'Market', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, height: 90, lineWidth: 2, labels: {x: 3, align: 'left'}, plotBands: []},
          {opposite: false, title: {text: 'Vol', align: 'middle', style: {color: '#4d759e', 'font-weight': 'bold'}}, top: 0, height: 40, offset: 0, lineWidth: 0, labels: {x: 3, align: 'left'}}
        ]
      }, _defaultOptions),
      loading: false,
      useHighStocks: true,
      title: {text: ''},
      series: [
        {id: 'stock', name: '', type: 'candlestick', color: '#005902', yAxis: 0, panning: false, data: []},
        {id: 'stockLine', name: '', color: 'rgba(0, 0, 0, 0)', yAxis: 0, panning: false, data: [], enableMouseTracking: false},
        {id: 'volume', name: 'Volume', type: 'column', color: 'rgba(50, 0, 160, 0.6)', yAxis: 1, panning: false, data: []},
        {id: 'customerTradeBuyMarkers', name: 'Buys', type: 'scatter', color: 'rgba(50, 200, 50, .90)', marker: buyMarker, tooltip: washSaleTooltip, data: [], zIndex: 10},
        {id: 'customerTradeSellMarkers', name: 'Sells', type: 'scatter', color: 'rgba(200, 50, 50, .90)', marker: sellMarker, tooltip: washSaleTooltip, data: [], zIndex: 10},
        {id: 'customerTradeBuyMarkers1', name: 'Buys', type: 'scatter', color: 'rgba(42, 158, 7, .90)', marker: buyMarker1, tooltip: washSaleTooltip, data: [], zIndex: 10},
        {id: 'customerTradeSellMarkers1', name: 'Sells', type: 'scatter', color: 'rgba(140, 5, 5, .90)', marker: sellMarker1, tooltip: washSaleTooltip, data: [], zIndex: 10}
      ],
    };

    var spoofingNbboHeaderFormat = '<span style="font-size: 10px">{point.key}</span><br/>';
    var spoofingNbboTooltipFormat = '{series.name}: <b>{point.y}</b><br/>';
    var spoofingTradeTooltipFormat = 'Price: <b>{point.y}</b><br/>' + 'Volume: <b>{point.volume:,.0f}</b><br/>' + 'NBBO Bid: <b>${point.nbboBid:,.2f}</b><br/>' + 'NBBO Offer: <b>${point.nbboOffer:,.2f}</b><br/>'
    var spoofingChartConfig = angular.copy(washSaleChartConfig);
    _.extend(spoofingChartConfig.options, {
      chart: _.assign(spoofingChartConfig.options.chart, {
        zoomType: 'x'
      }),
      xAxis: angular.extend(spoofingChartConfig.options.xAxis, {
        minRange: 30 * 60,
      }),
    });
    // spoofingChartConfig.series = spoofingChartConfig.series.slice(0, 5);
    spoofingChartConfig.series.splice(5, 0,
      {
        id: 'nbboBid',
        name: 'NBBO Bid',
        type: 'scatter',
        color: 'rgba(0, 29, 255, .90)',
        marker: _.assign(angular.copy(buyMarker1), {
          symbol: 'diamond',
          fillColor: 'rgba(0, 29, 255, .90)',
          radius: 6
        }),
        tooltip: {
          headerFormat: spoofingNbboHeaderFormat,
          pointFormat: spoofingNbboTooltipFormat,
          valuePrefix: '$',
          valueDecimals: 3,
        },
        zIndex: 9,
        data: [],    //7
      },
      {
        id: 'nbboOffer',
        name: 'NBBO Offer',
        type: 'scatter',
        color: 'rgba(255, 140, 0, .90)',
        marker: _.assign(angular.copy(sellMarker1), {
          symbol: 'diamond',
          fillColor: 'rgba(255, 140, 0, .90)',
          radius: 6
        }),
        tooltip: {
          headerFormat: spoofingNbboHeaderFormat,
          pointFormat: spoofingNbboTooltipFormat,
          valuePrefix: '$',
          valueDecimals: 3,
        },
        zIndex: 9,
        data: [] //8
      }
    );
    spoofingChartConfig.series[3].tooltip.pointFormat = spoofingTradeTooltipFormat;
    spoofingChartConfig.series[4].tooltip.pointFormat = spoofingTradeTooltipFormat;
    spoofingChartConfig.series[7].tooltip.pointFormat = spoofingTradeTooltipFormat;
    spoofingChartConfig.series[8].tooltip.pointFormat = spoofingTradeTooltipFormat;
    // spoofingChartConfig.series.push.apply(spoofingChartConfig.series, [
    //   {
    //     id: 'nbboBid',
    //     name: 'NBBO Bid',
    //     type: 'scatter',
    //     color: 'rgba(0, 29, 255, .90)',
    //     marker: _.assign(angular.copy(buyMarker1), {
    //       symbol: 'diamond',
    //       fillColor: 'rgba(0, 29, 255, .90)',
    //       radius: 6
    //     }),
    //     tooltip: {
    //       headerFormat: spoofingNbboHeaderFormat,
    //       pointFormat: spoofingNbboTooltipFormat,
    //       valuePrefix: '$',
    //       valueDecimals: 3,
    //     },
    //     zIndex: 9,
    //     data: [],    //7
    //   },
    //   {
    //     id: 'nbboOffer',
    //     name: 'NBBO Offer',
    //     type: 'scatter',
    //     color: 'rgba(255, 140, 0, .90)',
    //     marker: _.assign(angular.copy(sellMarker1), {
    //       symbol: 'diamond',
    //       fillColor: 'rgba(255, 140, 0, .90)',
    //       radius: 6
    //     }),
    //     tooltip: {
    //       headerFormat: spoofingNbboHeaderFormat,
    //       pointFormat: spoofingNbboTooltipFormat,
    //       valuePrefix: '$',
    //       valueDecimals: 3,
    //     },
    //     zIndex: 9,
    //     data: [] //8
    //   },
    // ]);

    var ruleChartConfig = angular.extend(angular.copy(washSaleChartConfig), {
      series: [
        {id: 'stock', name: '', type: 'candlestick', color: '#005902', yAxis: 0, panning: false, data: []},
        {id: 'stockLine', name: '', color: 'rgba(0, 0, 0, 0)', yAxis: 0, panning: false, data: [], enableMouseTracking: false},
        {id: 'volume', name: 'Volume', type: 'column', color: 'rgba(50, 0, 160, 0.6)', yAxis: 1, panning: false, data: []},
        {id: 'customerTradeBuyMarkers', name: 'Buys', type: 'flags', shape: 'url(images/buy-arrow.png)', color: '#005902', fillColor: 'rgba(50, 255, 50, .75)', data: [], y: 15, panning: false, onSeries: 'stockLine', zIndex: 10},
        {id: 'customerTradeSellMarkers', name: 'Sells', type: 'flags', shape: 'url(images/sell-arrow.png)', color: '#005902', fillColor: 'rgba(255, 50, 50, .75)', data: [], y: -15, panning: false, onSeries: 'stockLine', zIndex: 10},
      ],
    });

    var mcapChartConfig = angular.extend(angular.copy(ruleChartConfig))

    return {
      getChart: function (type) {
        switch (type) {
          case 'rule':
            return angular.copy(ruleChartConfig);
            break;
          case 'washSale':
            return angular.copy(washSaleChartConfig);
            break;
          case 'aheadOfNews':
            return angular.copy(aheadOfNewsChartConfig);
            break;
          case 'pl':
            return angular.copy(plChartConfig);
            break;
          case 'markingTheClose':
            return angular.copy(markingTheCloseChartConfig);
            break;
          case 'spoofing':
            return angular.copy(spoofingChartConfig);
            break;
          case 'mcap':
            return angular.copy(mcapChartConfig);
            break;
        }
      }
    };
  });
