import { estFilter, multiplyForOption } from "../tools/tk-tools";
import { commonColumns } from "./default-col";

export var washsaleColumns = commonColumns.concat([
  {
    name: "Symbol",
    value: "symbol",
  },
  {
    name: "Score",
    value: "scoreCollection.roundedTotal",
    filter: function (o) {
      return o + "%";
    },
  },
  {
    name: "Date",
    value: "washSaleAnalysis.date",
    filter: function (o) {
      return estFilter(o, "MM/DD/YYYY");
    },
  },
  {
    name: "ADV",
    value: "washSaleAnalysis.buy.adv",
    decimal: 0,
  },
  {
    name: "Buy Account",
    value: "washSaleAnalysis.buy.order.accountNumberDecoded",
  },
  {
    name: "Buy IBD",
    value: "washSaleAnalysis.buy.order.ibdDecoded",
  },
  {
    name: "Buy Vol",
    value: "washSaleAnalysis.buy.executions.volume",
  },
  {
    name: "Buy Value",
    value: "washSaleAnalysis.buy.executions.dollars",
    filter: function (o, rowData) {
      return multiplyForOption(
        o,
        _.get(rowData, "washSaleAnalysis.buy.order.product")
      );
    },
  },
  {
    name: "Sell Account",
    value: "washSaleAnalysis.sell.order.accountNumberDecoded",
  },
  {
    name: "Sell IBD",
    value: "washSaleAnalysis.sell.order.ibdDecoded",
  },
  {
    name: "Sell Vol",
    value: "washSaleAnalysis.sell.executions.volume",
  },
  {
    name: "Sell Value",
    value: "washSaleAnalysis.sell.executions.dollars",
    filter: function (o, rowData) {
      return multiplyForOption(
        o,
        _.get(rowData, "washSaleAnalysis.sell.order.product")
      );
    },
  },
  {
    name: "E",
    value: "isException",
    filter: function (o) {
      return o ? "Y" : "N";
    },
  },
]);
