import { aheadOfNewsCol } from "../columns/ahead-of-news-col";
import { defaultColumns } from "../columns/default-col";
import { marketCapColumns } from "../columns/market-cap-col";
import { markingTheCloseColumns } from "../columns/marking-the-close-col";
import { plColumns } from "../columns/pl-col";
import { regmColumns, ruleColumns } from "../columns/rule-col";
import { spoofingColumns } from "../columns/spoofing-col";
import { washsaleColumns } from "../columns/wash-sale-col";
import { exportToCsv } from "../services/helper-jsonToCsv";
import { estFilter, toFixed } from "../tools/tk-tools";
import { Case } from "./Case";
import { screenshot } from './../services/screenshot-helper'
import { getDateTo } from '../tools/getDateTo';

angular
  .module("sniperWebApp")
  .controller(
    "CaseController",
    function (
      $scope,
      criteria,
      caseFactory,
      marketDataFactory,
      ruleAnalysisHelperFactory,
      chartConfig,
      newsFactory,
      customerDataFactory,
      anonymizerFactory,
      config,
      TradingDataSummary,
      SpoofingMarketData,
      $modal,
      $timeout,
      resizer,
      $filter,
      $q,
      $location,
      // mcapService
    ) {
      window.$q = $q;
      // mcapService.checkExistence();

      var parentNav = $scope.nav;
      $scope.nav = function (path, replace) {
        if (path === "/market") {
          $location.path(path).search({
            selectedSymbol: $scope.caseDetail ? $scope.caseDetail.symbol : null,
            filteredSymbol: $scope.caseFilter ? $scope.caseFilter.symbol : null,
          });
        } else {
          parentNav(path, replace);
        }
      };

      var locationSearch = angular.copy($location.search());
      // $location.search({}).replace();
      var minutes = 15;
      $scope.config = config;
      $scope.caseControllerScope = $scope;
      $scope.clamp = function (val, min, max) {
        return Math.min(max, Math.max(min, val));
      };
      $scope.getTodayTS = function () {
        return new Date().valueOf();
      };
      // Highcharts.setOptions({
      //   global: {
      //     timezoneOffset: 4 * 60 //EST
      //   }
      // });
      // Highcharts.setOptions({
      //   getTimezoneOffset: function (timestamp) {
      //     console.log(timestamp);
      //     var zone = 'America/New_York',
      //         timezoneOffset = -moment.tz(timestamp, zone).utcOffset();

      //     return timezoneOffset;
      //   },
      //   lang: {
      //     thousandsSep: ','
      //   }
      // });
      $scope.screenshot = screenshot
      $scope.casePageSize = 100;
      $scope.casePaging = null;
      $scope.caseCurrentPage = 0;
      $scope.tradeSummaryDataPageSize = 50;
      $scope.customerTradeSummaryDataPaging = null;
      $scope.customerTradeSummaryDataCurrentPage = 0;
      $scope.customerTradeDataPageSize = 50;
      $scope.customerTradeDataPaging = null;
      $scope.customerTradeDataCurrentPage = 0;
      $scope.caseListPanelMaximized = false;
      $scope.lastCaseListPanelY = 0;
      $scope.showChart = true;

      $scope.aheadOfNewsChartConfig = chartConfig.getChart("aheadOfNews");
      $scope.markingTheCloseChartConfig =
        chartConfig.getChart("markingTheClose");
      $scope.washSaleChartConfig = chartConfig.getChart("washSale");
      $scope.ruleChartConfig = chartConfig.getChart("rule");
      $scope.spoofingChartConfig = chartConfig.getChart("spoofing");
      $scope.mcapChartConfig = chartConfig.getChart("mcap");
      $scope.plChartConfig = chartConfig.getChart("pl");
      $scope.spoofingChartConfig.options.xAxis.events =
        $scope.spoofingChartConfig.options.yAxis.events = {
          setExtremes: function (e) {
            $scope.caseDetail.spoofingGraphData.zoom(e.min, e.max);
            var withinThreshold =
              $scope.caseDetail.spoofingGraphMarketData.zoom(e.min, e.max);
            triggerSpoofingDataSize(withinThreshold);
            // var that = this;
            // $timeout(function () {
            //   that.chart.redraw();
            // }, 500);
          },
        };

      $scope.maximinizeCaseListPanel = function () {
        resizer.toggleMaxVertical("#horizontal-resizer", 24);
        $scope.caseListPanelMaximized = !$scope.caseListPanelMaximized;
      };
      $scope.reshapeCaseListPanel = function () {
        var v = angular.element("#caseListPanel");
        angular
          .element(".caseGrid .scrollableContainer")
          .css("height", v.height() - 40);
        $(".caseGrid.scrollable-table tbody").css("height", v.height() - 60);
      };

      $scope.caseSupervisionPanelMaximized = false;
      $scope.maximinizeSupervisionPanel = function () {
        resizer.toggleMaxVertical(
          "#horizontal-resizer",
          window.innerHeight - 65
        );
        resizer.toggleMaxHorizontal("#vertical-resizer", window.innerWidth);
        $scope.caseSupervisionPanelMaximized =
          !$scope.caseSupervisionPanelMaximized;
      };

      $scope.reshapeMarketPanel = function () {
        var panel = angular.element("#marketDataPanel");
        var chartHeight = panel.height() / 2 - 10;
        var chartWidth = panel.width() - 20;

        if (!$scope.showChart) {
          chartHeight = 0;
        }

        if (!$scope.caseDetail) {
          return;
        }
        if ($scope.caseDetail.aheadOfNewsAnalysis) {
          $scope.aheadOfNewsChartConfig.options.chart.height = chartHeight;
          $scope.aheadOfNewsChartConfig.options.chart.width = chartWidth;
          $scope.aheadOfNewsChartConfig.options.yAxis[0].height =
            chartHeight - 175;
          $scope.aheadOfNewsChartConfig.options.yAxis[1].top =
            $scope.aheadOfNewsChartConfig.options.yAxis[0].height + 15;
          $scope.aheadOfNewsChartConfig.options.yAxis[2].top =
            $scope.aheadOfNewsChartConfig.options.yAxis[0].height + 50;
          $scope.aheadOfNewsChartConfig.options.yAxis[3].top =
            $scope.aheadOfNewsChartConfig.options.yAxis[0].height + 115;
        } else if ($scope.caseDetail.markingTheCloseAnalysis) {
          $scope.markingTheCloseChartConfig.options.chart.height = chartHeight;
          $scope.markingTheCloseChartConfig.options.chart.width = chartWidth;
          $scope.markingTheCloseChartConfig.options.yAxis[0].height =
            chartHeight - 180;
          $scope.markingTheCloseChartConfig.options.yAxis[1].top =
            $scope.markingTheCloseChartConfig.options.yAxis[0].height + 15;
          $scope.markingTheCloseChartConfig.options.yAxis[2].top =
            $scope.markingTheCloseChartConfig.options.yAxis[0].height + 50;
        } else if ($scope.caseDetail.washSaleAnalysis) {
          $scope.washSaleChartConfig.options.chart.height = chartHeight;
          $scope.washSaleChartConfig.options.chart.width = chartWidth;
          $scope.washSaleChartConfig.options.yAxis[0].height =
            chartHeight - 100;
          $scope.washSaleChartConfig.options.yAxis[1].top =
            $scope.washSaleChartConfig.options.yAxis[0].height + 15;
        } else if ($scope.caseDetail.rulesAnalysis) {
          $scope.ruleChartConfig.options.chart.height = chartHeight;
          $scope.ruleChartConfig.options.chart.width = chartWidth;
          $scope.ruleChartConfig.options.yAxis[0].height = chartHeight - 100;
          $scope.ruleChartConfig.options.yAxis[1].top =
            $scope.ruleChartConfig.options.yAxis[0].height + 15;
        } else if ($scope.caseDetail.spoofingAnalysis) {
          $scope.spoofingChartConfig.options.chart.height = chartHeight;
          $scope.spoofingChartConfig.options.chart.width = chartWidth;
          $scope.spoofingChartConfig.options.yAxis[0].height =
            chartHeight - 100;
          $scope.spoofingChartConfig.options.yAxis[1].top =
            $scope.spoofingChartConfig.options.yAxis[0].height + 15;
        } else if ($scope.caseDetail.mcapAnalysis) {
          $scope.mcapChartConfig.options.chart.height = chartHeight;
          $scope.mcapChartConfig.options.chart.width = chartWidth;
          $scope.mcapChartConfig.options.yAxis[0].height = chartHeight - 100;
          $scope.mcapChartConfig.options.yAxis[1].top =
            $scope.spoofingChartConfig.options.yAxis[0].height + 15;
        } else if ($scope.caseDetail.plAnalysis) {
          $scope.plChartConfig.options.chart.height = chartHeight;
          $scope.plChartConfig.options.chart.width = chartWidth;
          $scope.plChartConfig.options.yAxis[0].height = chartHeight - 100;
          $scope.plChartConfig.options.yAxis[1].top =
            $scope.spoofingChartConfig.options.yAxis[0].height + 15;
        }

        var pad = 80;

        $timeout(function () {
          $("#customerTradeDataTabset .scrollableContainer").css(
            "height",
            panel.height() - chartHeight - pad
          );
        }, 10);
      };

      $scope.marketDataPanelMaximized = false;
      $scope.maximinizeMarketDataPanel = function () {
        resizer.toggleMaxVertical(
          "#horizontal-resizer",
          window.innerHeight - 65
        );
        resizer.toggleMaxHorizontal("#vertical-resizer", 5);
        $scope.marketDataPanelMaximized = !$scope.marketDataPanelMaximized;
      };
      //reshape the panels as the window is loaded
      $timeout(function () {
        $scope.reshapeCaseListPanel();
        $scope.reshapeMarketPanel();
      }, 100);

      $scope.$on("httpProviderMessages", function (evt, msg) {
        $scope.addAlert(msg, 5000);
      });

      // $scope.predefinedDateSelection = config.predefinedFilterDates[2].id;
      $scope.caseFilter = {
        status: config.caseStatusFilterChoices[0].id,
        exceptionsOnly: true,
        mustHaveOptions: false,
        symbol: locationSearch ? locationSearch.filteredSymbol : null,
      };

      $scope.caseFilterConfig = {
        caseType: "all",
        // caseType: 'plAnalysis',
        // caseTypeName: 'P&L',
        predefinedDateSelection: config.predefinedFilterDates[2].id,
        predefinedSelectionName: config.predefinedFilterDates[2].name,
      };

      var localCaseFilter = localStorage.getItem("caseFilter");
      if (localCaseFilter) {
        // var localCaseFilterConfig = localStorage.getItem('caseFilterConfig');
        _.extend($scope.caseFilter, JSON.parse(localCaseFilter));
        // _.extend($scope.caseFilterConfig, JSON.parse(localCaseFilterConfig));
      }

      $scope.resetCaseFilter = function () {
        setDatesFromCurrentPredefindSelection();
      };

      $scope.resetCaseFilter();

      $scope.resetSorting = function () {
        //      'analysisDtime': -1
        //      'symbol': 1
        //      'aheadOfNewsAnalysis.marketEvent.dtime': -1
        //      'aheadOfNewsAnalysis.symbol': -1,
        //      'aheadOfNewsAnalysis.accountNumber': 1

        $scope.caseSort = {
          analysisDtime: -1,
          symbol: 1,
        };
        $scope.caseSortConfig = {
          selected: "",
        };
      };

      $scope.applyCaseFilter = function () {
        $scope.casePaging = null;
        runCaseFilter();
      };

      $scope.$watch("caseFilterConfig.predefinedDateSelection", function () {
        setDatesFromCurrentPredefindSelection();
        $scope.resetCaseFilter();
        $scope.resetSorting();
        $scope.casePaging = null;
        runCaseFilter();
      });

      $scope.$watch("caseFilter.status", function () {
        $scope.resetCaseFilter();
        $scope.resetSorting();
        $scope.casePaging = null;
        runCaseFilter();
      });

      function setDatesFromCurrentPredefindSelection() {
        var dateRange = angular.fromJson(
          $scope.caseFilterConfig.predefinedDateSelection
        );
        if (dateRange.from) {
          $scope.caseFilter.from =
            dateRange.from < 0
              ? moment().businessSubtract(Math.abs(dateRange.from)).toDate()
              : moment().businessAdd(Math.abs(dateRange.from)).toDate();
        }
        if (dateRange.to) {
          $scope.caseFilter.to =
            dateRange.to < 0
              ? moment().businessSubtract(Math.abs(dateRange.to)).toDate()
              : moment().businessAdd(Math.abs(dateRange.to)).toDate();
        }

        // @Remove
        // $scope.caseFilter.from = moment('06/20/2018', 'MM/DD/YYYY').startOf('day').toDate()
        // $scope.caseFilter.to = moment('06/22/2018', 'MM/DD/YYYY').endOf('day').toDate()
      }

      $scope.$watch("caseFilterConfig.caseTypeName", function () {
        $scope.resetCaseFilter();
        $scope.resetSorting();
        $scope.casePaging = null;
        $scope.isReversed = false;
        previousMatchedCaseSortKey = null;
        $scope.matchedCaseSortKey = null;
        runCaseFilter();
      });
      $scope.showCaseFiltersDialog = function () {
        var modalInstance = $modal.open({
          templateUrl: "views/modal-caseFilters.html",
          windowClass: "gold-frame",
          resolve: {
            caseFilter: function () {
              return angular.copy($scope.caseFilter);
            },
            config: function () {
              return $scope.config;
            },
            caseFilterConfig: function () {
              return $scope.caseFilterConfig;
            },
          },
          controller: function (
            $scope,
            config,
            $modalInstance,
            caseFilter,
            caseFilterConfig
          ) {
            $scope.caseFilter = caseFilter;
            caseFilter.internationalType = caseFilter.internationalType || "";
            caseFilter.wsNoteComparator = caseFilter.wsNoteComparator || "=";
            caseFilter.minEventPriceChangePercSymbol =
              caseFilter.minEventPriceChangePercSymbol || "$gte";
            $scope.config = config;
            $scope.caseFilterConfig = caseFilterConfig;
            $scope.ok = function () {
              $modalInstance.close(caseFilter);
            };
            $scope.cancel = function () {
              $modalInstance.dismiss();
            };
            $scope.reset = function () {
              caseFilter.branch = undefined;
              caseFilter.account = "";
              caseFilter.comment = "";
              caseFilter.ibd = undefined;
              caseFilter.symbol = undefined;
              caseFilter.symbol = undefined;
              caseFilter.exceptionsOnly = true;
              caseFilter.internationalType = "";
              caseFilter.status = config.caseStatusFilterChoices[0].id;
              caseFilterConfig.statusName = undefined;
              caseFilter.rank = undefined;
              caseFilterConfig.rankName = undefined;
              caseFilter.action = undefined;
              caseFilterConfig.actionName = undefined;
              caseFilter.reason = undefined;
              caseFilterConfig.reasonName = undefined;
              caseFilter.minTotalPnl = undefined;
              caseFilter.minEventPnl = undefined;
              caseFilter.eventPosition = undefined;
              caseFilter.minEventPriceChangePerc = undefined;
              caseFilter.minEventPriceChangePercSymbol = "$gte";
              caseFilter.mustHaveOptions = false;
              caseFilter.minMarketVolume = undefined;
              caseFilter.minCustomerVolume = undefined;
              caseFilter.minCustomerValue = undefined;
              caseFilter.minDayCustomerPercentageVolume = undefined;
              caseFilter.minCustomerPercentageVolume = undefined;
              caseFilter.minMarketPriceChangePerc = undefined;
              caseFilter.mktScore = undefined;
              caseFilter.minBuyExecutionsVolume = undefined;
              caseFilter.minBuyExecutionsValue = undefined;
              caseFilter.minPercentDayVolume = undefined;
              caseFilter.wsScore = undefined;
              caseFilter.wsSource = undefined;
              caseFilter.wsOptionOnly = false;
              caseFilter.ruleName = undefined;
              caseFilter.ruleVolume = undefined;
              caseFilter.ruleValue = undefined;
              caseFilter.ruleSource = undefined;
              caseFilter.wsNotes = undefined;
              caseFilter.wsNoteComparator = "=";
              caseFilter.spoofingSource = undefined;
              $modalInstance.close(caseFilter);
            };
            $scope.saveAsDefault = function () {
              var clonedCaseFilter = angular.copy(caseFilter);
              clonedCaseFilter.from =
                clonedCaseFilter.to =
                clonedCaseFilter.symbol =
                clonedCaseFilter.account =
                caseFilter.ibd =
                caseFilter.status =
                undefined;
              // var clonedCaseFilterConfig = angular.copy(caseFilterConfig);
              // clonedCaseFilterConfig.predefinedSelectionName = clonedCaseFilterConfig.predefinedDateSelection = undefined;
              localStorage.setItem(
                "caseFilter",
                JSON.stringify(clonedCaseFilter)
              );
              // localStorage.setItem('caseFilterConfig', JSON.stringify(clonedCaseFilterConfig));
              $modalInstance.close(caseFilter);
            };
            $scope.resetDefault = function () {
              localStorage.removeItem("caseFilter");
              // localStorage.removeItem('caseFilterConfig');
            };
          },
        });
        modalInstance.result.then(
          function (caseFilter) {
            //          $scope.resetSorting();
            _.extend($scope.caseFilter, caseFilter);
            $scope.applyCaseFilter();
          },
          function () {
            //canceled
          }
        );
      };
      $scope.selectedCase = null;
      $scope.selectCase = function (v) {
        $scope.clearAllCases();
        $scope.selectedCase = angular.copy(v);
      };
      $scope.$watch("selectedCase.mid", function (v) {
        if (!v) {
          return;
        }
        if ($scope.caseListPanelMaximized) {
          $scope.maximinizeCaseListPanel();
        }
        loadCaseDetail($scope.selectedCase.mid);
      });
      $scope.newCaseHistory = {};
      $scope.newHistoryConfig = {};

      $scope.addCaseHistory = function () {
        var newCaseHistory = $scope.newCaseHistory;
        if (
          !newCaseHistory.rank ||
          !newCaseHistory.action ||
          !newCaseHistory.reason ||
          !newCaseHistory.status
        ) {
          newCaseHistory.error = true;
          return;
        }
        if ($scope.multipleCasesSelected) {
          //Handing in case-edit-selection.js
          $scope.submitActionForMultiCases(newCaseHistory);
          return;
        }
        delete newCaseHistory.error;
        caseFactory
          .addHistory($scope.caseDetail.mid, newCaseHistory)
          .$promise.then(function () {
            caseFactory.get($scope.caseDetail.mid).$promise.then(function (c) {
              $scope.caseDetail.history = c.history;
              //find and update the case in the grid
              var gc = $scope.cases.filter(function (e) {
                return e.mid === $scope.caseDetail.mid;
              });
              if (gc && gc.length > 0) {
                gc[0].history = c.history;
              }

              (function clearForm() {
                $scope.newCaseHistory = {};
                $scope.newHistoryConfig = {};
              })();
            });
          });
        if (newCaseHistory.status === "PENDING") {
          newCaseHistory.status = "ONGOING";
        }
      };

      $scope.caseSort = {};
      $scope.toggleSortColumn = function (c, d) {
        d = d ? d : 1;
        var t = $scope.caseSort[c] ? $scope.caseSort[c] * -1 : d;
        $scope.caseSort = {};
        $scope.caseSort[c] = t;
      };
      var runCaseFilter = function () {
        $scope.closeMultiCasesSelection();
        $scope.closeMatchCasesDetail();
        if (!$scope.caseSort.analysisDtime) {
          $scope.caseSort.analysisDtime = -1;
        }
        if (!$scope.caseSort.symbol) {
          $scope.caseSort.symbol = 1;
        }
        var sortCriteria = $scope.caseSort,
          findCriteria = { $and: [] },
          caseFilter = $scope.caseFilter,
          caseFilterConfig = $scope.caseFilterConfig;
        var runIt = function () {
          if (findCriteria.$and.length === 0) {
            delete findCriteria.$and;
          }
          if ($scope.casePaging === null) {
            $scope.caseCurrentPage = 0;
            caseFactory
              .paging(
                {
                  pageSize: $scope.casePageSize,
                  from: moment(caseFilter.from).format("YYYYMMDD"),
                  to: moment(caseFilter.to).format("YYYYMMDD"),
                },
                {
                  find: JSON.stringify(findCriteria),
                  sort: JSON.stringify(sortCriteria),
                }
              )
              .$promise.then(function (data) {
                $scope.casePaging = {
                  totalRecords: data.totalRecords,
                  totalPages: data.totalPages,
                };
              });
          }
          caseFactory
            .query(
              {
                pageSize: $scope.casePageSize,
                pageNo: $scope.caseCurrentPage,
                from: moment(caseFilter.from).format("YYYYMMDD"),
                to: moment(caseFilter.to).format("YYYYMMDD"),
              },
              {
                find: JSON.stringify(findCriteria),
                sort: JSON.stringify(sortCriteria),
              }
            )
            .$promise.then(function (data) {
              delete $scope.cases;
              $scope.cases = _.map(data, function (c) {
                return new Case(c);
              });
              if (
                caseFilterConfig.caseType === "washSaleAnalysis" ||
                caseFilterConfig.caseType === "spoofingAnalysis"
              ) {
                decoder($scope.cases, $scope.cases, caseFilterConfig.caseType);
              } else {
                decoder($scope.cases, $scope.cases);
              }
              // $scope.cases = data;
              $scope.selectCase($scope.cases[0]);
              $scope.reshapeCaseListPanel();
            });
        };

        criteria
          .getFindCriteria(caseFilter, caseFilterConfig)
          .then(function (fc) {
            findCriteria = fc;
            runIt();
          });
      };

      function decoder(cases, data, caseType, ignoreAlert) {
        var promises = [];
        if (
          caseType === "washSaleAnalysis" ||
          caseType === "spoofingAnalysis"
        ) {
          //Decoding washsale buy and sell account number
          var hashes = data.map(function (d) {
            return { hash: d[caseType].buy.order.account_number };
          });
          var decoded = anonymizerFactory.decode({ hashRequest: hashes });
          promises.push(decoded.$promise);
          decoded.$promise.then(function (decodedData) {
            if (data.length !== decodedData.length && !ignoreAlert) {
              $scope.alerts.push({ type: "danger", msg: "Invalid reply" });
            }
            for (var i = 0; i < cases.length; i++) {
              cases[i][caseType].buy.order.accountNumberDecoded =
                decodedData[i].value;
            }
            return decodedData;
          });
          var hashes = data.map(function (d) {
            return { hash: d[caseType].sell.order.account_number };
          });
          var decoded = anonymizerFactory.decode({ hashRequest: hashes });
          promises.push(decoded.$promise);
          decoded.$promise.then(function (decodedData) {
            if (data.length !== decodedData.length && !ignoreAlert) {
              $scope.alerts.push({ type: "danger", msg: "Invalid reply" });
            }
            for (var i = 0; i < cases.length; i++) {
              cases[i][caseType].sell.order.accountNumberDecoded =
                decodedData[i].value;
            }
            return decodedData;
          });

          //Decoding washsale buy and sell ibd
          var buyIbdHashes = data.map(function (d) {
            return { hash: d[caseType].buy.order.acct_corrnum };
          });
          promises.push(
            anonymizerFactory
              .decode({ hashRequest: buyIbdHashes })
              .$promise.then(function (decodedData) {
                if (data.length !== decodedData.length && !ignoreAlert) {
                  $scope.alerts.push({ type: "danger", msg: "Invalid reply" });
                }
                cases.forEach(function (c, i) {
                  c[caseType].buy.order.ibdDecoded = decodedData[i].value;
                });
                return decodedData;
              })
          );
          var sellIbdHashes = data.map(function (d) {
            return { hash: d[caseType].sell.order.acct_corrnum };
          });
          promises.push(
            anonymizerFactory
              .decode({ hashRequest: sellIbdHashes })
              .$promise.then(function (decodedData) {
                if (data.length !== decodedData.length && !ignoreAlert) {
                  $scope.alerts.push({ type: "danger", msg: "Invalid reply" });
                }
                cases.forEach(function (c, i) {
                  c[caseType].sell.order.ibdDecoded = decodedData[i].value;
                });
                return decodedData;
              })
          );
        } else {
          var hashes = data.map(function (d) {
            return { hash: d.accountNumber };
          });
          var decoded = anonymizerFactory.decode({ hashRequest: hashes });
          promises.push(decoded.$promise);
          decoded.$promise.then(function (decodedData) {
            if (data.length !== decodedData.length && !ignoreAlert) {
              $scope.alerts.push({ type: "danger", msg: "Invalid reply" });
            }
            for (var i = 0; i < cases.length; i++) {
              cases[i].accountNumberDecoded = decodedData[i].value;
            }
          });

          hashes = data.map(function (d) {
            return { hash: d.ibd };
          });
          promises.push(
            anonymizerFactory
              .decode({ hashRequest: hashes })
              .$promise.then(function (decodedData) {
                if (data.length !== decodedData.length && !ignoreAlert) {
                  $scope.alerts.push({ type: "danger", msg: "Invalid reply" });
                }
                cases.forEach(function (c, i) {
                  c.ibdDecoded = decodedData[i].value;
                });
                return decodedData;
              })
          );
        }
        cases.forEach(function (c) {
          if (c.aheadOfNewsAnalysis) {
            c.caseType = "Ahead of News";
          } else if (c.washSaleAnalysis) {
            c.caseType = "Wash Sale";
          } else if (c.markingTheCloseAnalysis) {
            c.caseType = "Marking the Close";
          } else if (c.rulesAnalysis) {
            c.caseType = c.rulesAnalysis.rule.name;
          } else if (c.spoofingAnalysis) {
            c.caseType = "Spoofing";
          } else if (c.plAnalysis) {
            c.caseType = "P&L";
          }
        });
        return $q.all(promises);
      }

      $scope.$watch("caseCurrentPage", function () {
        runCaseFilter();
      });

      $scope.$watch("customerTradeSummaryDataCurrentPage", function () {
        if (
          $scope.customerTradeSummaryDataCurrentPage === null ||
          !$scope.caseDetail
        ) {
          return;
        }
        customerDataFactory
          .trades({
            pageSize: $scope.tradeSummaryDataPageSize,
            pageNo: $scope.customerTradeSummaryDataCurrentPage,
            accountNumber: $scope.caseDetail.accountNumber,
            symbol: $scope.caseDetail.realSymbol,
            from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
            to: getDateTo($scope.marketDataFilter.to, $scope.caseDetail).format("YYYYMMDD"),
            mins: 0,
            collapse: 1,
          })
          .$promise.then(function (data) {
            for (var i = 0; i < data.length; i++) {
              data[i].limitedTo = data[i].buy_sell_ind === "SSHORT" ? 2 : 1;
            }
            $scope.customerTradeSummaryData = data;
            $timeout(reshapeBottomPanel, 300);
          });
      });

      $scope.$watch("customerTradeDataCurrentPage", function () {
        if (
          $scope.customerTradeDataCurrentPage === null ||
          !$scope.caseDetail
        ) {
          return;
        }
        customerDataFactory
          .trades({
            pageSize: $scope.customerTradeDataPageSize,
            pageNo: $scope.customerTradeDataCurrentPage,
            accountNumber: $scope.caseDetail.accountNumber,
            symbol: $scope.caseDetail.realSymbol,
            from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
            to: getDateTo($scope.marketDataFilter.to, $scope.caseDetail).format("YYYYMMDD"),
            mins: 0,
            collapse: 0,
          })
          .$promise.then(function (data) {
            for (var i = 0; i < data.length; i++) {
              data[i].limitedTo = data[i].buy_sell_ind === "SSHORT" ? 2 : 1;
            }
            $scope.customerTradeData = data;
            $timeout(reshapeBottomPanel, 300);
          });
      });

      function loadCaseDetail(caseId) {
        $scope.newCaseHistory = {};
        $scope.newHistoryConfig = {};
        $scope.customerTradeDataCurrentPage = null;
        $scope.customerTradeSummaryDataCurrentPage = null;

        caseFactory.get(caseId).$promise.then(function (e) {
          var prevCaseDetail = angular.copy($scope.caseDetail) || {};
          if (e.aheadOfNewsAnalysis) {
            e.beginDtime = e.aheadOfNewsAnalysis.beginDtime;
            e.endDtime = e.aheadOfNewsAnalysis.endDtime;
            e.caseType = "Ahead of News";
          } else if (e.plAnalysis) {
            e.caseType = "P&L";
            e.beginDtime = e.plAnalysis.beginDtime;
            e.endDtime = e.plAnalysis.endDtime;
            // e.beginDtime = e.endDtime = e.plAnalysis.date
          } else if (e.markingTheCloseAnalysis) {
            e.beginDtime = e.endDtime = e.analysisDtime;
            e.caseType = "Marking the Close";
          } else if (e.washSaleAnalysis) {
            e.beginDtime = e.endDtime = e.analysisDtime;
            e.caseType = "Wash Sale";
          } else if (e.rulesAnalysis) {
            e.caseType = e.rulesAnalysis.rule.name;
            // e.beginDtime = $scope.caseDetail.endDtime = e.analysisDtime;
            e.beginDtime = e.rulesAnalysis.rule.startDate;
            e.endDtime = e.rulesAnalysis.rule.endDate || e.beginDtime;
            e = ruleAnalysisHelperFactory.generateSymbolSummary(e);
            $scope.loadRulesMarketData =
              $scope.loadRulesMarketData ||
              function (symbol) {
                /** Don't reload chart if same symbol and same case **/
                if (
                  symbol === $scope.caseDetail.symbol &&
                  prevCaseDetail.mid === $scope.caseDetail.mid
                ) {
                  return;
                }
                prevCaseDetail = $scope.caseDetail;
                $scope.caseDetail.symbol = symbol;
                $scope.loadMarketData();
              };
          } else if (e.spoofingAnalysis) {
            e.caseType = "Spoofing";
            e.beginDtime = e.endDtime = e.analysisDtime;
          } else if (e.mcapAnalysis) {
            e.caseType = "Market Cap";
            e.beginDtime = e.endDtime = e.analysisDtime;
          }
          $scope.caseDetail = new Case(e);
          var realSymbol = $scope.caseDetail.symbol;
          // for BITCOIN-XBTE.STO fix
          if (realSymbol.indexOf(".") === -1) {
            realSymbol = realSymbol.split("-")[0];
          }
          $scope.caseDetail.realSymbol = realSymbol;

          var decoded = anonymizerFactory.decode({
            hashRequest: [{ hash: $scope.caseDetail.accountNumber }],
          });
          decoded.$promise.then(function (decodedData) {
            $scope.caseDetail.accountNumberDecoded = decodedData[0].value;
          });
          var buySellAnalysis =
            $scope.caseDetail.washSaleAnalysis ||
            $scope.caseDetail.spoofingAnalysis;
          if (buySellAnalysis) {
            anonymizerFactory
              .decode({
                hashRequest: [
                  { hash: buySellAnalysis.buy.order.account_number },
                ],
              })
              .$promise.then(function (decodedData) {
                buySellAnalysis.buy.order.accountNumberDecoded =
                  decodedData[0].value;
              });
            anonymizerFactory
              .decode({
                hashRequest: [
                  { hash: buySellAnalysis.sell.order.account_number },
                ],
              })
              .$promise.then(function (decodedData) {
                buySellAnalysis.sell.order.accountNumberDecoded =
                  decodedData[0].value;
              });
          }
          if (e.hasOwnProperty("history") && e.history.length > 0) {
            $scope.caseMid = e.mid;
          }
          $scope.marketDataFilter.from = new Date($scope.caseDetail.beginDtime);
          $scope.marketDataFilter.to = getDateTo($scope.caseDetail.endDtime, $scope.caseDetail).toDate();
          $scope.loadMarketData(true);
          setTimeout($scope.reshapeBottomPanel, 200);
        });
      }

      $scope.marketDataFilter = {};
      $scope.marketData = {};
      $scope.resetMarketDataDatesToEvent = function () {
        $scope.marketDataFilter.from = new Date($scope.caseDetail.beginDtime);
        $scope.marketDataFilter.to = getDateTo($scope.caseDetail.endDtime, $scope.caseDetail).toDate();
        $scope.loadMarketData();
      };

      function saveFile(data, status, headers, config, name) {
        if (!data) {
          $scope.addAlert({ type: "danger", msg: "No Trading Data" }, 3000);
          return;
        }
        var octetStreamMime = "application/octet-stream";

        var dateFrom = moment($scope.marketDataFilter.from).isValid()
          ? moment($scope.marketDataFilter.from).format("MM-DD-YYYY")
          : "";
        var dateTo = moment($scope.marketDataFilter.to).isValid()
          ? moment($scope.marketDataFilter.to).format("MM-DD-YYYY")
          : "";
        var filename = name || "trade ";
        // Get the filename from the x-filename header or default to "download.bin"
        if (dateFrom && dateTo) {
          if (dateFrom === dateTo) {
            filename += dateFrom + ".csv";
          } else {
            filename += dateFrom + " to " + dateTo + ".csv";
          }
        } else {
          filename = "trade.csv";
        }
        // Determine the content type from the header or default to "application/octet-stream"
        var contentType = octetStreamMime;

        // Support for saveBlob method (Currently only implemented in Internet Explorer as msSaveBlob, other extension incase of future adoption)
        var saveBlob =
          navigator.msSaveBlob ||
          navigator.webkitSaveBlob ||
          navigator.mozSaveBlob ||
          navigator.saveBlob;
        var urlEncodedParams = config.params
          ? "?" + $.param(config.params)
          : "";
        try {
          if (saveBlob) {
            // Save blob is supported, so get the blob as it's contentType and call save.
            var blob = new Blob([data], { type: contentType });
            saveBlob(blob, filename);
          } else {
            // Get the blob url creator
            var urlCreator =
              window.URL || window.webkitURL || window.mozURL || window.msURL;
            if (urlCreator) {
              // Try to use a download link
              var link = document.createElement("a");
              if ("download" in link) {
                // Prepare a blob URL
                var blob = new Blob([data], { type: contentType });
                var url = urlCreator.createObjectURL(blob);
                link.setAttribute("href", url);

                // Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
                link.setAttribute("download", filename);

                // Simulate clicking the download link
                var event = document.createEvent("MouseEvents");
                event.initMouseEvent(
                  "click",
                  true,
                  true,
                  window,
                  1,
                  0,
                  0,
                  0,
                  0,
                  false,
                  false,
                  false,
                  false,
                  0,
                  null
                );
                link.dispatchEvent(event);
              } else {
                window.open(config.url + urlEncodedParams, "_self");
              }
            } else {
              window.open(config.url + urlEncodedParams, "_self");
            }
          }
        } catch (e) {
          window.open(config.url + urlEncodedParams, "_self");
        }
      }

      $scope.saveFile = saveFile;
      $scope.exportMarketData = function (sellAccountNumber) {
        var requestParam = {
          account: sellAccountNumber
            ? sellAccountNumber
            : $scope.caseDetail.accountNumber,
          symbol: $scope.caseDetail.symbol,
          from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
          to: getDateTo($scope.marketDataFilter.to, $scope.caseDetail).format("YYYYMMDD"),
        };
        customerDataFactory.getTradesCsv(requestParam).success(saveFile);
      };

      minutes = 15;
      $scope.closingPrice = 0;
      $scope.loadMarketData = function () {
        // if ($scope.caseDetail.aheadOfNewsAnalysis || $scope.caseDetail.plAnalysis) {
        if ($scope.caseDetail.aheadOfNewsAnalysis) {
          loadCommonMarketData($scope.aheadOfNewsChartConfig);
          loadAheadOfNewsMarketData();
        } else if ($scope.caseDetail.markingTheCloseAnalysis) {
          loadCommonMarketData($scope.markingTheCloseChartConfig);
          loadMarkingTheCloseMarketData();
          loadMatchedCases($scope.caseDetail);
        } else if ($scope.caseDetail.washSaleAnalysis) {
          loadCommonMarketData($scope.washSaleChartConfig);
          loadWashSalesMarketData();
          // loadWashSaleMultiMatchCases();
          loadMatchedCases($scope.caseDetail);
        } else if ($scope.caseDetail.rulesAnalysis) {
          loadCommonMarketData($scope.ruleChartConfig);
        } else if ($scope.caseDetail.spoofingAnalysis) {
          loadCommonMarketData($scope.spoofingChartConfig);
          loadSpoofingMarketData();
          loadMatchedCases($scope.caseDetail);
        } else if ($scope.caseDetail.mcapAnalysis) {
          loadCommonMarketData($scope.mcapChartConfig);
        } else if ($scope.caseDetail.plAnalysis) {
          loadCommonMarketData($scope.plChartConfig);
          loadPLMarketData();
        }
        $scope.reshapeMarketPanel();
      };

      function loadCommonMarketData(chart) {
        //setup the market chart
        //add chart data cleaning in highcharts-ng if updating the code
        //chartSeries.setData([]);
        chart.series.forEach(function (s) {
          s.data = [];
        });
        chart.options.xAxis.plotBands.length = 0;
        var momentFrom = moment($scope.marketDataFilter.from);
        var momentTo = moment($scope.marketDataFilter.to);
        if (momentTo.isAfter(moment())) {
          momentTo = moment().hour(0).minute(0).second(0).millisecond(0);
          $scope.marketDataFilter.to = momentTo.toDate();
        }
        var daysBetween = momentTo.diff(momentFrom, "days");
        if (daysBetween > 60) {
          $scope.addAlert(
            {
              type: "warning",
              msg: "Date range exceeding 60 days, adjusting end date",
            },
            3000
          );
          $scope.marketDataFilter.to = momentFrom
            .clone()
            .add(60, "days")
            .toDate();
        }
        if (daysBetween < 10) {
          minutes = 15;
        } else if (daysBetween < 30) {
          minutes = 60;
        } else {
          minutes = 120;
        }

        function setInitialChart(data) {
          if (data.length > 0) {
            $scope.showChart = true;
          } else {
            $scope.showChart = false;
          }
          chart.series[0].name = $scope.caseDetail.symbol;
          chart.series[0].data = data.map(function (d) {
            return [
              d.rtime || d.dtime,
              $filter("toDecimal")(d.first_price),
              $filter("toDecimal")(d.high_price),
              $filter("toDecimal")(d.low_price),
              $filter("toDecimal")(d.last_price),
            ];
          });
          chart.series[1].data = data.map(function (d) {
            return [d.rtime || d.dtime, $filter("toDecimal")(d.avg_price)];
          });
          chart.series[2].data = data.map(function (d) {
            return [d.rtime || d.dtime, d.volume];
          });
        }

        if ($scope.caseDetail.spoofingAnalysis) {
          $scope.caseDetail.spoofingGraphData = SpoofingMarketData.createData(
            {
              symbol: $scope.caseDetail.realSymbol,
              from: moment($scope.marketDataFilter.from),
              to: moment($scope.marketDataFilter.to),
              minutes: minutes,
            },
            setInitialChart
          );
          return;
        }

        //always show 5 minutes candles for market manipulation
        if (
          $scope.caseDetail.markingTheCloseAnalysis ||
          $scope.caseDetail.washSaleAnalysis
        ) {
          minutes = 5;
        }

        $scope.customerTradeSummaryDataCurrentPage = null;
        $scope.customerTradeDataCurrentPage = null;
        chart.options.labels.items[0].html = $scope.caseDetail.symbol;
        var candles = marketDataFactory.interval({
          symbol: $scope.caseDetail.realSymbol,
          from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
          to: moment($scope.marketDataFilter.to).format("YYYYMMDD"),
          mins: minutes,
        });
        candles.$promise.then(setInitialChart);
        var isRuleAnalysis =
          $scope.caseDetail.rulesAnalysis || $scope.caseDetail.mcapAnalysis;
        if (
          $scope.caseDetail.aheadOfNewsAnalysis ||
          $scope.caseDetail.markingTheCloseAnalysis ||
          isRuleAnalysis ||
          $scope.caseDetail.plAnalysis
        ) {
          if (!isRuleAnalysis) {
            var customerPositionHistory = customerDataFactory.positionHistory({
              accountNumber: $scope.caseDetail.accountNumber,
              symbol: $scope.caseDetail.realSymbol,
              from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
              to: getDateTo($scope.marketDataFilter.to, $scope.caseDetail).format("YYYYMMDD"),
              mins: minutes,
            });
            customerPositionHistory.$promise.then(function (data) {
              // $timeout(function () {
              //   $scope.customerPositionHistory = data.filter(function (d) {
              //     const cancelTrade = _.find($scope.customerTradeData, {activity_date: d.endDtime, trade_volume: _.get(d.equityPosition, 'position'), order_status: 'CANCEL'});
              //     return d.autoFill === false && !cancelTrade;
              //   });
              // }, 300);
              $scope.customerPositionHistory = data.filter(function (d) {
                return d.autoFill === false;
              });
              var chartData = data.filter(function (d) {
                return d.autoFill === true;
              });
              chart.series[3].data = chartData.map(function (d) {
                return [d.endDtime, Math.abs(d.position)];
              });
              if ($scope.caseDetail.aheadOfNewsAnalysis) {
                if (
                  $scope.caseDetail.aheadOfNewsAnalysis.positionBeforeEvent
                    .equityPosition ||
                  $scope.caseDetail.aheadOfNewsAnalysis.positionAfterEvent
                    .equityPosition
                ) {
                  chart.series[4].data = chartData
                    .map(function (d) {
                      return d.equityPosition
                        ? [d.endDtime, Math.abs(d.equityPosition.position)]
                        : null;
                    })
                    .filter(function (e) {
                      return e !== null;
                    });
                }
                if (
                  $scope.caseDetail.aheadOfNewsAnalysis.positionBeforeEvent
                    .optionsPosition ||
                  $scope.caseDetail.aheadOfNewsAnalysis.positionAfterEvent
                    .optionsPosition
                ) {
                  chart.series[5].data = chartData
                    .map(function (d) {
                      return d.optionsPosition
                        ? [d.endDtime, d.optionsPosition.position]
                        : null;
                    })
                    .filter(function (e) {
                      return e !== null;
                    });
                }
              } else if ($scope.caseDetail.plAnalysis) {
                if (
                  $scope.caseDetail.plAnalysis.openingPosition.equityPosition ||
                  $scope.caseDetail.plAnalysis.closingPosition.equityPosition
                ) {
                  chart.series[4].data = chartData
                    .map(function (d) {
                      return d.equityPosition
                        ? [d.endDtime, Math.abs(d.equityPosition.position)]
                        : null;
                    })
                    .filter(function (e) {
                      return e !== null;
                    });
                }
              }
            });
          }
          var customerTradeDataCollapsed = customerDataFactory.trades({
            accountNumber: $scope.caseDetail.accountNumber,
            symbol: $scope.caseDetail.realSymbol,
            from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
            to: getDateTo($scope.marketDataFilter.to, $scope.caseDetail).format("YYYYMMDD"),
            mins: minutes,
            collapse: 1,
          });
          customerTradeDataCollapsed.$promise.then(function (data) {
            var series1 = !isRuleAnalysis ? chart.series[6] : chart.series[3];
            series1.data = data
              .filter(function (d) {
                return d.buy_sell_ind === "BUY";
              })
              .map(function (d) {
                return {
                  // x: d.activity_date,
                  x: d.rtime,
                  title: " ",
                  text:
                    "Price: " +
                    $filter("toDecimalCurrency")(d.execution_price) +
                    "<br/>Volume: " +
                    d.trade_volume,
                };
              });
            var series2 = !isRuleAnalysis ? chart.series[7] : chart.series[4];
            series2.data = data
              .filter(function (d) {
                return (
                  d.buy_sell_ind === "SELL" ||
                  d.buy_sell_ind.match(/(SSHORT|SSELL)/)
                );
              })
              .map(function (d) {
                return {
                  // x: d.activity_date,
                  x: d.rtime,
                  title: " ",
                  text:
                    "Price: " +
                    $filter("toDecimalCurrency")(d.execution_price) +
                    "<br/>Volume: " +
                    d.trade_volume,
                };
              });
          });
          customerDataFactory
            .paging(getPagingParams(1))
            .$promise.then(function (data) {
              $scope.customerTradeSummaryDataPaging = data;
              $scope.customerTradeSummaryDataCurrentPage = 0;
            });

          customerDataFactory
            .paging(getPagingParams(0))
            .$promise.then(function (data) {
              $scope.customerTradeDataPaging = data;
              $scope.customerTradeDataCurrentPage = 0;
            });
        }
      }

      function getPagingParams(collapse) {
        return {
          pageSize: $scope.tradeSummaryDataPageSize,
          accountNumber: $scope.caseDetail.accountNumber,
          symbol: $scope.caseDetail.realSymbol,
          from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
          to: getDateTo($scope.marketDataFilter.to, $scope.caseDetail).format("YYYYMMDD"),
          collapse: collapse,
        };
      }

      function loadAheadOfNewsMarketData() {
        if ($scope.caseDetail.aheadOfNewsAnalysis) {
          var eventNews = newsFactory.list({
            symbol: $scope.caseDetail.realSymbol,
            from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
            to: moment($scope.marketDataFilter.to).format("YYYYMMDD"),
            dtime: $scope.caseDetail.analysisDtime,
            type: "full",
            mins: minutes,
          });
          eventNews.$promise.then(function (data) {
            var newsData = data.filter(function (d) {
              return d.relevance === "HIGH" || d.relevance === "MODERATE";
            });
            if (newsData.length === 0) {
              newsData = data;
            }
            var c = "A".charCodeAt(0) - 1;
            $scope.eventNews = newsData.map(function (d) {
              c = (c + 1) % "Z".charCodeAt(0);
              d.alpha = String.fromCharCode(c);
              return d;
            });
            $scope.aheadOfNewsChartConfig.series[8].data = $scope.eventNews.map(
              function (d) {
                return {
                  x: d.rtime,
                  title: d.alpha,
                  text:
                    "<b>" +
                    wordwrap(d.article.title, 65, "</b><br/><b>") +
                    "</b><br/>" +
                    wordwrap(d.article.excerpt, 65, "<br/>"),
                };
              }
            );
            $scope.aheadOfNewsChartConfig.loading = false;
          });
        }

        var aheadOfNewsAnalysis =
          $scope.caseDetail.aheadOfNewsAnalysis || $scope.caseDetail.plAnalysis;
        var marketEvent = aheadOfNewsAnalysis.marketEvent;
        if (marketEvent) {
          $scope.aheadOfNewsChartConfig.options.xAxis.plotBands.push({
            value: marketEvent.dtime,
            width: 5,
            color:
              marketEvent.beginExtremePrice > marketEvent.endExtremePrice
                ? "#FFB0B0"
                : "#B0FFB0",
          });
        }
        $scope.aheadOfNewsChartConfig.options.xAxis.plotBands.push({
          value: aheadOfNewsAnalysis.beginDtime,
          width: 2,
          color: "#8C8C8C",
        });
        $scope.aheadOfNewsChartConfig.options.xAxis.plotBands.push({
          value: aheadOfNewsAnalysis.endDtime,
          width: 2,
          color: "#8C8C8C",
        });
      }

      function loadPLMarketData() {
        if ($scope.caseDetail.plAnalysis) {
          var eventNews = newsFactory.list({
            symbol: $scope.caseDetail.realSymbol,
            from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
            to: moment($scope.marketDataFilter.to).format("YYYYMMDD"),
            dtime: $scope.caseDetail.analysisDtime,
            type: "full",
            mins: minutes,
          });
          eventNews.$promise.then(function (data) {
            var newsData = data.filter(function (d) {
              return d.relevance === "HIGH" || d.relevance === "MODERATE";
            });
            if (newsData.length === 0) {
              newsData = data;
            }
            var c = "A".charCodeAt(0) - 1;
            $scope.eventNews = newsData.map(function (d) {
              c = (c + 1) % "Z".charCodeAt(0);
              d.alpha = String.fromCharCode(c);
              return d;
            });
            $scope.plChartConfig.series[8].data = $scope.eventNews.map(
              function (d) {
                return {
                  x: d.rtime,
                  title: d.alpha,
                  text:
                    "<b>" +
                    wordwrap(d.article.title, 65, "</b><br/><b>") +
                    "</b><br/>" +
                    wordwrap(d.article.excerpt, 65, "<br/>"),
                };
              }
            );
            $scope.plChartConfig.loading = false;
          });
        }

        var plAnalysis = $scope.caseDetail.plAnalysis;
        // var marketEvent = plAnalysis.marketEvent;
        // if (marketEvent) {
        //   $scope.plChartConfig.options.xAxis.plotBands.push({
        //     value: marketEvent.dtime,
        //     width: 5,
        //     color: marketEvent.beginExtremePrice > marketEvent.endExtremePrice ? '#FFB0B0' : '#B0FFB0'
        //   });
        // }
        // $scope.plChartConfig.options.xAxis.plotBands.push({
        //   value: plAnalysis.beginDtime,
        //   width: 2,
        //   color: '#8C8C8C'
        // });
        $scope.plChartConfig.options.xAxis.plotBands.push({
          value: plAnalysis.eventPosition.endDtime,
          width: 2,
          color: "#ff4646",
        });
      }

      function loadWashSalesMarketData() {
        customerDataFactory
          .washsaleTrades({ caseId: $scope.caseDetail.mid })
          .$promise.then(function (data) {
            var hashes = data.map(function (d) {
              return { hash: d.account_number };
            });
            var decoded = anonymizerFactory.decode({ hashRequest: hashes });
            decoded.$promise.then(function (decodedData) {
              if (data.length !== decodedData.length) {
                $scope.alerts.push({ type: "danger", msg: "Invalid reply" });
              }
              for (var i = 0; i < data.length; i++) {
                data[i].accountNumberDecoded = decodedData[i].value;
                data[i].limitedTo = data[i].buy_sell_ind === "SSHORT" ? 2 : 1;
              }
              $scope.customerWashSaleSummaryData = data;
              $scope.washSaleummaryCount = data.length;
            });
          });

        $scope.washSaleBuyTradeData = TradingDataSummary.create({
          collapse: 0,
          mins: 0,
          symbol: $scope.caseDetail.realSymbol,
          accountNumber:
            $scope.caseDetail.washSaleAnalysis.buy.order.account_number,
          from: moment($scope.marketDataFilter.from),
          to: moment($scope.marketDataFilter.to),
          side: "buy",
          type: $scope.caseDetail.washSaleAnalysis.buy.order.product,
        });

        $scope.washSaleSellTradeData = TradingDataSummary.create({
          collapse: 0,
          mins: 0,
          symbol: $scope.caseDetail.realSymbol,
          accountNumber:
            $scope.caseDetail.washSaleAnalysis.sell.order.account_number,
          from: moment($scope.marketDataFilter.from),
          to: getDateTo($scope.marketDataFilter.to, $scope.caseDetail),
          side: "sell",
          type: $scope.caseDetail.washSaleAnalysis.sell.order.product,
        });

        customerDataFactory
          .trades({
            accountNumber:
              $scope.caseDetail.washSaleAnalysis.buy.order.account_number,
            symbol: $scope.caseDetail.realSymbol,
            from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
            to: getDateTo($scope.marketDataFilter.to, $scope.caseDetail).format("YYYYMMDD"),
            mins: minutes,
            collapse: 1,
          })
          .$promise.then(function (data) {
            $scope.washSaleChartConfig.series[3].data = data
              .filter(function (d) {
                return d.buy_sell_ind === "BUY";
              })
              .map(function (d) {
                var price = d.execution_price;
                if (price === "NaN") {
                  price = null;
                }
                return {
                  x: d.activity_date,
                  y: price,
                  volume: d.trade_volume,
                  // title: ' ',
                  // text: 'Price: ' + $filter('toDecimalCurrency')(d.execution_price) + '<br/>Volume: ' + d.trade_volume
                };
              });
            $scope.washSaleChartConfig.series[4].data = data
              .filter(function (d) {
                return d.buy_sell_ind === "SELL" || d.buy_sell_ind === "SSHORT";
              })
              .map(function (d) {
                var price = d.execution_price;
                if (price === "NaN") {
                  price = null;
                }
                return {
                  x: d.activity_date,
                  y: price,
                  volume: d.trade_volume,
                  // title: ' ',
                  // text: 'Price: ' + $filter('toDecimalCurrency')(d.execution_price) + '<br/>Volume: ' + d.trade_volume
                };
              });
          });

        if (
          $scope.caseDetail.washSaleAnalysis.buy.order.account_number !==
          $scope.caseDetail.washSaleAnalysis.sell.order.account_number
        ) {
          $scope.caseDetail.sellAccountNumber =
            $scope.caseDetail.washSaleAnalysis.sell.order.account_number;
          customerDataFactory
            .trades({
              accountNumber:
                $scope.caseDetail.washSaleAnalysis.sell.order.account_number,
              symbol: $scope.caseDetail.realSymbol,
              from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
              to: getDateTo($scope.marketDataFilter.to, $scope.caseDetail).format("YYYYMMDD"),
              mins: minutes,
              collapse: 1,
            })
            .$promise.then(function (data) {
              $scope.washSaleChartConfig.series[5].data = data
                .filter(function (d) {
                  return d.buy_sell_ind === "BUY";
                })
                .map(function (d) {
                  return {
                    x: d.activity_date,
                    y: d.execution_price,
                    volume: d.trade_volume,
                    // title: ' ',
                    // text: 'Price: ' + $filter('toDecimalCurrency')(d.execution_price) + '<br/>Volume: ' + d.trade_volume
                  };
                });
              $scope.washSaleChartConfig.series[6].data = data
                .filter(function (d) {
                  return (
                    d.buy_sell_ind === "SELL" || d.buy_sell_ind === "SSHORT"
                  );
                })
                .map(function (d) {
                  return {
                    x: d.activity_date,
                    y: d.execution_price,
                    volume: d.trade_volume,
                    // title: ' ',
                    // text: 'Price: ' + $filter('toDecimalCurrency')(d.execution_price) + '<br/>Volume: ' + d.trade_volume
                  };
                });

              $timeout(reshapeBottomPanel, 300);
            });
        }

        $scope.washSaleChartConfig.options.xAxis.plotBands.push({
          value:
            $scope.caseDetail.washSaleAnalysis.sell.executions
              .firstActivityDtime,
          width: 2,
          color: "#8C8C8C",
        });
        $scope.washSaleChartConfig.options.xAxis.plotBands.push({
          value:
            $scope.caseDetail.washSaleAnalysis.sell.executions
              .lastActivityDtime,
          width: 2,
          color: "#8C8C8C",
        });
      }

      function loadSpoofingMarketData() {
        var analysis = [];
        var customerSpoofingSummaryData = [];
        $scope.spoofingSummaryCount = 0;
        // $scope.triggerSpoofingTrades(true);
        triggerSpoofingDataSize(false);

        // Setting the market data for summary
        if ($scope.caseDetail.spoofingAnalysis.analyzedSide[0] === "B") {
          analysis.push($scope.caseDetail.spoofingAnalysis.buy);
          analysis.push($scope.caseDetail.spoofingAnalysis.sell);
        } else {
          analysis.push($scope.caseDetail.spoofingAnalysis.sell);
          analysis.push($scope.caseDetail.spoofingAnalysis.buy);
        }

        for (var i = 0; i < analysis.length; i++) {
          analysis[i].order.limitedTo =
            analysis[i].order.buy_sell_ind === "SSHORT" ? 2 : 1;
        }
        var canceledOrder = analysis[0].order;
        var nonCanceledOrder = analysis[1].order;
        var nonCanceledSide = analysis[1];

        var summaryTrades = nonCanceledSide.executions.summary;
        summaryTrades = _.map(summaryTrades, function (trade) {
          return _.assign(angular.copy(nonCanceledOrder), {
            activity_date: trade.dtime,
            execution_price: trade.price,
            trade_volume: trade.volume,
            nonCanceledOrder: nonCanceledOrder,
          });
        });
        summaryTrades[summaryTrades.length - 1].order_status = "FILLED";
        customerSpoofingSummaryData.push(canceledOrder);
        customerSpoofingSummaryData =
          customerSpoofingSummaryData.concat(summaryTrades);
        $scope.customerSpoofingSummaryData = customerSpoofingSummaryData;
        $scope.spoofingSummaryCount = customerSpoofingSummaryData.length;
        if (/^B/.test(canceledOrder.buy_sell_ind)) {
          $scope.spoofingChartConfig.series[5].data = [
            {
              x: canceledOrder.order_date,
              y: canceledOrder.nbboBid,
            },
            {
              x: nonCanceledOrder.activity_date,
              y: nonCanceledOrder.nbboBid,
            },
          ];
          $scope.spoofingChartConfig.series[6].data = [];

          // $scope.spoofingChartConfig.series[7].data = [{
          //   x: canceledOrder.activity_date,
          //   y: canceledOrder.execution_price || canceledOrder.limit_price,
          //   volume: canceledOrder.trade_volume,
          //   nbboBid: canceledOrder.nbboBid,
          //   nbboOffer: canceledOrder.nbboOffer
          // }];

          $scope.spoofingChartConfig.series[7].data = [];

          $scope.spoofingChartConfig.series[8].data = [
            {
              x: nonCanceledOrder.activity_date,
              y:
                nonCanceledOrder.execution_price ||
                nonCanceledOrder.limit_price,
              volume: nonCanceledOrder.trade_volume,
              nbboBid: nonCanceledOrder.nbboBid,
              nbboOffer: nonCanceledOrder.nbboOffer,
            },
          ];
        } else {
          $scope.spoofingChartConfig.series[5].data = [];
          $scope.spoofingChartConfig.series[6].data = [
            {
              x: canceledOrder.order_date,
              y: canceledOrder.nbboOffer,
            },
            {
              x: nonCanceledOrder.activity_date,
              y: nonCanceledOrder.nbboOffer,
            },
          ];
          $scope.spoofingChartConfig.series[5].name = "NBBO Offer";
          $scope.spoofingChartConfig.series[6].name = "NBBO Offer";

          // $scope.spoofingChartConfig.series[8].data = [{
          //   x: canceledOrder.activity_date,
          //   y: canceledOrder.limit_price || canceledOrder.execution_price,
          //   volume: canceledOrder.trade_volume,
          //   nbboBid: canceledOrder.nbboBid,
          //   nbboOffer: canceledOrder.nbboOffer
          // }];
          $scope.spoofingChartConfig.series[8].data = [];
          $scope.spoofingChartConfig.series[7].data = [
            {
              x: nonCanceledOrder.activity_date,
              y:
                nonCanceledOrder.limit_price ||
                nonCanceledOrder.execution_price,
              volume: nonCanceledOrder.trade_volume,
              nbboBid: nonCanceledOrder.nbboBid,
              nbboOffer: nonCanceledOrder.nbboOffer,
            },
          ];
        }

        $scope.spoofingChartConfig.options.yAxis[0].plotLines = [
          {
            width: 1,
            value: canceledOrder.limit_price,
            color: /^B/.test(canceledOrder.buy_sell_ind)
              ? "rgba(50, 200, 50, .90)"
              : "rgba(200, 50, 50, .90)",
            zIndex: 1,
          },
        ];

        $scope.spoofingBuyTradeData = TradingDataSummary.create({
          collapse: 0,
          mins: 0,
          symbol: $scope.caseDetail.realSymbol,
          accountNumber:
            $scope.caseDetail.spoofingAnalysis.buy.order.account_number,
          side: "buy",
          from: moment($scope.marketDataFilter.from),
          to: moment($scope.marketDataFilter.to),
          type: $scope.caseDetail.spoofingAnalysis.buy.order.product,
        });

        $scope.spoofingSellTradeData = TradingDataSummary.create({
          collapse: 0,
          mins: 0,
          symbol: $scope.caseDetail.realSymbol,
          accountNumber:
            $scope.caseDetail.spoofingAnalysis.sell.order.account_number,
          side: "sell",
          from: moment($scope.marketDataFilter.from),
          to: moment($scope.marketDataFilter.to),
          type: $scope.caseDetail.spoofingAnalysis.sell.order.product,
        });

        $scope.caseDetail.spoofingGraphMarketData =
          SpoofingMarketData.createMarketData(
            {
              accountNumber:
                $scope.caseDetail.spoofingAnalysis.buy.order.account_number,
              symbol: $scope.caseDetail.realSymbol,
              from: moment($scope.marketDataFilter.from),
              to: moment($scope.marketDataFilter.to),
              collapse: 1,
              minutes: minutes,
              type: $scope.caseDetail.spoofingAnalysis.buy.order.product,
            },
            function (data) {
              $scope.spoofingChartConfig.series[3].data = data
                .filter(function (d) {
                  return d.buy_sell_ind === "BUY";
                })
                .map(function (d) {
                  return {
                    x: d.activity_date,
                    y: d.execution_price,
                    volume: d.trade_volume,
                    nbboBid: d.nbboBid,
                    nbboOffer: d.nbboOffer,
                  };
                });
              $scope.spoofingChartConfig.series[4].data = data
                .filter(function (d) {
                  return (
                    d.buy_sell_ind === "SELL" || d.buy_sell_ind === "SSHORT"
                  );
                })
                .map(function (d) {
                  return {
                    x: d.activity_date,
                    y: d.execution_price,
                    volume: d.trade_volume,
                    nbboBid: d.nbboBid,
                    nbboOffer: d.nbboOffer,
                  };
                });
            }
          );

        var startingActivityTime = canceledOrder.order_date;

        $scope.spoofingChartConfig.options.xAxis.plotBands.push({
          value: startingActivityTime,
          width: 2,
          color: "#8C8C8C",
        });

        var endingActivityTime = canceledOrder.activity_date;

        $scope.spoofingChartConfig.options.xAxis.plotBands.push({
          value: endingActivityTime,
          width: 2,
          color: "#8C8C8C",
        });

        var firstExecutionTime = summaryTrades[0].activity_date;

        $scope.spoofingChartConfig.options.xAxis.plotBands.push({
          value: firstExecutionTime,
          width: 2,
          color: "#8C8C8C",
        });

        // $scope.spoofingChartConfig.options.xAxis.plotBands.push({
        //   value: $scope.caseDetail.spoofingAnalysis.sell.executions.firstActivityDtime,
        //   width: 2,
        //   color: '#8C8C8C'
        // });
        // $scope.spoofingChartConfig.options.xAxis.plotBands.push({
        //   value: $scope.caseDetail.spoofingAnalysis.sell.executions.lastActivityDtime,
        //   width: 2,
        //   color: '#8C8C8C'
        // });
        // $scope.spoofingChartConfig.options.xAxis.plotBands.push({
        //   value: $scope.caseDetail.spoofingAnalysis.buy.executions.firstActivityDtime,
        //   width: 2,
        //   color: '#8C8C8C'
        // });
        // $scope.spoofingChartConfig.options.xAxis.plotBands.push({
        //   value: $scope.caseDetail.spoofingAnalysis.buy.executions.lastActivityDtime,
        //   width: 2,
        //   color: '#8C8C8C'
        // });
        // $scope.spoofingChartConfig.options.xAxis.plotBands.push({
        //   value: canceledOrder.order_date,
        //   width: 2,
        //   color: '#8C8C8C'
        // });
      }

      function triggerSpoofingDataSize(increase) {
        if (increase) {
          $scope.spoofingChartConfig.series[3].marker.radius = 6;
          $scope.spoofingChartConfig.series[4].marker.radius = 6;
          $scope.spoofingChartConfig.series[7].marker.radius = 6;
          $scope.spoofingChartConfig.series[8].marker.radius = 6;
          $scope.spoofingChartConfig.series[5].marker.radius = 7;
          $scope.spoofingChartConfig.series[6].marker.radius = 7;
        } else {
          $scope.spoofingChartConfig.series[3].marker.radius = 4;
          $scope.spoofingChartConfig.series[4].marker.radius = 4;
          $scope.spoofingChartConfig.series[7].marker.radius = 4;
          $scope.spoofingChartConfig.series[8].marker.radius = 4;
          $scope.spoofingChartConfig.series[5].marker.radius = 5;
          $scope.spoofingChartConfig.series[6].marker.radius = 5;
        }
      }

      $scope.triggerSpoofingTrades = function (visibility) {
        $scope.spoofingChartConfig.series[3].visible = visibility;
        $scope.spoofingChartConfig.series[4].visible = visibility;
      };

      function loadMatchedCases(c) {
        var parentCaseId;
        if (
          $scope.selectedMatchedCase &&
          $scope.selectedMatchedCase.parentNiceId
        ) {
          parentCaseId = $scope.selectedMatchedCase.parentNiceId;
        }
        // $scope.selectedMatchedCase = angular.copy(c);
        $scope.selectedMatchedCase = c;
        $scope.selectedMatchedCase.parentNiceId =
          parentCaseId || $scope.caseDetail.niceId;
        $scope.selectedMatchedCase.multiMatchCases =
          $scope.selectedMatchedCase.multiMatchCases || [];
        var allMatchedCases = $scope.selectedMatchedCase.multiMatchCases;
        loadScores($scope.selectedMatchedCase);
        /** Don't replace the current view on click of a matched case **/
        if (!$scope.matchedCaseConfig || !$scope.matchedCaseConfig.show) {
          $scope.multiMatchedCases = allMatchedCases;
          $scope.matchedCaseConfig = {
            exceptionCount: 0,
            currentPage: 1,
            totalPages: 0, //Total pages of the matched cases, 1 if cases.length <= 100
            show: false, //Flag to turn the multi-matched case grid view on or off
          };
        } else {
          $scope.matchedCaseConfig.currentPage = 1;
          $scope.matchedCaseConfig.exceptionCount = 0;
        }
        if (allMatchedCases.length) {
          angular.extend($scope.matchedCaseConfig, {
            totalPages: Math.ceil(allMatchedCases.length / 100),
            exceptionCount:
              $filter("filter")(allMatchedCases, { isException: true }).length +
              "/" +
              allMatchedCases.length,
          });
        }
      }

      function loadScores(c) {
        if (c.loaded) {
          return;
        }
        c.scoreCollection = {};
        var total;
        switch (c.caseType) {
          case "Wash Sale":
            total = c.washSaleAnalysis.scores.total;
            c.washSaleAnalysis.scores.multiMatch = c.washSaleAnalysis.scores
              .multiMatch || { score: 0, value: 0, weight: 0.1 };
            c.scoreCollection = {
              scores: c.washSaleAnalysis.scores,
              total: total,
              roundedTotal: total > 1 ? 100 : toFixed(total * 100, 2),
            };
            delete c.scoreCollection.scores.total;
            break;
          case "Marking the Close":
            total = c.markingTheCloseAnalysis.score;
            c.scoreCollection = {
              scores: {
                volumeScorePerc: c.markingTheCloseAnalysis.volumeScorePerc,
                priceScorePerc: c.markingTheCloseAnalysis.priceScorePerc,
                timingScorePerc: c.markingTheCloseAnalysis.timingScorePerc,
                windowActivityScorePerc:
                  c.markingTheCloseAnalysis.windowActivityScorePerc,
                repeatOffenderScorePerc:
                  c.markingTheCloseAnalysis.repeatOffenderScorePerc,
              },
              total: total,
              roundedTotal: total > 1 ? "100%" : toFixed(total * 100, 2),
            };
            break;
        }
        return c;
      }

      $scope.loadMatchedCaseGrid = function () {
        if (!$scope.selectedMatchedCase.multiMatchCases.length) {
          $scope.matchedCaseConfig.exceptionCount = 0;
          return;
        }
        $scope.prevSelectedCaseIndex = $scope.selectedCaseIndex;
        $scope.selectedCaseIndex = -1;
        $scope.multiMatchedCases = $scope.selectedMatchedCase.multiMatchCases;
        var matchedCaseConfig = $scope.matchedCaseConfig;
        var decodingData = [],
          isProcessed = [];
        matchedCaseConfig.show = true;

        for (var i = 0; i < $scope.multiMatchedCases.length; i++) {
          isProcessed[i] = false;
        }

        $scope.multiMatchedCases.forEach(function (c, i) {
          (function (c, i) {
            caseFactory.get(c.mid).$promise.then(function (data) {
              decodingData[i] = data;
              isProcessed[i] = true;
              $scope.multiMatchedCases[i] = data;
              if (!$filter("filter")(isProcessed, false).length) {
                if ($scope.caseDetail.washSaleAnalysis) {
                  decoder(
                    $scope.multiMatchedCases,
                    decodingData,
                    "washSaleAnalysis"
                  );
                } else if ($scope.caseDetail.spoofingAnalysis) {
                  decoder(
                    $scope.multiMatchedCases,
                    decodingData,
                    "spoofingAnalysis"
                  );
                } else {
                  decoder($scope.multiMatchedCases, decodingData);
                }
              }
            });
          })(c, i);
        });
        resizeAll();
      };

      var previousMatchedCaseSortKey = null;
      $scope.isReversed = false;
      $scope.matchedCaseSortKey = null;
      $scope.sortMultiMatchCases = function (key) {
        $scope.matchedCaseSortKey = key;
        if (previousMatchedCaseSortKey === key) {
          $scope.isReversed = !$scope.isReversed;
        }
        previousMatchedCaseSortKey = key;
      };

      $scope.getSortingClass = function (key) {
        if ($scope.matchedCaseSortKey === key) {
          if ($scope.isReversed) {
            return "glyphicon-arrow-up";
          }
          return "glyphicon-arrow-down";
        }
      };

      $scope.closeMatchCasesDetail = function () {
        if ($scope.matchedCaseConfig) {
          $scope.matchedCaseConfig.show = false;
          $scope.selectedMatchedCase.parentNiceId = null;
        }
        $scope.multiMatchedCases = [];
        $scope.selectedCaseIndex = $scope.prevSelectedCaseIndex;
      };

      function loadMarkingTheCloseMarketData() {
        var marketEvent = $scope.caseDetail.markingTheCloseAnalysis;
        $scope.markingTheCloseChartConfig.options.xAxis.plotBands.push({
          value: marketEvent.date,
          width: 5,
          color:
            marketEvent.priceBefore > marketEvent.priceAfter
              ? "#FFB0B0"
              : "#B0FFB0",
        });
        var volumeHistory = customerDataFactory.volumeHistory({
          accountNumber: $scope.caseDetail.accountNumber,
          symbol: $scope.caseDetail.symbol,
          from: moment($scope.marketDataFilter.from).format("YYYYMMDD"),
          to: getDateTo($scope.marketDataFilter.to, $scope.caseDetail).format("YYYYMMDD"),
          mins: minutes,
        });
        volumeHistory.$promise.then(function (data) {
          var previousTime = [1e14, 0];
          $scope.markingTheCloseChartConfig.series[8].data = data
            .filter(function (d) {
              // Check the previous data point's timestamp to ensure the time is a minute apart at least
              var notNeighboringTime =
                Math.abs(d.activity_date - previousTime[0]) > 6e4 ||
                d.trade_volume !== previousTime[1];
              var otherData = $scope.markingTheCloseChartConfig.series[0].data;
              if (otherData && otherData.length && notNeighboringTime) {
                for (var i in otherData) {
                  notNeighboringTime =
                    Math.abs(otherData[i][0] - previousTime[0]) > 6e4;
                  if (!notNeighboringTime) {
                    break;
                  }
                }
              }
              previousTime = [d.activity_date, d.trade_volume];
              return d.buy_sell_ind === "BUY" && notNeighboringTime;
            })
            .map(function (d) {
              return [d.activity_date, d.trade_volume];
            });
          previousTime = [1e14, 0];
          $scope.markingTheCloseChartConfig.series[9].data = data
            .filter(function (d) {
              var notNeighboringTime =
                Math.abs(d.activity_date - previousTime[0]) > 6e4 ||
                d.trade_volume !== previousTime[1];
              previousTime = [d.activity_date, d.trade_volume];
              return d.buy_sell_ind === "SELL" && notNeighboringTime;
            })
            .map(function (d) {
              // d.parseTime = (new Date(d.activity_date)).toLocaleTimeString();
              return [d.activity_date, d.trade_volume];
            });
        });
      }

      $scope.closeMultiCasesSelection = angular.noop;
      $scope.clearAllCases = angular.noop;

      $scope.isGenericExport = function () {
        var caseDetail = $scope.caseDetail;
        return (
          !caseDetail.washSaleAnalysis &&
          !caseDetail.spoofingAnalysis &&
          !caseDetail.aheadOfNewsAnalysis &&
          !caseDetail.plAnalysis &&
          !caseDetail.markingTheCloseAnalysis
        );
      };

      function wordwrap(str, width, brk, cut) {
        brk = brk || "\n";
        width = width || 75;
        cut = cut || false;
        if (!str) {
          return str;
        }
        var regex =
          ".{1," +
          width +
          "}(\\s|$)" +
          (cut ? "|.{" + width + "}|.+$" : "|\\S+?(\\s|$)");
        return str.match(RegExp(regex, "g")).join(brk);
      }

      var reshapeBottomPanel = function () {
        try {
          $scope.reshapeMarketPanel();
          $scope.reshapeCaseListPanel();
        } catch (e) { }
      };
      $scope.reshapeBottomPanel = reshapeBottomPanel;
      var reshapeBottomPanelTimer;
      $(window).resize(function () {
        $timeout.cancel(reshapeBottomPanelTimer);
        reshapeBottomPanelTimer = $timeout(function () {
          reshapeBottomPanel();
        }, 50);
      });

      var resizeAll = function () {
        setTimeout(function () {
          $(window).resize();
        }, 200);
      };
      $scope.resizeAll = resizeAll;

      $scope.$on("$routeChangeStart", function () {
        $(window).off("resize");
      });

      $scope.displayTooltip = function (value, displayValue) {
        if (value) {
          return displayValue;
        }
        return null;
      };

      $scope.exportToCsv = function () {
        if (!$scope.caseSort.analysisDtime) {
          $scope.caseSort.analysisDtime = -1;
        }
        if (!$scope.caseSort.symbol) {
          $scope.caseSort.symbol = 1;
        }
        var sortCriteria = $scope.caseSort,
          findCriteria = { $and: [] },
          caseFilter = $scope.caseFilter,
          caseFilterConfig = $scope.caseFilterConfig;
        var runIt = function () {
          if (findCriteria.$and.length === 0) {
            delete findCriteria.$and;
          }
          return caseFactory
            .query(
              {
                pageSize: 10000,
                pageNo: 0,
                from: moment(caseFilter.from).format("YYYYMMDD"),
                to: moment(caseFilter.to).format("YYYYMMDD"),
              },
              {
                find: JSON.stringify(findCriteria),
                sort: JSON.stringify(sortCriteria),
              }
            )
            .$promise.then(function (data) {
              var cases = _.map(data, function (o) {
                return new Case(o);
              });
              var promises = [];
              if (
                caseFilterConfig.caseType === "washSaleAnalysis" ||
                caseFilterConfig.caseType === "spoofingAnalysis"
              ) {
                promises.push(
                  decoder(cases, cases, caseFilterConfig.caseType, true)
                );
              } else {
                promises.push(decoder(cases, cases, null, true));
              }
              return $q.all(promises).then(function () {
                return cases;
              });
            });
        };

        criteria
          .getFindCriteria(caseFilter, caseFilterConfig)
          .then(function (fc) {
            findCriteria = fc;
            runIt().then(function (cases) {
              var fileName =
                "Cases (" +
                (caseFilterConfig.caseTypeName || "All Surveillance") +
                ") " +
                moment(caseFilter.from).format("MM-DD-YYYY") +
                " to " +
                moment(caseFilter.to).format("MM-DD-YYYY") +
                ".csv";
              var columns;
              switch (caseFilterConfig.caseType) {
                case "aheadOfNewsAnalysis":
                  columns = aheadOfNewsCol;
                  break;
                case "markingTheCloseAnalysis":
                  columns = markingTheCloseColumns;
                  break;
                case "washSaleAnalysis":
                  columns = washsaleColumns;
                  break;
                case "rulesAnalysis":
                  columns = ruleColumns;
                  if (caseFilterConfig.caseTypeName === "Reg-M 105") {
                    columns = regmColumns;
                  }
                  break;
                case "spoofingAnalysis":
                  columns = spoofingColumns;
                  break;
                case "mcapAnalysis":
                  columns = marketCapColumns;
                  break;
                case "plAnalysis":
                  columns = plColumns;
                  break;
                default:
                  columns = defaultColumns;
              }
              columns = columns.concat([
                {
                  name: "Processing Dt",
                  value: "processingDtime",
                  filter: function (o) {
                    return estFilter(o, "MM/DD/YYYY");
                  },
                },
              ]);
              cases.forEach(o => o.history = _.sortBy(o.history, 'dtime'))
              exportToCsv(cases, fileName, columns);
            });
          });
        // switch($scope.caseFilterConfig.caseType) {
        //   case 'all':

        // }
      };
    }
  );
