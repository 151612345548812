import {
  estFilter,
  noFractionCurrency,
  toDecimalCurrency,
} from "../tools/tk-tools";
import { commonColumns } from "./default-col";

export var markingTheCloseColumns = commonColumns.concat([
  {
    name: "Account",
    value: "accountNumberDecoded",
  },
  {
    name: "IBD",
    value: "ibdDecoded",
  },
  {
    name: "Symbol",
    value: "symbol",
  },
  {
    name: "Score",
    value: "scoreCollection.roundedTotal",
    filter: function (o) {
      return o + "%";
    },
  },
  {
    name: "Date",
    value: "markingTheCloseAnalysis.date",
    filter: function (o) {
      return estFilter(o, "MM/DD/YYYY");
    },
  },
  {
    name: "Day Mkt Vol",
    value: "markingTheCloseAnalysis.totalDailyMarketVolume",
    filter: function (o) {
      return noFractionCurrency(o, "");
    },
  },
  {
    name: "Day Cust Vol",
    value: "markingTheCloseAnalysis.totalDailyCustomerVolume",
    filter: function (o) {
      return noFractionCurrency(o, "");
    },
  },
  {
    name: "Day Cust %",
    value: "markingTheCloseAnalysis.totalDailyMarketPercentage",
    percent: true,
  },
  {
    name: "Win Mkt Vol",
    value: "markingTheCloseAnalysis.marketVolume",
    filter: function (o) {
      return noFractionCurrency(o, "");
    },
  },
  {
    name: "Win Cust Vol",
    value: "markingTheCloseAnalysis.customerVolume",
    filter: function (o) {
      return noFractionCurrency(o, "");
    },
  },
  {
    name: "Win Mkt %",
    value: "markingTheCloseAnalysis.marketPercentage",
    percent: true,
    decimal: 1,
  },
  {
    name: "Value",
    value: "markingTheCloseAnalysis.position.value",
    filter: function (o) {
      return noFractionCurrency(-o);
    },
  },
  {
    name: "Px Before",
    value: "markingTheCloseAnalysis.priceBefore",
    filter: function (o) {
      return toDecimalCurrency(o);
    },
  },
  {
    name: "Px After",
    value: "markingTheCloseAnalysis.priceAfter",
    filter: function (o) {
      return toDecimalCurrency(o);
    },
  },
  {
    name: "Px% Chg",
    value: "markingTheCloseAnalysis.pricePercentageChange",
    percent: true,
  },
  {
    name: "E",
    value: "isException",
    filter: function (o) {
      return o ? "Y" : "N";
    },
  },
]);
