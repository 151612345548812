angular.module('sniperWebApp')
  .service('TradingDataSummary', function ($q, CustomerDataService) {
    /**
     * [TradingDataSummary description]
     * @param {Object} caseInfo
     * {
     *   collapse
     *   mins
     *   symbol
     *   accountNumber
     *   side
     *   from
     *   to
     * }
     */
    function TradingDataSummary (caseInfo, successFn) {
      successFn = successFn || _.noop;
      this.successFn = successFn;
      this.pageSize = 50;
      this.pageNo = 0;
      this.totalPages = 1;
      this.totalRecords = 0;
      this.collapse = caseInfo.collapse;
      this.mins = caseInfo.mins
      this.symbol = caseInfo.symbol;
      this.accountNumber = caseInfo.accountNumber;
      this.side = caseInfo.side;
      this.from = caseInfo.from.format('YYYYMMDD');
      this.to = caseInfo.to.format('YYYYMMDD');
      if(caseInfo.type) {
        if(/option/i.test(caseInfo.type)) {
          this.type = 'options';
        } else if(/equity/i.test(caseInfo.type)) {
          this.type = 'equities';
        }
      }
      this.getPagingInfo();
      this.get();
    }

    TradingDataSummary.prototype.updateDateRange = function (from, to) {
      this.from = from.format('YYYYMMDD');
      this.to = to.format('YYYYMMDD');
    }

    TradingDataSummary.prototype.getPagingInfo = function () {
      var that = this;
      return CustomerDataService.paging({
        from: that.from,
        to: that.to,
        mins: that.mins,
        collapse: that.collapse,
        pageSize: that.pageSize,
        side: that.side,
        type: that.type
      }, that.accountNumber, that.symbol).success(function (pagingInfo) {
        that.totalPages = pagingInfo.totalPages;
        that.totalRecords = pagingInfo.totalRecords;
      });
    };

    TradingDataSummary.prototype.get = function () {
      var that = this;
      return CustomerDataService.trades({
        from: that.from,
        to: that.to,
        mins: that.mins,
        collapse: that.collapse,
        pageSize: that.pageSize,
        pageNo: that.pageNo,
        side: that.side,
        type: that.type
      }, that.accountNumber, that.symbol).success(function (data) {
        for (var i = 0; i < data.length; i++) {
          data[i].limitedTo = data[i].buy_sell_ind === "SSHORT" ? 2 : 1;
        }

        that.summary = data;
        that.successFn(data);
      });
    };

    TradingDataSummary.prototype.previousPage = function () {
      if(this.pageNo === 0) {
        return $q.when(this.summary);
      }
      this.pageNo --;
      return this.get();
    };

    TradingDataSummary.prototype.nextPage = function () {
      if(this.pageNo > this.totalPages) {
        return $q.when(this.summary);
      }
      if(this.totalRecords <= (this.pageNo + 1) * this.pageSize) {
        return $q.when(this.summary);
      }
      this.pageNo ++;
      return this.get();
    };

    TradingDataSummary.prototype.firstPage = function () {
      if(this.pageNo === 0) {
        return $q.when(this.summary);
      }
      this.pageNo = 0;
      return this.get();
    };

    TradingDataSummary.prototype.lastPage = function () {
      if(this.pageNo + 1 ===  this.totalPages) {
        return $q.when(this.summary);
      }
      this.pageNo = this.totalPages - 1;
      return this.get();
    };

    this.create = function (caseInfo, successFn) {
      return new TradingDataSummary(caseInfo, successFn);
    };

  });
