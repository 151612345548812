angular.module('sniperWebApp')
  .service('SpoofingMarketData', function ($timeout, $q, CustomerDataService, MarketDataFactory) {

    function SpoofingDataSummary (caseInfo, successFn) {
      successFn = successFn || _.noop;
      this.successFn = successFn;
      this.symbol = caseInfo.symbol;
      this.from = caseInfo.from.format('YYYYMMDD');
      this.to = caseInfo.to.format('YYYYMMDD');
      this.minutes = caseInfo.minutes;
      if(caseInfo.type) {
        if(/option/i.test(caseInfo.type)) {
          this.type = 'options';
        } else if(/equity/i.test(caseInfo.type)) {
          this.type = 'equities';
        }
      }

      this.data = [];
      this.get();
    }

    SpoofingDataSummary.prototype.get = function () {
      var that = this;
      return MarketDataFactory.interval({
        from: that.from,
        to: that.to,
        mins: that.minutes
      }, this.symbol).success(function (data) {
        that.data = data;
        that.successFn(data);
      });
    };

    SpoofingDataSummary.prototype.zoom = function (min, max) {
      var that = this;
      var rangeThreshold = 1 * 60 * 60 * 1000; //1 hour
      var diff = moment(max).diff(moment(min));
      var withinThreshold = false;
      if((!min || diff > rangeThreshold) && this.fullSizedData) {
        this.minutes = this.fullSizedMinutes;
        this.data = this.fullSizedData;
        $timeout(function () {
          that.successFn(that.data);
        });
      } else {
        withinThreshold = true;
        if(!this.fullSizedData) {
          this.fullSizedData = angular.copy(this.data);
          this.fullSizedMinutes = this.minutes;
        }
        if(this.minSizedData) {
          this.data = this.minSizedData;
          $timeout(function () {
            that.successFn(that.data);
          });
          return withinThreshold;
        }
        this.minutes = 1;
        this.get().success(function (data) {
          that.minSizedData = data;
        });
      }
      return withinThreshold;
    };

    this.createData = function (caseInfo, successFn) {
      return new SpoofingDataSummary(caseInfo, successFn);
    };

    function SpoofingMarketData (caseInfo, successFn) {
      this.collapse = caseInfo.collapse;
      this.accountNumber = caseInfo.accountNumber;
      SpoofingDataSummary.call(this, caseInfo, successFn);
    }

    SpoofingMarketData.prototype = Object.create(null);
    _.assign(SpoofingMarketData.prototype, SpoofingDataSummary.prototype);
    SpoofingMarketData.prototype.get = function () {
      var that = this;
      return CustomerDataService.trades({
        symbol: that.symbol,
        from: that.from,
        to: that.to,
        collapse: that.collapse,
        mins: that.minutes,
        type: that.type
      }, that.accountNumber, that.symbol).success(function (data) {
        that.data = data;
        that.successFn(data);
      });
    };

    this.createMarketData = function (caseInfo, successFn) {
      return new SpoofingMarketData(caseInfo, successFn);
    };


  });
