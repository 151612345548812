import { estFilter, noFractionCurrency } from "../tools/tk-tools";
import { commonColumns } from "./default-col";

export var ruleColumns = commonColumns.concat([
  {
    name: "Rule",
    value: "rulesAnalysis.rule.name",
  },
  {
    name: "Account",
    value: "accountNumberDecoded",
  },
  {
    name: "IBD",
    value: "ibdDecoded",
  },
  {
    name: "Symbol",
    value: "rulesAnalysis.rule.symbol",
  },
  {
    name: "Date",
    value: "rulesAnalysis.dtime",
    filter: function (o) {
      return estFilter(o, "MM/DD/YYYY");
    },
  },
  {
    name: "Volume",
    value: "rulesAnalysis.flaggedSummary.volume",
  },
  {
    name: "Value",
    value: "rulesAnalysis.flaggedSummary.dollars",
    filter: function (o) {
      return noFractionCurrency(o);
    },
  },
  {
    name: "E",
    value: "isException",
    filter: function (o) {
      return o ? "Y" : "N";
    },
  },
]);

var regmColumns = _.cloneDeep(ruleColumns);
_.remove(regmColumns, { name: "Rule" });

export { regmColumns };
