angular.module('sniperWebApp')
  .service('mcapService', function ($http, config) {
    return {
      checkExistence: function () {
        return $http({
          method: 'POST',
          url: config.restApiUrl + '/cases/intervalraw',
          params: {
            from: moment().subtract(3, 'month').format('YYYYMMDD'),
            to: moment().businessSubtract(1).format('YYYYMMDD'),
            pageSize: 1,
            pageNo: 0
          },
          data: {
            find: JSON.stringify({
              analysisType: 'MCAP'
            })
          }
        }).then(function (res) {
          if (_.isEmpty(res.data)) {
            _.remove(config.caseTypeChoices, {id: 'mcapAnalysis'})
          }
          return res
        });
      }
    };
  });
