let noFractionCurrency;
let toDecimalCurrency;
let multiplyForOption;

angular
  .module("tools.tk", [])
  .directive("templateUrl", function ($compile, $http, $parse) {
    return {
      restrict: "A",
      scope: false,
      priority: 1,
      link: function ($scope, element, attrs) {
        var templateUrl = attrs.templateUrl;
        if (!templateUrl) {
          return;
        }

        function compileTemplate(url) {
          $http({
            method: "GET",
            url: url,
          }).success(function (template) {
            var compiledTemplate = $compile(template)($scope);
            element.append(compiledTemplate);

            if (attrs.onComplete) {
              $scope.$eval(attrs.onComplete);
            }
          });
        }

        if (/.html$/.test(templateUrl)) {
          compileTemplate(templateUrl);
        } else {
          var watcher = $scope.$watch(
            function () {
              return $parse(templateUrl)($scope);
            },
            function (url) {
              if (url) {
                compileTemplate(url);
                //unbind watcher function
                watcher();
              }
            }
          );
        }
      },
    };
  })
  .directive("popScoresModal", function ($modal, $filter) {
    return {
      link: function ($scope, element, attrs) {
        $scope.openProgreeBar = function () {
          $modal.open({
            templateUrl: "views/modal-scoreBar.html",
            size: "sm",
            windowClass: "gold-frame",
            resolve: {
              scoreCollection: function () {
                if (attrs.model) {
                  return $scope.c.scoreCollection;
                }
                return $scope.selectedMatchedCase.scoreCollection;
              },
              surveillance: function () {
                if (attrs.model) {
                  return $scope.c.caseType;
                }
                return $scope.selectedMatchedCase.caseType;
              },
            },
            controller: function (
              $scope,
              $modalInstance,
              scoreCollection,
              surveillance
            ) {
              var modalScores = angular.copy(scoreCollection.scores);
              $scope.scores = [];
              $scope.title = surveillance;
              $scope.totalScore = toFixed(
                parseFloat(scoreCollection.roundedTotal)
              );

              if (surveillance === "Wash Sale") {
                var advScore = null;
                for (var i in modalScores) {
                  var value =
                    (modalScores[i].value || modalScores[i] || 0.000001) * 100;
                  value = toFixed(value);
                  var score = {
                    name: $filter("scoresFilter")(i, surveillance),
                    pseudoName: i,
                    value: value,
                    weight: modalScores[i].weight,
                    score: modalScores[i].score,
                  };
                  if (i === "adv") {
                    advScore = score;
                  } else {
                    $scope.scores.push(score);
                  }
                }
                if (advScore) {
                  $scope.scores.push(advScore);
                }
                /** Sorting by Weight for Wash Sale **/
                $scope.scores = $filter("orderBy")(
                  $scope.scores,
                  "weight",
                  "name"
                );
              } else if (surveillance === "Marking the Close") {
                /** Sorting by percentage for Marking the Close **/
                $scope.scores[0] = {
                  name: $filter("scoresFilter")(
                    "timingScorePerc",
                    surveillance
                  ),
                  value: (modalScores.timingScorePerc * 100).toFixed(),
                };
                $scope.scores[1] = {
                  name: $filter("scoresFilter")("priceScorePerc", surveillance),
                  value: (modalScores.priceScorePerc * 100).toFixed(),
                };
                $scope.scores[2] = {
                  name: $filter("scoresFilter")(
                    "windowActivityScorePerc",
                    surveillance
                  ),
                  value: (modalScores.windowActivityScorePerc * 100).toFixed(),
                };
                $scope.scores[3] = {
                  name: $filter("scoresFilter")(
                    "volumeScorePerc",
                    surveillance
                  ),
                  value: (modalScores.volumeScorePerc * 100).toFixed(),
                };
                $scope.scores[4] = {
                  name: $filter("scoresFilter")(
                    "repeatOffenderScorePerc",
                    surveillance
                  ),
                  value: (modalScores.repeatOffenderScorePerc * 100).toFixed(),
                };
              }
            },
          });
        };
        element.on("click", $scope.openProgreeBar);
        $scope.$on("$destroy", () => {
          element.off("click");
        });
      },
    };
  })
  .directive("tkgTabs", function () {
    return {
      restrict: "E",
      template:
        '<div class="tab-bar">' +
        '<div ng-repeat="tab in tabs" class="tabs" ng-click="tab.clickFunc()" ng-class="{selected: tab.selected}" style="width:{{tabWidth}}">' +
        "{{tab.name}}" +
        "</div>" +
        "</div>",
      replace: true,
      scope: {
        tabs: "=tabs",
      },
      link($scope, element) {
        var nextEleOffsetTop = element.next().css("top");
        try {
          nextEleOffsetTop = parseFloat(nextEleOffsetTop.replace("px", ""));
        } catch (e) {
          nextEleOffsetTop = 0;
        }
        element.next().css({
          top: nextEleOffsetTop + element.height() + "px",
        });
        $scope.tabWidth = 100 / $scope.tabs.length + "%";
      },
    };
  })
  /** For Bottom Content **/
  .directive("adminConfigSlider", function ($timeout) {
    return {
      link: function ($scope, element, attrs) {
        var maxH = attrs.maxH || 0,
          minH = attrs.minH || 0,
          offsetB = attrs.offsetB || 0;
        maxH = parseFloat(maxH);
        minH = parseFloat(minH);
        offsetB = parseFloat(offsetB);
        var controlElement;

        var getElementInterval = setInterval(function grabElement() {
          controlElement = $(attrs.adminConfigSlider);
          if (controlElement.length) {
            init();
            clearInterval(getElementInterval);
          }
        }, 200);

        function init() {
          if (!$scope.isAlreadyRegistered) {
            $scope.isAlreadyRegistered = true;
            $scope.$watch("isOpen", function (isOpen) {
              if (isOpen) {
                open();
              } else {
                close();
              }
            });
          }

          if (!attrs.disableDefault) {
            element.on("click", $scope.slide);
            $scope.$on("$destroy", () => {
              element.off("click", $scope.slide);
            });
          }
        }

        $scope.isSliding = false;
        $scope.slide = function () {
          if (!$scope.isSliding) {
            $scope.isSliding = true;
            $scope.$apply(function () {
              if ($scope.isOpen) {
                $scope.isOpen = false;
              } else {
                $scope.isOpen = true;
              }
            });
            $timeout(function () {
              $scope.isSliding = false;
            }, 501);
          }
        };

        function open() {
          controlElement.css({
            height: maxH + "px",
            bottom: 0,
          });
          runAfterAnimation();
        }

        function close() {
          $scope.$eval(attrs.executeOnClose);
          controlElement.css({
            height: minH + "px",
            bottom: offsetB + "px",
          });
          runAfterAnimation();
        }

        function runAfterAnimation() {
          controlElement.find(".table-wrapper").trigger("triggerResize");
          controlElement.prev().find(".table-wrapper").trigger("triggerResize");
          setTimeout(function () {
            controlElement.prev().css({
              height:
                window.innerHeight -
                controlElement.prev().offset().top -
                getOffsetBottom(),
            });
          });
        }

        function getOffsetBottom() {
          return window.innerHeight - controlElement.offset().top;
        }

        $timeout(function () {
          $scope.isOpen = false;
        }, 200);
      },
    };
  })
  .directive("topContentResizer", function () {
    return {
      link: function ($scope, element) {
        var bottomContent = $("#bottom-content"),
          resizeTimeout;
        setTimeout(function () {
          bottomContent = $("#bottom-content");
        }, 100);

        $(window).resize(resize);
        $scope.$on("$destroy", () => {
          $(window).off("resize", resize);
          clearTimeout(resizeTimeout);
        });

        function resize() {
          window.clearTimeout(resizeTimeout);
          resizeTimeout = setTimeout(function () {
            element.css({
              height:
                window.innerHeight - element.offset().top - getOffsetBottom(),
            });
          }, 101);
        }

        setTimeout(resize, 201);

        function getOffsetBottom() {
          return window.innerHeight - bottomContent.offset().top;
        }
      },
    };
  })
  .directive("tableWrapper", function () {
    return {
      restrict: "C",
      link: function ($scope, element, attrs) {
        var footerHeight = 0;
        var bottomOffsetEle = $(attrs.bottomOffsetEle);
        // element.css({
        //   'overflow-y': 'scroll'
        // });
        initializePosition();
        var resizeTimeout;

        function resize() {
          window.clearTimeout(resizeTimeout);
          resizeTimeout = window.setTimeout(adjustHeight, 100);
        }
        $(window).resize(resize);

        function getOffsetBottom() {
          var bottomOffset = 0;

          bottomOffsetEle = $(attrs.bottomOffsetEle);
          if (bottomOffsetEle.length) {
            bottomOffset = window.innerHeight - bottomOffsetEle.offset().top;
          } else {
            bottomOffset = footerHeight + 23;
          }
          return bottomOffset;
        }

        function adjustHeight() {
          element.css({
            height:
              window.innerHeight - element.offset().top - getOffsetBottom(),
          });
          var tableEle = $(".scrollableContainer");
          var bottomEle = $("#bottom-content");
          var footerOffset;
          if (bottomEle.length && bottomEle.height()) {
            footerOffset = $("#bottom-content").offset().top;
          } else {
            footerOffset = window.innerHeight;
          }
          tableEle.css({
            height: footerOffset - tableEle.offset().top - 8 + "px",
          });
        }

        setTimeout(function () {
          bottomOffsetEle.on("click", initializePosition);
        }, 500);
        element.on("click", initializePosition);
        element.on("triggerResize", adjustHeight);

        function initializePosition() {
          setTimeout(adjustHeight, 500);
        }

        $scope.$on("$destroy", function () {
          $(window).off("resize", resize);
          element.off("click", initializePosition);
          element.off("triggerResize", adjustHeight);
        });
      },
    };
  })
  .directive("resCol", function () {
    return {
      restrict: "C",
      link: function ($scope, element, attrs) {
        var viewportPercent = (attrs.res || 10) / 100;
        element.css("width", (attrs.res || 10) + "vw");
        if (attrs.maxWidth) {
          element.css("max-width", attrs.maxWidth + "vw");
        }
        var browserType = navigator.userAgent,
          isSafari = !!browserType
            .split(" ")
            [browserType.split(" ").length - 1].match(/safari/i);
        if (!browserType.match(/chrome/i) && isSafari) {
          var viewportWidth, resizeTimeout;
          function resizeColumns() {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(function () {
              viewportWidth = Math.max(
                document.documentElement.clientWidth,
                window.innerWidth || 0
              );
              element.css("width", viewportPercent * viewportWidth + "px");
              if (attrs.maxWidth) {
                element.css(
                  "max-width",
                  (attrs.maxWidth / 100) * viewportWidth + "px"
                );
              }
            }, 100);
          }
          $(window).resize(resizeColumns);
          resizeColumns();
          $scope.$on("$destroy", function () {
            $(window).off("resize", resizeColumns);
          });
        }
      },
    };
  })
  /** Hack to safari browser that doesn't take viewport width and height correctly **/
  .directive("resizer", function () {
    return {
      restrict: "A",
      link: function ($scope, element) {
        /** hacking chrome for resize sensor **/
        // if(!navigator.userAgent.match('Chrome')) {
        //   return;
        // }
        var mousedown = false,
          docEle = angular.element(document);

        function mousedownEvent() {
          mousedown = true;
        }
        function mouseupEvent() {
          if (mousedown) {
            mousedown = false;
            $scope.reshapeMarketPanel();
            $scope.reshapeCaseListPanel();
          }
        }
        element.on("mousedown", mousedownEvent);
        docEle.on("mouseup", mouseupEvent);

        $scope.$on("$destroy", function () {
          element.off("mousedown", mousedownEvent);
          docEle.off("mouseup", mouseupEvent);
        });
      },
    };
  })
  .directive("caseListPanel", () => {
    return {
      restrict: "A",
      link: function ($scope, element) {
        var allowArrowNav = false,
          floodOff = true,
          docEle = angular.element(document),
          timeoutTracker;

        $scope.selectedCaseIndex = -1;

        var originalSelectCaseFun = $scope.selectCase;
        $scope.selectCase = function (v) {
          originalSelectCaseFun(v);
          $scope.selectedCaseIndex =
            this.$index !== undefined ? this.$index : $scope.selectedCaseIndex;
        };

        $scope.$watch("caseFilterConfig.caseType", function (type) {
          if (type) {
            $scope.selectedCaseIndex = -1;
          }
        });

        function docEleClickEvent() {
          allowArrowNav = false;
        }
        function eleClickEvent() {
          setTimeout(function () {
            allowArrowNav = true;
          });
        }

        docEle.on("click", docEleClickEvent);
        element.on("click", eleClickEvent);

        function keyup(evt) {
          floodOff = false;
          var selectedIndex = $scope.selectedCaseIndex;
          $scope.$apply(function () {
            if (allowArrowNav) {
              if (!$scope.matchedCaseConfig || !$scope.matchedCaseConfig.show) {
                if ($scope.cases) {
                  if (selectedIndex === -1) {
                    $scope.selectCase($scope.cases[0]);
                    return;
                  }
                  if (
                    (evt.keyCode === 38 && selectedIndex >= 0) ||
                    (evt.keyCode === 40 && selectedIndex < $scope.cases.length)
                  ) {
                    window.setTimeout(function () {
                      if (floodOff) {
                        $scope.$apply(function () {
                          $scope.selectCase($scope.cases[selectedIndex]);
                        });
                      }
                    }, 300);
                  }
                }
              } else {
                if (selectedIndex === -1) {
                  $scope.selectCase($scope.multiMatchedCases[0]);
                  return;
                }
                if (
                  (evt.keyCode === 38 && selectedIndex >= 0) ||
                  (evt.keyCode === 40 &&
                    selectedIndex < $scope.multiMatchedCases.length)
                ) {
                  window.setTimeout(function () {
                    if (floodOff) {
                      $scope.selectCase(
                        $scope.multiMatchedCases[selectedIndex]
                      );
                    }
                  }, 300);
                }
              }
            }
          });
          timeoutTracker = window.setTimeout(function () {
            floodOff = true;
          }, 250);
        }
        docEle.on("keyup", keyup);

        function keydown(evt) {
          $scope.$apply(function () {
            if (allowArrowNav) {
              var selectedIndex = $scope.selectedCaseIndex;
              if (!$scope.matchedCaseConfig || !$scope.matchedCaseConfig.show) {
                if (evt.keyCode === 38 || evt.keyCode === 40) {
                  if (evt.keyCode === 38 && selectedIndex - 1 >= 0) {
                    //up arrow
                    $scope.selectedCaseIndex = selectedIndex - 1;
                  } else if (
                    evt.keyCode === 40 &&
                    selectedIndex + 1 < $scope.cases.length
                  ) {
                    //down arrow
                    $scope.selectedCaseIndex = selectedIndex + 1;
                  }
                  if ($scope.selectedCase) {
                    $scope.selectedCase.mid = null;
                  }
                  setTimeout(function () {
                    $(
                      'tr[row-id="' +
                        $scope.cases[$scope.selectedCaseIndex].mid +
                        '"]'
                    )[0].scrollIntoView(false);
                  });
                }
              } else {
                if (evt.keyCode === 38 || evt.keyCode === 40) {
                  if (evt.keyCode === 38 && selectedIndex - 1 >= 0) {
                    //up arrow
                    $scope.selectedCaseIndex = selectedIndex - 1;
                  } else if (
                    evt.keyCode === 40 &&
                    selectedIndex + 1 < $scope.multiMatchedCases.length
                  ) {
                    //down arrow
                    $scope.selectedCaseIndex = selectedIndex + 1;
                  }
                  if ($scope.selectedCase) {
                    $scope.selectedCase.mid = null;
                  }
                  setTimeout(function () {
                    $(
                      'tr[row-id="' +
                        $scope.multiMatchedCases[$scope.selectedCaseIndex].mid +
                        '"]'
                    )[0].scrollIntoView(false);
                  });
                }
              }
            }
          });
          window.clearTimeout(timeoutTracker);
        }
        docEle.on("keydown", keydown);

        $scope.$on("$destroy", function () {
          docEle.off("click", docEleClickEvent);
          element.off("click", eleClickEvent);
          docEle.off("keyup", keyup);
          docEle.off("keydown", keydown);
        });
      },
    };
  })
  .directive("staticInclude", [
    "$http",
    "$templateCache",
    "$compile",
    "$parse",
    function ($http, $templateCache, $compile, $parse) {
      return function (scope, element, attrs) {
        var templatePath = $parse(attrs.staticInclude)(scope);

        attrs.$observe("staticInclude", function (value) {
          scope.$watch(value, function () {
            loadTemplate(templatePath);
          });
        });

        function loadTemplate(templatePath) {
          $http
            .get(templatePath, { cache: $templateCache })
            .success(function (response) {
              element.html(response);
              $compile(element.contents())(scope);
            });
        }
      };
    },
  ])
  .directive("uppercase", function () {
    return {
      require: "ngModel",
      link: function (scope, element, attrs, modelCtrl) {
        var capitalize = function (inputValue) {
          if (!inputValue) return;
          var capitalized = inputValue.toUpperCase();
          if (capitalized !== inputValue) {
            modelCtrl.$setViewValue(capitalized);
            modelCtrl.$render();
          }
          return capitalized;
        };
        modelCtrl.$parsers.push(capitalize);
        capitalize(scope[attrs.ngModel]); // capitalize initial value
      },
    };
  })
  .directive("resizer", function ($document) {
    return function ($scope, $element, $attrs) {
      function eleMousedownEvent(event) {
        event.preventDefault();
        $document.on("mousemove", mousemove);
        $document.on("mouseup", mouseup);
      }
      $element.on("mousedown", eleMousedownEvent);

      function mousemove(event) {
        if ($attrs.resizer === "vertical") {
          // Handle vertical resizer
          var x = event.pageX;
          if ($attrs.resizerMax && x > $attrs.resizerMax) {
            x = parseInt($attrs.resizerMax);
          }
          $element.css({
            left: x + "px",
          });
          $($attrs.resizerLeft).css({
            width: x + "px",
          });
          $($attrs.resizerRight).css({
            left: x + parseInt($attrs.resizerWidth) + "px",
          });
        } else {
          // Handle horizontal resizer
          var y = window.innerHeight - event.pageY;
          $element.css({
            bottom: y + "px",
          });
          $($attrs.resizerTop).css({
            bottom: y + parseInt($attrs.resizerHeight) + "px",
          });
          $($attrs.resizerBottom).css({
            height: y + "px",
          });
        }
      }
      function mouseup() {
        readjustBottom();
        $document.unbind("mousemove", mousemove);
        $document.unbind("mouseup", mouseup);
      }

      function readjustBottom() {
        var min = 80;
        if ($element.offset().top < min) {
          var y = window.innerHeight - min;
          $element.css({
            bottom: y + "px",
          });
          $($attrs.resizerTop).css({
            bottom: y + parseInt($attrs.resizerHeight) + "px",
          });
          $($attrs.resizerBottom).css({
            height: y + "px",
          });
        } else if ($element.offset().top > window.innerHeight - 10) {
          $element.css({
            bottom: min + "px",
          });
          $($attrs.resizerTop).css({
            bottom: min + parseInt($attrs.resizerHeight) + "px",
          });
          $($attrs.resizerBottom).css({
            height: min + "px",
          });
        }
      }

      function resize() {
        clearTimeout(timer);
        timer = setTimeout(readjustBottom, 100);
      }

      if ($attrs.resizer === "horizontal") {
        var timer;
        $(window).resize(resize);
      }

      $scope.$on("$destroy", function () {
        $(window).off("resize", resize);
        $element.off("mousedown", eleMousedownEvent);
        $document.off("mousemove", mousemove);
        $document.off("mouseup", mouseup);
      });
    };
  })
  .directive("exportWashSaleCsv", function ($filter, jsonToCsv) {
    return {
      link: function ($scope, element) {
        function getSummaryJson() {
          var summary = [];
          var data = $scope.customerWashSaleSummaryData;
          for (var i = 0; i < data.length; i++) {
            var orderId =
              data[i].source === "regular"
                ? (data[i].order_branch || "").trim() + data[i].order_seq
                : data[i].order_seq;
            orderId = orderId || ",";
            summary.push({
              "Execution Date": $filter("estFilter")(
                data[i].activity_date,
                "MM/DD/YYYY-h:mm:ssa"
              ),
              Id: data[i].activity_seq || ",",
              Account: data[i].accountNumberDecoded || ",",
              "Buy/Sell": data[i].buy_sell_ind || ",",
              Symbol: data[i].symbol || ",",
              "Original Volume": data[i].original_volume || ",",
              Volume: data[i].trade_volume || ",",
              Price:
                $filter("toDecimalCurrency")(data[i].execution_price).replace(
                  /[,$]/g,
                  ""
                ) || ",",
              Option: data[i].option_type || "",
              "Option Exp": data[i].option_expire_date
                ? $filter("estFilter")(
                    data[i].option_expire_date,
                    "MM/DD/YYYY-h:mm:ssa"
                  )
                : "",
              "Option Strike":
                $filter("toDecimalCurrency")(data[i].option_strike_price) || "",
              "Order Date": $filter("estFilter")(
                data[i].order_date,
                "MM/DD/YYYY-h:mm:ssa"
              ),
              "Order Id": orderId,
              "Order Type": data[i].order_type || ",",
              "Limit Price": $filter("toDecimalCurrency")(
                data[i].limit_price
              ).replace(/[,$]/g, ""),
              Source: data[i].source === "regular" ? "atoms" : data[i].source,
            });
          }

          var csv = jsonToCsv.convert(summary);
          console.log(csv);
          var config = {
            url: "data:text/csv;charset=utf-8," + escape(csv),
          };
          $scope.saveFile(csv, null, null, config, "washsale summary ");
        }

        element.on("click", getSummaryJson);
        $scope.$on("$destroy", function () {
          element.off("click");
        });
      },
    };
  })
  .directive("exportOrderSummary", function ($filter, jsonToCsv) {
    return {
      link: function ($scope, element) {
        function getSummaryJson() {
          var summary = [];
          var isWashsale = !!$scope.caseDetail.washSaleAnalysis;
          var isSpoofing = !!$scope.caseDetail.spoofingAnalysis;
          var isAheadOfnews = !!$scope.caseDetail.aheadOfNewsAnalysis;
          var isPlAnalysis = !!$scope.caseDetail.plAnalysis;
          var isMarkingTheCloseAnalysis =
            !!$scope.caseDetail.markingTheCloseAnalysis;
          var data = isSpoofing
            ? $scope.customerSpoofingSummaryData
            : isAheadOfnews || isPlAnalysis | isMarkingTheCloseAnalysis
            ? $scope.customerTradeSummaryData
            : $scope.customerWashSaleSummaryData;
          var hasRtick = _.some(data, { source: "rtick" });
          for (var i = 0; i < data.length; i++) {
            var orderId =
              data[i].source === "regular"
                ? (data[i].order_branch || "").trim() + data[i].order_seq
                : data[i].order_seq;
            orderId = orderId || "";
            var row = {
              "Execution Date": $filter("estFilter")(
                data[i].activity_date,
                "MM/DD/YYYY-h:mm:ssa"
              ),
              Id: data[i].activity_seq || "",
              Account: data[i].accountNumberDecoded || "",
              "Buy/Sell": data[i].buy_sell_ind || "",
              Symbol: data[i].symbol || "",
              "Original Volume": data[i].original_volume || "",
              Volume: data[i].trade_volume || "",
              Price:
                $filter("toDecimalCurrency")(data[i].execution_price).replace(
                  /[,$]/g,
                  ""
                ) || "",
              "Order Date":
                $filter("estFilter")(
                  data[i].order_date,
                  "MM/DD/YYYY-h:mm:ssa"
                ) || "",
              "Order Id": orderId,
              "Order Type": data[i].order_type || "",
              "Limit Price": $filter("toDecimalCurrency")(
                data[i].limit_price
              ).replace(/[,$]/g, ""),
            };

            if (isAheadOfnews || isPlAnalysis || isWashsale) {
              row = {
                "Execution Date": $filter("estFilter")(
                  data[i].activity_date,
                  "MM/DD/YYYY-h:mm:ssa"
                ),
                Id: data[i].activity_seq || "",
                Account: data[i].accountNumberDecoded || "",
                Side: data[i].buy_sell_ind || "",
                Symbol: data[i].symbol || "",
                "Original Volume": data[i].original_volume || "",
                Volume: data[i].trade_volume || "",
                Price:
                  $filter("toDecimalCurrency")(data[i].execution_price).replace(
                    /[,$]/g,
                    ""
                  ) || "",
                Status: data[i].order_status || "",
                Option: data[i].option_type || "",
                "Option Exp": data[i].option_expire_date
                  ? $filter("estFilter")(
                      data[i].option_expire_date,
                      "MM/DD/YYYY-h:mm:ssa"
                    )
                  : "",
                "Option Strike":
                  $filter("toDecimalCurrency")(data[i].option_strike_price) ||
                  "",
                "Order Date":
                  $filter("estFilter")(
                    data[i].order_date,
                    "MM/DD/YYYY-h:mm:ssa"
                  ) || "",
                "Order Id": orderId,
                "Order Type": data[i].order_type || "",
                "Limit Price": $filter("toDecimalCurrency")(
                  data[i].limit_price
                ).replace(/[,$]/g, ""),
              };
            }

            if (isSpoofing) {
              row["Limit Price"] = row["Limit Price"] || "";
              row["Bid"] =
                $filter("currencyToLimit")(data[i].nbboBid, 3).replace(
                  /[,$]/g,
                  ""
                ) || "";
              row["Ask"] = $filter("currencyToLimit")(
                data[i].nbboOffer,
                3
              ).replace(/[,$]/g, "");
            }
            if (hasRtick) {
              row["Notes"] = data[i].notes || "";
            }
            row["Source"] =
              data[i].source === "regular" ? "atoms" : data[i].source;
            summary.push(row);
          }
          var csv = jsonToCsv.convert(summary);
          console.log(csv);
          var config = {
            url: "data:text/csv;charset=utf-8," + escape(csv),
          };
          var filePrefx = "";
          if (isSpoofing) {
            filePrefx = "spoofing";
          } else if (isAheadOfnews) {
            filePrefx = "ahead of news";
          } else if (isMarkingTheCloseAnalysis) {
            filePrefx = "marking the close";
          } else if (isPlAnalysis) {
            filePrefx = "pl";
          } else if (isWashsale) {
            filePrefx = "washsale";
          }
          $scope.saveFile(csv, null, null, config, filePrefx + " summary ");
        }

        element.on("click", getSummaryJson);
        $scope.$on("$destroy", function () {
          element.off("click");
        });
      },
    };
  })
  .factory("resizer", function () {
    return {
      toggleMaxVertical: function (resizerId, margin) {
        var resizer = angular.element(resizerId);
        var resizerTop = angular.element(resizer.attr("resizer-top"));
        var resizerBottom = angular.element(resizer.attr("resizer-bottom"));
        var resizerHeight = parseInt(resizer.attr("resizer-height"));
        var maximized = resizer.data("maximized");
        var y;
        if (!maximized) {
          resizer.data(
            "lastY",
            parseInt(
              resizer
                .css("bottom")
                .substring(0, resizer.css("bottom").length - 2)
            )
          );
          y = margin - resizerHeight;
        } else {
          y = resizer.data("lastY");
        }
        resizer.data("maximized", !maximized);
        resizer.css({
          bottom: y + "px",
        });
        resizerTop.css({
          bottom: y + resizerHeight + "px",
        });
        resizerBottom.css({
          height: y + "px",
        });
      },
      toggleMaxHorizontal: function (resizerId, margin) {
        var resizer = angular.element(resizerId);
        var resizerLeft = angular.element(resizer.attr("resizer-left"));
        var resizerRight = angular.element(resizer.attr("resizer-right"));
        var resizerWidth = parseInt(resizer.attr("resizer-width"));
        var maximized = resizer.data("maximized");
        var x;
        if (!maximized) {
          resizer.data(
            "lastX",
            parseInt(
              resizer.css("left").substring(0, resizer.css("left").length - 2)
            )
          );
          x = margin - resizerWidth;
        } else {
          x = resizer.data("lastX");
        }
        resizer.data("maximized", !maximized);

        resizer.css({
          left: x + "px",
        });
        resizerLeft.css({
          width: x + "px",
        });
        resizerRight.css({
          left: x + parseInt(resizerWidth) + "px",
        });
      },
    };
  })
  .filter("noFractionCurrency", function ($filter, $locale) {
    var currencyFilter = $filter("currency");
    var formats = $locale.NUMBER_FORMATS;

    noFractionCurrency = (amount, currencySymbol) => {
      var value = currencyFilter(amount, currencySymbol);
      var sep = value.indexOf(formats.DECIMAL_SEP);
      if (amount >= 0) {
        return value.substring(0, sep);
      } else if (amount < 0) {
        return value.substring(0, sep) + ")";
      } else {
        return "";
      }
    };
    return noFractionCurrency;
  })
  .filter("rulesConfigCurrencyFilter", function ($filter) {
    var currencyFilter = $filter("currency");
    return function (value) {
      if (value == -1 || !value) {
        return "";
      } else if (value.toString().match(/\D/g)) {
        return value;
      } else {
        return currencyFilter(value);
      }
    };
  })
  .filter("camelCaseFilter", function () {
    return function (type, minimumLength) {
      minimumLength = minimumLength || 3;
      type = type.split("_");
      for (var i = 0; i < type.length; i++) {
        if (type[i].length < minimumLength) {
          type[i] = type[i].toLowerCase();
          continue;
        }
        type[i] =
          type[i][0] + type[i].substring(1, type[i].length).toLowerCase();
      }
      return type.join(" ");
    };
  })
  //Doesn't lower case if the split is less than minimum length
  .filter("modifiedCamelCaseFilter", function () {
    return function (type, minimumLength) {
      minimumLength = minimumLength || 3;
      type = type.split("_");
      for (var i = 0; i < type.length; i++) {
        if (type[i].length < minimumLength) {
          continue;
        }
        type[i] =
          type[i][0] + type[i].substring(1, type[i].length).toLowerCase();
      }
      return type.join(" ");
    };
  })
  .filter("ruleConfigNameFilter", function () {
    return ruleConfigNameFilter;
  })
  .filter("scoresFilter", function () {
    return function (type, caseType) {
      if (caseType === "Wash Sale") {
        switch (type) {
          case "bothLimit":
            return "Both Limit Order";
            break;
          case "execDollars":
            return "Dollar Transacted";
            break;
          case "execOfDay":
            return "Portion of Account Activity";
            break;
          case "execOfMarket":
            return "Market Participation";
            break;
          case "execOfTotal":
            return "Matched Portion of Order";
            break;
          case "executionTime":
            return "Execution Time Spread";
            break;
          case "ibd":
            return "Same IBD";
            break;
          case "multiMatch":
            return "Multiple Match";
            break;
          case "orderTime":
            return "Order Time Spread";
            break;
          case "price":
            return "Price Spread";
            break;
          case "size":
            return "Executed Shares Match";
            break;
          case "unusual":
            return "Unusual Shares Count";
            break;
          case "pennyStock":
            return "Penny Stock";
            break;
          case "total":
            return "Total";
            break;
          case "adv":
            return "ADV Boost";
            break;
          case "optionStock":
            return "Option Stock";
            break;
        }
      } else if (caseType === "Marking the Close") {
        switch (type) {
          case "volumeScorePerc":
            return "Window Participation Rate";
            break;
          case "priceScorePerc":
            return "Market Price Impact";
            break;
          case "timingScorePerc":
            return "Time to Close";
            break;
          case "windowActivityScorePerc":
            return "Portion of Account Activity";
            break;
          case "repeatOffenderScorePerc":
            return "Pattern of Activity";
        }
      }
      return type;
    };
  })
  .filter("pxChangeFilter", function ($filter) {
    var numberFilter = $filter("number");
    return function (n, aheadOfNewsAnalysis) {
      if (!aheadOfNewsAnalysis) return;
      if (
        _.get(aheadOfNewsAnalysis, "marketEvent.endExtremePrice") >=
        _.get(aheadOfNewsAnalysis, "marketEvent.beginExtremePrice")
      ) {
        return numberFilter(n, 1);
      }
      return -numberFilter(n, 1);
    };
  })
  .filter("noPennyCurrency", function ($filter) {
    var currencyFilter = $filter("currency");
    return function (input) {
      var convertedInput = currencyFilter(input);
      return convertedInput.substring(0, convertedInput.indexOf("."));
    };
  })
  .filter("currencyToLimit", function () {
    return function (input, decimalPlaces) {
      var number = parseFloat(input);
      if (isNaN(number)) return;
      decimalPlaces = decimalPlaces || 2;
      return "$" + toFixed(number, decimalPlaces);
    };
  })
  .filter("toDecimalCurrency", function ($filter) {
    var numberFilter = $filter("number");
    toDecimalCurrency = function (number, threshold, decimalPlaces) {
      number += "";
      number = number.replace(/$/g, "");

      if (isNaN(number)) {
        return "";
      }

      threshold = threshold || 10;

      if (number >= threshold) {
        number = numberFilter(number, decimalPlaces || 2);
      } else {
        number = numberFilter(number, 4);
      }

      return "$" + number;
    };
    return toDecimalCurrency;
  })
  .filter("toDecimal", function () {
    return function (number, threshold) {
      number += "";
      number = number.replace(/$/g, "");

      if (isNaN(number)) {
        return "";
      }

      threshold = threshold || 10;
      if (number >= threshold) {
        number = toFixed(parseFloat(number), 2);
      } else {
        number = toFixed(parseFloat(number, 4));
      }

      return parseFloat(number) || 0;
    };
  })
  .filter("conditionFilter", function () {
    return function (abbr) {
      if (!abbr) return;

      switch (abbr) {
        case "GT":
          return ">";
        case "LT":
          return "<";
        case "EQ":
          return "=";
        case "NE":
          return "!=";
        default:
          return "";
      }
    };
  })
  .filter("productFilter", function ($filter) {
    var camelCaseFilter = $filter("modifiedCamelCaseFilter");
    return function (product) {
      if (typeof product === "string") {
        product = product.replace("RP1_", "");
        return camelCaseFilter(product, 4);
      }
      return product;
    };
  })
  .filter("actionFilter", function () {
    return function (action) {
      switch (action) {
        case "NOTHING":
          return "None";
          break;
        case "CONT_REV":
          return "Continue Review";
          break;
        case "NOTE_IBD_ACCT":
          return "Note IBD and Account";
          break;
        case "REQ_NFO_FROM_IBD":
          return "Request Info from IBD";
          break;
        case "REPORT":
          return "File Incident Report";
          break;
      }
      return action;
    };
  })
  .filter("rankFilter", function () {
    return function (rank) {
      switch (rank) {
        case "NONE":
          return "None";
        case "LOW":
          return "Low";
        case "MEDIUM":
          return "Medium";
        case "HIGH":
          return "High";
      }

      return rank;
    };
  })
  .filter("reasonFilter", function (allReasons) {
    return function (reason) {
      return _.get(_.find(allReasons, { id: reason }), "name") || "None";
      // switch (reason) {
      //   case 'EVT_NOT_RELVT':
      //     return 'Event Not Relevant';
      //     break;
      //   case 'ANT_EVT':
      //     return 'Anticipated Event';
      //     break;
      //   case 'UNANT_EVT':
      //     return 'Unanticipated Event';
      //     break;
      //   case 'CONST_WITH_PRIOR':
      //     return 'Consistent with Prior Activity';
      //     break;
      //   case 'NOT_CONST_WITH_PRIOR':
      //     return 'Not Consistent with Prior Activity';
      //     break;
      //   case 'LARGE_TRANS':
      //     return 'Unusually Large Transaction';
      //     break;
      //   case 'PROFIT_TRANS':
      //     return 'Unusually Profitable Transaction';
      //     break;
      //   case 'HIGH_LEVGD':
      //     return 'Highly Leveraged';
      //     break;
      //   case 'CHANGE_OF_OWNER':
      //     return 'Change of Beneficial Owner';
      //     break;
      //   case 'NO_CHANGE_OF_OWNER':
      //     return 'No Change of Beneficial Owner';
      //     break;
      //   default:
      //     return 'None';
      // }
    };
  })
  .filter("pageLimitFilter", function () {
    return function (array, limit, startPage) {
      // return array;
      if (!array || !array.length) return;

      startPage--;
      return array.slice(startPage * limit, startPage * limit + limit);
    };
  })
  .filter("sideFilter", function () {
    return function (trade) {
      if (!trade) {
        return;
      }
      if (/^SS/.test(trade.buy_sell_ind)) {
        if (trade.sell_short_exempt_flag === "EXEMPT") {
          return "SSX";
        }
        return "SS";
      }
      return trade.buy_sell_ind[0];
    };
  })
  .filter("estFilter", function () {
    return estFilter;
  })
  .filter("multiplyForOption", function ($filter) {
    console.log(arguments);
    multiplyForOption = function (value, product) {
      if (/OPTION/.test(product)) {
        return $filter("noFractionCurrency")(value * 100);
      } else {
        return $filter("noFractionCurrency")(value);
      }
    };
    return multiplyForOption;
  })
  .filter("multiplyForOptionNoPenny", function ($filter) {
    return function (value, product) {
      if (/OPTION/.test(product)) {
        return $filter("noPennyCurrency")(value * 100);
      } else {
        return $filter("noPennyCurrency")(value);
      }
    };
  });

export function estFilter(value, format) {
  if (!value) {
    return;
  }
  return moment.tz(value, "America/New_York").format(format);
}

export function ruleConfigNameFilter(config) {
  if (/REGM/i.test(config)) {
    return "RegM-105";
  } else if (/IPO/i.test(config)) {
    return "IPO";
  } else if (/EXCLUSION/i.test(config)) {
    return "Activity";
  }
  return config;
}

export function toFixed(num, precision) {
  precision = precision || 0;
  return (+(Math.round(+(num + "e" + precision)) + "e" + -precision)).toFixed(
    precision
  );
}

export { noFractionCurrency, toDecimalCurrency, multiplyForOption };
