import { config } from "../services/configConstant";
import { estFilter, ruleConfigNameFilter } from "../tools/tk-tools";

export var commonColumns = [
  {
    name: "Status",
    value: "history[0].status",
    filter: function (o) {
      return o || "PENDING";
    },
  },
  {
    name: "M",
    value: "o",
    filter: function (o, rowData) {
      return _.size(rowData.history) || "";
    },
  },
  {
    name: "Rank",
    value: "history[0].rank",
    filter: function (o) {
      if (!o) {
        return "";
      }
      return config.caseRankChoicesLookup(o).replace("None", "") || "";
    },
  },
  {
    name: "Action",
    value: "history[0].action",
    filter: function (o) {
      if (!o) {
        return "";
      }
      return config.caseActionChoicesLookup(o).replace("None", "") || "";
    },
  },
  {
    name: "Reason",
    value: "history[0].reason",
    filter: function (o) {
      if (!o) {
        return "";
      }
      return config.caseReasonChoicesLookup(o) || "";
    },
  },
  {
    name: "Comment1",
    value: "history[0].comment",
    text: true,
  },
  {
    name: "User1",
    value: "history[0].userId",
  },
  {
    name: "Workflow Date1",
    value: "history[0].dtime",
    filter: function (o) {
      return estFilter(o, "MM/DD/YYYY h:mma");
    },
  },
  {
    name: "Comment2",
    value: "history[1].comment",
    text: true,
  },
  {
    name: "User2",
    value: "history[1].userId",
  },
  {
    name: "Workflow Date2",
    value: "history[1].dtime",
    filter: function (o) {
      return estFilter(o, "MM/DD/YYYY h:mma");
    },
  },
  {
    name: "Comment3",
    value: "history[2].comment",
    text: true,
  },
  {
    name: "User3",
    value: "history[2].userId",
  },
  {
    name: "Workflow Date3",
    value: "history[2].dtime",
    filter: function (o) {
      return estFilter(o, "MM/DD/YYYY h:mma");
    },
  },
];

export var defaultColumns = commonColumns.concat([
  {
    name: "Type",
    value: "analysisType",
    filter: function (o, rowData) {
      if (rowData.rulesAnalysis) {
        return ruleConfigNameFilter(rowData.rulesAnalysis.rule.name);
      }
      return (
        (
          config.caseTypeChoices.find(function (caseType) {
            return caseType.analysisType === rowData.analysisType;
          }) || {}
        ).name || o
      );
    },
  },
  {
    name: "Account",
    value: "accountNumberDecoded",
  },
  {
    name: "IBD",
    value: "ibdDecoded",
  },
  {
    name: "Symbol",
    value: "symbol",
    filter: function (o, rowData) {
      return o || _.get(rowData, "rulesAnalysis.rule.symbol");
    },
  },
  {
    name: "Date",
    value: "analysisDtime",
    filter: function (o) {
      return estFilter(o, "MM/DD/YYYY");
    },
  },
  {
    name: "E",
    value: "isException",
    filter: function (o) {
      return o ? "Y" : "N";
    },
  },
]);
