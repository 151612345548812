angular.module('sniperWebApp')
  .factory('decoderFactory', function ($http, config) {
    function decode (hashes) {
      return $http({
        method: 'POST',
        url: config.restApiUrl + '/anonymizer/decode/',
        data: {
          hashRequest: hashes
        }
      });
    }

    function encode (hash) {
      return $http({
        method: 'GET',
        url: config.restApiUrl + '/anonymizer/encode/' + hash
      });
    }

    return {
      decode: decode,
      encode: encode
    };
  })
