angular.module('sniperWebApp')
.controller('MarketDataCtrl', function ($scope, $filter, $location, chartConfig, marketDataFactory, config) {

  var parentNav = $scope.nav;
  $scope.nav = function (path, replace) {
    if(path === '/market') {
      return;
    } else if (path === '/') {
      $location.path('/cases').search($location.search());
    } else {
      parentNav(path, replace);
    }
  }

  $scope.config = config;
  $scope.caseFilterConfig = {
    caseType: 'all',
    predefinedDateSelection: config.predefinedFilterDates[1].id,
    predefinedSelectionName: config.predefinedFilterDates[1].name
  };

  function setDatesFromCurrentPredefindSelection() {
    var dateRange = angular.fromJson($scope.caseFilterConfig.predefinedDateSelection);
    if (dateRange.from) {
      marketDataFilter.from = dateRange.from < 0 ?
        moment().businessSubtract(Math.abs(dateRange.from)).valueOf() :
        moment().businessAdd(Math.abs(dateRange.from)).valueOf();
    }
    if (dateRange.to) {
      marketDataFilter.to = dateRange.to < 0 ?
        moment().businessSubtract(Math.abs(dateRange.to)).valueOf() :
        moment().businessAdd(Math.abs(dateRange.to)).valueOf();
    }
  }

  var marketDataFilter = $scope.marketDataFilter = {
    from: '',
    to: '',
    symbol: $location.search() ? $location.search().selectedSymbol : null
  };

  $scope.$watch('caseFilterConfig.predefinedDateSelection', function() {
    setDatesFromCurrentPredefindSelection();
    $scope.loadMarketData();
  });

  var marketDataChart = $scope.marketDataChart = chartConfig.getChart('washSale');
  marketDataChart.options.yAxis[0].height = '50%';
  marketDataChart.options.yAxis[1].top = '50%';
  marketDataChart.options.yAxis[1].height = '50%';

  $scope.loadMarketData = function () {
    if(!marketDataFilter.symbol) return;
    var chart = marketDataChart;
    _.forEach(chart.series, function (s) {
      s.data = [];
    });
    chart.options.xAxis.plotBands.length = 0;
    chart.options.labels.items[0].html = marketDataFilter.symbol;

    var from = moment(marketDataFilter.from);
    var to = moment(marketDataFilter.to);
    if(to.isAfter(moment())) {
      to.startOf('day');
    }
    var daysBetween = to.diff(from, 'days');
    if (daysBetween > 60) {
      $scope.addAlert({type: 'warning', msg: 'Date range exceeding 60 days, adjusting end date'}, 3000);
      marketDataFilter.to = from.clone().add(60, 'days').valueOf();
    }

    var minutes = 5;

    var candles = marketDataFactory.interval({
      symbol: marketDataFilter.symbol,
      from: moment(marketDataFilter.from).format('YYYYMMDD'),
      to: moment(marketDataFilter.to).format('YYYYMMDD'),
      mins: minutes
    });
    candles.$promise.then(function (data) {
      if (data.length > 0)
        $scope.showChart = true;
      else
        $scope.showChart = false;
      chart.series[0].name = marketDataFilter.symbol;
      chart.series[0].data = data.map(function (d) {
        return [
          d.rtime,
          $filter('toDecimal')(d.first_price),
          $filter('toDecimal')(d.high_price),
          $filter('toDecimal')(d.low_price),
          $filter('toDecimal')(d.last_price)
        ];
      });
      chart.series[1].data = data.map(function (d) {
        return [
          d.rtime,
          $filter('toDecimal')(d.avg_price),
        ];
      });
      chart.series[2].data = data.map(function (d) {
        return [d.rtime, d.volume];
      });
    });
  }
});
