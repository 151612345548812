import { toFixed } from "../tools/tk-tools";

export function Case(c) {
  _.assign(this, c);
  this.loadScore();
  this.isOption =
    /OPTION/.test(_.get(this, "washSaleAnalysis.buy.order.product")) ||
    /OPTION/.test(_.get(this, "washSaleAnalysis.sell.order.product"));
}

Case.prototype.loadScore = function () {
  if (this.loaded) {
    return;
  }
  this.loaded = true;
  var total;
  if (this.aheadOfNewsAnalysis) {
    this.caseType = "Ahead of News";
  } else if (this.washSaleAnalysis) {
    this.caseType = "Wash Sale";
  } else if (this.markingTheCloseAnalysis) {
    this.caseType = "Marking the Close";
  } else if (this.rulesAnalysis) {
    this.caseType = this.rulesAnalysis.rule.name;
  } else if (this.spoofingAnalysis) {
    this.caseType = "Spoofing";
  } else if (this.mcapAnalysis) {
    this.caseType = "Mcap";
  }
  switch (this.caseType) {
    case "Wash Sale":
      total = this.washSaleAnalysis.scores.total;
      this.washSaleAnalysis.scores.multiMatch = this.washSaleAnalysis.scores
        .multiMatch || { score: 0, value: 0, weight: 0.1 };
      this.scoreCollection = {
        scores: this.washSaleAnalysis.scores,
        total: total,
        roundedTotal: total > 1 ? "100.00" : toFixed(total * 100, 2),
      };
      delete this.scoreCollection.scores.total;
      break;
    case "Marking the Close":
      total = this.markingTheCloseAnalysis.score;
      this.scoreCollection = {
        scores: {
          volumeScorePerc: this.markingTheCloseAnalysis.volumeScorePerc,
          priceScorePerc: this.markingTheCloseAnalysis.priceScorePerc,
          timingScorePerc: this.markingTheCloseAnalysis.timingScorePerc,
          windowActivityScorePerc:
            this.markingTheCloseAnalysis.windowActivityScorePerc,
          repeatOffenderScorePerc:
            this.markingTheCloseAnalysis.repeatOffenderScorePerc,
        },
        total: total,
        roundedTotal: total > 1 ? "100.00%" : toFixed(total * 100, 2),
      };
      break;
  }
};
