import "./tools/tk-tools";

Highcharts.setOptions({
  global: {
    getTimezoneOffset: function (timestamp) {
      var zone = "America/New_York",
        timezoneOffset = -moment.tz(timestamp, zone).utcOffset();

      return timezoneOffset;
    },
    lang: {
      thousandsSep: ",",
    },
  },
});

angular
  .module("sniperWebApp", [
    "ngCookies",
    "ngResource",
    "ngRoute",
    "highcharts-ng",
    "ui.bootstrap",
    "scrollable-table",
    "ajoslin.promise-tracker",
    "chieffancypants.loadingBar",
    "pasvaz.bindonce",
    "once",
    "tools.tk",
  ])
  .config(function ($routeProvider, $httpProvider, config) {
    config.restApiUrl = location.pathname.replace(/\/$/, "") + "/api";
    // config.restApiUrl = "https://sniper-dev.tkginternal.com/" + location.pathname.replace(/\/$/, "") + "/api";
    var redirecting = false;
    $httpProvider.responseInterceptors.push(function ($q, $rootScope) {
      return function (promise) {
        return promise.then(
          function (successResponse) {
            window.isSaml = $rootScope.isSaml =
              window.isSaml || Boolean(successResponse.headers("X-Auth-Uid"));
            return successResponse;
          },
          function (errorResponse) {
            switch (errorResponse.status) {
              case 0:
                if (window.isSaml && !redirecting) {
                  redirecting = true;
                  window.loginService
                    .getProfileSaml()
                    .then(_.noop, function () {
                      location.href = location.origin;
                    });
                }
              case 302: // SAML
                var loc = errorResponse.headers("Location");
                if (loc) {
                  var url = new URL(loc);
                  var isSaml = url.searchParams.get("SAMLRequest");
                  if (!_.isEmpty(isSaml)) {
                    location.href = loc;
                  }
                }
                break;
              case 401:
                $rootScope.$broadcast("httpProviderMessages", {
                  type: "danger",
                  msg: "Invalid usename or password.",
                });
                break;
              case 403:
                $rootScope.$broadcast("httpProviderMessages", {
                  type: "danger",
                  msg: "You don't have permission to do this.",
                });
                break;
              case 404:
                $rootScope.$broadcast("httpProviderMessages", {
                  type: "danger",
                  msg: "Value not found.",
                });
                break;
              case 500:
                $rootScope.$broadcast("httpProviderMessages", {
                  type: "danger",
                  msg: "Internal server error: " + errorResponse.data + ".",
                });
                break;
              default:
                $rootScope.$broadcast("httpProviderMessages", {
                  type: "danger",
                  msg:
                    "Error " +
                    errorResponse.status +
                    ": " +
                    errorResponse.data +
                    ".",
                });
            }
            return $q.reject(errorResponse);
          }
        );
      };
    });

    function validateUser(loginService) {
      return loginService.validateUser();
    }

    $routeProvider
      .when("/cases", {
        templateUrl: "views/cases.html",
        controller: "CaseController",
        resolve: {
          validateUser: validateUser,
        },
      })
      .when("/market", {
        templateUrl: "views/market-data.html",
        controller: "MarketDataCtrl",
        resolve: {
          validateUser: validateUser,
        },
      })
      .when("/admin/omnibus", {
        templateUrl: "views/adminOmnibus.html",
        controller: "OmnibusController",
        resolve: {
          validateUser: validateUser,
        },
      })
      .when("/admin/symbol", {
        templateUrl: "views/adminSymbol.html",
        controller: "SymbolController",
        resolve: {
          validateUser: validateUser,
        },
      })
      .when("/admin/config", {
        templateUrl: "views/adminConfig.html",
        controller: "MetaConfigController",
        resolve: {
          validateUser: validateUser,
        },
      })
      .when("/admin/config/SE", {
        templateUrl: "views/adminConfig.html",
        controller: "MetaConfigController",
        resolve: {
          validateUser: validateUser,
        },
      })
      .when("/admin/config/SPO", {
        templateUrl: "views/adminFilteredConfig.html",
        controller: "MetaFilteredConfigController",
        resolve: {
          validateUser: validateUser,
        },
      })
      .when("/admin/config/IPO", {
        templateUrl: "views/IPO.html",
        controller: "MetaFilteredConfigController",
        resolve: {
          validateUser: validateUser,
        },
      })
      .when("/account", {
        controller: "AccountCtrl",
        templateUrl: "views/account.html",
        resolve: {
          validateUser: validateUser,
        },
      })
      .when("/login", {
        controller: "LoginCtrl",
        templateUrl: "views/login.html",
      })
      .otherwise({
        redirectTo: "/cases",
      });
  })
  .run(function ($rootScope, $location, $timeout, loginService) {
    // loginService.validateUser();
    $rootScope.borderWidth = "4px";
    $rootScope.nav = function (path, replace) {
      var locationSearch = {};
      var search = path.split("?");
      path = search[0];
      if (search[1]) {
        search = search[1].split("=");
        if (search.length) {
          locationSearch[search[0]] = search[1];
        }
      }
      $location.path(path).search(locationSearch);
      if (replace) {
        $location.search(locationSearch).replace();
      }
    };
    $rootScope.currentPath = "";
    $rootScope.$watch(
      function () {
        return $location.path();
      },
      function (newPath) {
        $rootScope.currentPath = newPath || "";
      }
    );

    $rootScope.hasPermission = function (p) {
      return $rootScope.user && $rootScope.user.permission.indexOf(p) >= 0;
    };

    $rootScope.isRestrictedUser = function () {
      return (
        $rootScope.user &&
        !_.isEmpty($rootScope.user.groups) &&
        $rootScope.user.permission.indexOf("READ") > -1 &&
        $rootScope.user.permission.indexOf("WRITE") === -1
      );
    };

    $rootScope.alerts = [];
    $rootScope.closeAlert = function (index) {
      $rootScope.alerts.splice(index, 1);
    };
    $rootScope.addAlert = function (alert, autohideTime) {
      $rootScope.alerts.push(alert);
      if (autohideTime) {
        $timeout(function () {
          $rootScope.alerts.splice($rootScope.alerts.indexOf(alert), 1);
        }, autohideTime);
      }
    };
  });
