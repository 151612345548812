import { estFilter, toDecimalCurrency } from "../tools/tk-tools";
import { commonColumns } from "./default-col";

export var marketCapColumns = commonColumns.concat([
  {
    name: "Account",
    value: "accountNumberDecoded",
  },
  {
    name: "IBD",
    value: "ibdDecoded",
  },
  {
    name: "Symbol",
    value: "symbol",
  },
  {
    name: "Date",
    value: "mcapAnalysis.order.order.activity_date",
    filter: function (o) {
      return estFilter(o, "MM/DD/YYYY h:mma");
    },
  },
  {
    name: "MarketCap",
    value: "mcapAnalysis.marketCap",
    decimal: 0,
  },
  {
    name: "Ord Branch",
    value: "mcapAnalysis.order.order.order_branch",
  },
  {
    name: "Ord Id",
    value: "mcapAnalysis.order.order.order_seq",
  },
  {
    name: "Exec Px",
    value: "mcapAnalysis.order.executions.summary[0].price",
    filter: function (o) {
      return toDecimalCurrency(o);
    },
  },
  {
    name: "E",
    value: "isException",
    filter: function (o) {
      return o ? "Y" : "N";
    },
  },
]);
