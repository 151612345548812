angular
  .module("sniperWebApp")
  .directive("loginHeader", function (loginService) {
    return {
      restrict: "A",
      link($scope) {
        $scope.logout = function () {
          loginService.logout();
        };
      },
    };
  })
  .controller("LoginCtrl", function ($scope, loginService) {
    var user = ($scope.user = {
      username: "",
      password: "",
    });
    $scope.login = function () {
      loginService.login(user.username, user.password);
    };
  })
  .service(
    "loginService",
    function ($cookieStore, $q, $http, $rootScope, $location, base64, config) {
      var _user;
      window.loginService = this;

      this.getToken = function () {
        return $cookieStore.get("tkg-sniper-token");
      };

      this.getProfile = function (authorization) {
        var that = this;
        return $http({
          method: "GET",
          url: config.restApiUrl + "/user/",
          headers: {
            Authorization: authorization,
          },
        })
          .success(function (user) {
            _user = user;
            $http.defaults.headers.common["Authorization"] = authorization;
            $rootScope.user = user;
            $cookieStore.put(
              "tkg-sniper-token",
              authorization.replace(/^Basic /, "")
            );
            if (!location.pathname.includes('insigneo')) {
              _.remove(config.caseTypeChoices, {id: 'mcapAnalysis'})
            }
          })
          .error(function () {
            that.redirectToLogin();
          });
      };

      this.getProfileSaml = function () {
        var that = this;
        return $http({
          method: "GET",
          url: config.restApiUrl + "/user/",
        }).then(
          function (res) {
            if (res.headers("content-type").indexOf("json") === -1) {
              // saml
              return $q.reject();
            }
            $rootScope.isSaml = true;
            window.isSaml = true;
            var user = res.data;
            _user = user;
            $rootScope.user = user;
            if (!location.pathname.includes('insigneo')) {
              _.remove(config.caseTypeChoices, {id: 'mcapAnalysis'})
            }
            return user;
          },
          function (res) {
            if (res.status !== 0) {
              that.redirectToLogin();
            }
            return $q.reject(res);
            // redirect to saml provider here
          }
        );
      };

      window.getProfileSaml = this.getProfileSaml;

      this.login = function (username, password, path) {
        var authorization = "Basic " + base64.encode(username + ":" + password);
        return this.getProfile(authorization)
          .success(function () {
            // $rootScope.addAlert({type: 'success', msg: 'Login sucessful. Welcome ' + user.realName});
            $location.path(path || "/cases").replace();
          })
          .error(function () {
            $rootScope.addAlert(
              { type: "danger", msg: "Invalid user name or password" },
              3000
            );
          });
      };

      this.validateUser = function () {
        if (_user) {
          return $q.when(_user);
        }
        var token = this.getToken();
        if (!token || window.isSaml) {
          return this.getProfileSaml();
          // this.redirectToLogin();
          // return $q.reject();
        }
        var authorization = "Basic " + token;
        $http.defaults.headers.common["Authorization"] = authorization;
        // var currentPath = $location.path() || '/cases';
        this.getProfile(authorization);
        return $q.when(_user);
      };

      this.logout = function () {
        $cookieStore.remove("tkg-sniper-token");
        this.redirectToLogin();
        setTimeout(function () {
          window.location.reload();
        });
      };

      this.redirectToLogin = function () {
        $location.path("/login").replace();
      };
    }
  );
