angular
  .module("sniperWebApp")

  .directive(
    "caseSelection",
    function (
      $timeout,
      $q,
      $modal,
      caseFactory,
      newCaseFactory,
      criteria,
      decoderFactory
    ) {
      return {
        restrict: "E",
        scope: true,
        templateUrl: "views/case-edit-selection/case-selection.html",
        link: function ($scope, element, attrs) {
          var ctrlScope = $scope.caseControllerScope;
          var checkAllCases = false;
          ctrlScope.multipleCasesSelected = false;
          ctrlScope.checkbox = {
            checkCurrentPageCases: false,
          };

          ctrlScope.editPaging = {
            totalPages: 1,
            currentPage: 1,
            size: 100,
          };

          function navBack(allTheWay) {
            if (ctrlScope.editPaging.currentPage > 1) {
              if (allTheWay) {
                ctrlScope.editPaging.currentPage = 1;
              } else {
                ctrlScope.editPaging.currentPage--;
              }
            }
          }

          function navForward(allTheWay) {
            if (
              ctrlScope.editPaging.currentPage < ctrlScope.editPaging.totalPages
            ) {
              if (allTheWay) {
                ctrlScope.editPaging.currentPage =
                  ctrlScope.editPaging.totalPages;
              } else {
                ctrlScope.editPaging.currentPage++;
              }
            }
          }

          ctrlScope.closeMultiCasesSelection = function () {
            ctrlScope.selectedEditCases = [];
            resetCheckAll();
            ctrlScope.checkbox.checkCurrentPageCases = false;
            ctrlScope.multipleCasesSelected = false;

            ctrlScope.editPaging = {
              totalPages: 1,
              currentPage: 1,
              size: 100,
            };
          };

          ctrlScope.navMultiCases = function (action) {
            switch (action) {
              case "backward":
                navBack();
                break;
              case "forward":
                navForward();
                break;
              case "fast backward":
                navBack(true);
                break;
              case "fast forward":
                navForward(true);
                break;
            }
          };

          function resetCheckAll() {
            checkAllCases = false;
            ctrlScope.editPaging.totalPages = 1;
          }

          ctrlScope.clearAllCases = function () {
            _.forEach(ctrlScope.cases, function (c) {
              c.editHistorySelection = false;
            });
            ctrlScope.closeMultiCasesSelection();
          };

          ctrlScope.selectAllCases = function () {
            var totalRecords = ctrlScope.casePaging.totalRecords,
              sortCriteria = ctrlScope.caseSort,
              caseFilter = ctrlScope.caseFilter,
              caseFilterConfig = ctrlScope.caseFilterConfig,
              findCriteria;

            checkAllCases = true;
            criteria
              .getFindCriteria(caseFilter, caseFilterConfig)
              .then(function (fc) {
                findCriteria = fc;

                newCaseFactory
                  .getTrades(
                    {
                      from: moment(caseFilter.from).format("YYYYMMDD"),
                      to: moment(caseFilter.to).format("YYYYMMDD"),
                    },
                    {
                      find: JSON.stringify(findCriteria),
                      sort: JSON.stringify(sortCriteria),
                    }
                  )
                  .success(function (data) {
                    ctrlScope.editPaging.totalPages = Math.ceil(
                      data.length / ctrlScope.editPaging.size
                    );
                    ctrlScope.selectedEditCases = data;

                    if (/WASH/i.test(data[0].analysisType)) {
                      var buyAccountNums = _.map(data, function (c, index) {
                        return {
                          hash: c.washSaleAnalysis.buy.order.account_number,
                        };
                      });
                      var sellAccountNums = _.map(data, function (c, index) {
                        return {
                          hash: c.washSaleAnalysis.sell.order.account_number,
                        };
                      });
                      decoderFactory
                        .decode(buyAccountNums)
                        .success(function (decodedData) {
                          if (data.length !== decodedData.length) {
                            ctrlScope.alerts.push({
                              type: "danger",
                              msg: "Invalid reply",
                            });
                            return;
                          }
                          _.forEach(data, function (c, index) {
                            c.washSaleAnalysis.buy.order.accountNumberDecoded =
                              decodedData[index].value;
                          });
                        });
                      decoderFactory
                        .decode(sellAccountNums)
                        .success(function (decodedData) {
                          if (data.length !== decodedData.length) {
                            ctrlScope.alerts.push({
                              type: "danger",
                              msg: "Invalid reply",
                            });
                            return;
                          }
                          _.forEach(data, function (c, index) {
                            c.washSaleAnalysis.sell.order.accountNumberDecoded =
                              decodedData[index].value;
                          });
                        });
                    } else {
                      var accountNums = _.map(data, function (c, index) {
                        return {
                          hash: c.accountNumber,
                        };
                      });

                      decoderFactory
                        .decode(accountNums)
                        .success(function (decodedData) {
                          if (data.length !== decodedData.length) {
                            ctrlScope.alerts.push({
                              type: "danger",
                              msg: "Invalid reply",
                            });
                            return;
                          }
                          _.forEach(data, function (c, index) {
                            c.accountNumberDecoded = decodedData[index].value;
                          });
                        });
                    }
                  });
              });
          };

          ctrlScope.selectedCurrentPageCases = function (selectAll) {
            if (!ctrlScope.cases || !ctrlScope.cases.length) return;

            resetCheckAll();
            ctrlScope.checkbox.checkCurrentPageCases =
              ctrlScope.multipleCasesSelected = !!selectAll;
            if (selectAll) {
              ctrlScope.selectedEditCases = ctrlScope.cases;
              _.forEach(ctrlScope.cases, function (c, index) {
                c.editHistorySelection = true;
              });
            } else {
              ctrlScope.selectedEditCases = [];
              _.forEach(ctrlScope.cases, function (c, index) {
                c.editHistorySelection = false;
              });
            }
          };

          ctrlScope.updateCheckedToken = function () {
            resetCheckAll();
            ctrlScope.selectedEditCases = _.where(ctrlScope.cases, {
              editHistorySelection: true,
            });
            ctrlScope.multipleCasesSelected =
              ctrlScope.selectedEditCases.length > 1;
          };

          ctrlScope.submitActionForMultiCases = function (caseHistory) {
            $modal
              .open({
                size: "sm",
                templateUrl:
                  "views/case-edit-selection/modal-confirm-history-update.html",
                scope: $scope,
                windowClass: "gold-frame",
              })
              .result.then(function () {
                updateCasesHistory(caseHistory);
              });
          };

          function updateCasesHistory(caseHistory) {
            var dtime = moment().valueOf();
            var userId = ctrlScope.user.realName || "";
            var defers = [];
            caseHistory.comment =
              caseHistory.comment ||
              "Multiple updates for " +
                ctrlScope.selectedEditCases.length +
                " transactions";

            if (checkAllCases) {
              _.forEach(ctrlScope.selectedEditCases, function (c, index) {
                var defer = newCaseFactory
                  .addHistory(c.mid, caseHistory)
                  .error(function () {
                    try {
                      var failedCase = _.find(ctrlScope.cases, { mid: c.mid });
                      failedCase.history.shift();
                    } catch (e) {
                      //cannot find failed case or history of the failed case is empty
                    }
                  });
                defers.push(defer);
              });
              _.forEach(ctrlScope.cases, function (c, index) {
                if (!_.find(ctrlScope.selectedEditCases, { mid: c.mid })) {
                  return;
                }
                c.history = c.history || [];
                c.history.unshift(
                  angular.extend(
                    {
                      dtime: dtime,
                      userId: userId,
                    },
                    caseHistory
                  )
                );
              });
            } else {
              _.forEach(ctrlScope.selectedEditCases, function (c, index) {
                var defer = newCaseFactory
                  .addHistory(c.mid, caseHistory)
                  .success(function () {
                    c.history = c.history || [];
                    c.history.unshift(
                      angular.extend(
                        {
                          dtime: dtime,
                          userId: userId,
                        },
                        caseHistory
                      )
                    );
                  });
                defers.push(defer);
              });
            }

            $q.all(defers).then(function () {
              ctrlScope.newCaseHistory = {};
              ctrlScope.newHistoryConfig = {};
              ctrlScope.clearAllCases();
            });
          }
        },
      };
    }
  )

  .directive("allSelectionNotification", function () {
    return {
      restrict: "E",
      templateUrl: "views/case-edit-selection/all-selection-notification.html",
      scope: false,
    };
  });
